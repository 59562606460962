import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { CustomField } from './custom-field.entity';
import { Program } from '../../program/program.entity';
import { Tactic } from '../../tactic/tactic.entity';

export type PublicCustomFieldValue = Pick<CustomFieldValue, 'id' | 'customField' | 'value'>;

@Entity('customFieldValues')
export class CustomFieldValue {
	@PrimaryGeneratedColumn('uuid')
	id: string;

	@ManyToOne(() => CustomField, (customField) => customField.values, { onDelete: 'CASCADE' })
	@JoinColumn({ name: 'customFieldId' })
	customField: CustomField;

	@ManyToOne(() => Program, (program) => program.customFieldValues, { nullable: true, onDelete: 'CASCADE' })
	@JoinColumn({ name: 'programId' })
	program?: Program;

	@ManyToOne(() => Tactic, (tactic) => tactic.customFieldValues, { nullable: true, onDelete: 'CASCADE' })
	@JoinColumn({ name: 'tacticId' })
	tactic?: Tactic;

	@Column({ name: 'value', type: 'jsonb', nullable: true })
	value: any; // Stores text, single select ID, or array of multi-select IDs

	constructor(value?: Partial<CustomFieldValue>) {
		if (value) {
			Object.assign(this, value);
		}
	}
}
