import { BrandAllocation } from '../brand-allocation/brand-allocation.entity';
import { BudgetAllocation } from '../budget-allocation/budget-allocation.entity';
import { PublicBudgetDistributionGroup } from '../budget-distribution-group/budget-distribution-group.entity';
import { BudgetDistribution, MergedBudgetDistribution } from '../budget-distribution/budget-distribution.entity';
import { BudgetDistributionDto } from '../budget-distribution/dtos/budget-distribution.dto';
import { Cost } from '../cost/cost.entity';
import { Cadence, TimeUtils } from '../_core/utils/utils.time';

export class BudgetDistributionUtils {
	/**
	 * Creates a BudgetDistributionDto with optional overridden properties.
	 * @param overrideParams - An object containing properties to override in the created BudgetDistributionDto.
	 * @returns A new BudgetDistributionDto object.
	 */
	public static createBudgetDistributionDto(overrideParams: Partial<BudgetDistributionDto>): BudgetDistributionDto {
		return {
			start: overrideParams.start ? new Date(overrideParams.start).toISOString() : undefined,
			end: overrideParams.end ? new Date(overrideParams.end).toISOString() : undefined,
			split: overrideParams.split || '0',
		};
	}

	/**
	 * Takes a duration range and returns an array of evenly appropriated
	 * distributions based on the cadence provided.
	 *
	 * @param from The start date of the range
	 * @param end The end date of the range
	 * @param cadence The cadence to use for the time periods
	 * @returns An array of budget distribution objects
	 */
	public static createDistributionsForDateRange(from: string, end: string, cadence: Cadence): Partial<BudgetDistributionDto>[] {
		// Get an array of time periods based on the provided cadence
		const timePeriods = TimeUtils.getTimePeriods(from, end, cadence);

		// Map each time period to a budget distribution object
		return timePeriods.map((period) =>
			this.createBudgetDistributionDto({
				start: period.from.toISOString(),
				end: period.to.toISOString(),
				split: (1 / timePeriods.length).toString(),
			})
		);
	}

	/**
	 * Creates a merged budget distribution object.
	 * @param budgetEntity - The budget allocation or cost object.
	 * @param brandAllocationKey - The brand allocation key.
	 * @param params - Additional parameters to include in the merged budget distribution object.
	 * @returns The merged budget distribution object.
	 */
	public static createMergedBudgetDistribution(
		budgetEntity: BudgetAllocation | Cost,
		brandAllocationKey: string = 'brandAllocations',
		params?: Partial<MergedBudgetDistribution>
	): MergedBudgetDistribution {
		return {
			ids: [],
			name: 'Flight',
			budgetItemId: budgetEntity.id,
			budgetItemBrandAllocationKey: brandAllocationKey,
			budgetDistributionGroupId: undefined,
			start: undefined,
			end: undefined,
			total: 0,
			...params,
		};
	}

	/**
	 * Merges budget distributions from an array of budget items or costs into a single array of
	 * merged budget distributions, with the total for each merged distribution calculated based on
	 * the split and total of each brand allocation and its associated budget distributions.
	 *
	 * @param budgetItems - An array of budget allocations or costs.
	 * @param totalKey - The key of the total property in each budget item.
	 * @param brandAllocationKey - The key of the brand allocation property in each budget item.
	 * Defaults to 'brandAllocations'.
	 *
	 * @returns An array of merged budget distributions.
	 */
	public static getMergedDistributionsWithTotals(
		budgetItems: BudgetAllocation[] | Cost[],
		totalKey: string,
		brandAllocationKey: string = 'brandAllocations'
	): MergedBudgetDistribution[] {
		let mergedDistributions: MergedBudgetDistribution[] = [];

		// Loop through each budget item or cost.
		for (const budgetItem of budgetItems || []) {
			// Get the brand allocations for the current budget item.
			let brandAllocations = (budgetItem[brandAllocationKey] as BrandAllocation[]) || [];

			// Remove any duplicate brand allocations by id.
			brandAllocations = brandAllocations?.filter((brandAllocation, index, self) => {
				return index === self.findIndex((t) => t.id === brandAllocation.id);
			});

			// Loop through each brand allocation for the current budget item.
			for (const brandAllocation of brandAllocations) {
				// Calculate the total for the current brand allocation.
				const brandAllocationTotal = budgetItem[totalKey] * brandAllocation.split;

				// Loop through each budget distribution for the current brand allocation.
				for (const distribution of brandAllocation?.budgetDistributions || []) {
					// Check if a merged distribution with the same start and end date already exists.
					const mergedDistribution = mergedDistributions.find(
						(d) => d.start === distribution.start && d.end === distribution.end
					);

					// If a merged distribution exists, add the current distribution's total to it.
					if (mergedDistribution) {
						mergedDistribution.ids.push(distribution.id);
						mergedDistribution.total += distribution.split * brandAllocationTotal;

						// // Throw a warning if the distribution group is not the same as the first distribution.
						// if (mergedDistribution.budgetDistributionGroupId !== distribution.budgetDistributionGroupId) {
						// 	// console.warn(
						// 	// 	`Budget Distribution Group ID mismatch for Budget Item ${budgetItem.id} and Brand Allocation ${brandAllocation.id}.`,
						// 	// 	mergedDistribution,
						// 	// 	distribution
						// 	// );
						// }
					} else {
						// Check and found if budget distribution group exists to get the budget distribution group id as fallback.
						const foundBudgetDistribution = ((budgetItems as any) ?? [])
							?.find((bi) => bi.id === budgetItem.id)
							?.budgetDistributionGroups?.find(
								(budgetDistributionGroup) =>
									budgetDistributionGroup?.costId === budgetItem?.id ||
									budgetDistributionGroup?.budgetAllocationId === budgetItem?.id
							)
							?.budgetDistributions?.find((val) => val.id === distribution.id);
						// If a merged distribution does not exist, create a new one and add it to the array of merged distributions.
						mergedDistributions.push(
							this.createMergedBudgetDistribution(budgetItem, brandAllocationKey, {
								name: `Flight ${String.fromCharCode(65 + mergedDistributions.length)}`,
								ids: [distribution.id],
								budgetDistributionGroupId:
									distribution.budgetDistributionGroupId ?? foundBudgetDistribution?.budgetDistributionGroupId,
								start: distribution.start,
								end: distribution.end,
								total: distribution.split * brandAllocationTotal,
							})
						);
					}
				}
			}
		}

		// Sort the array of merged distributions by start date and rename each object.
		mergedDistributions = mergedDistributions?.sort((a, b) => new Date(a.start)?.getTime() - new Date(b.start)?.getTime());
		mergedDistributions = mergedDistributions.map((d, i) => ({ ...d, name: `Flight ${String.fromCharCode(65 + i)}` }));

		return mergedDistributions;
	}

	/**
	 * Merges budget distribution groups with merged budget distributions.
	 * @param {MergedBudgetDistribution[]} mergedBudgetDistributions - An array of merged budget distributions.
	 * @param {PublicBudgetDistributionGroup[]} budgetDistributionGroups - An array of budget distribution groups.
	 * @returns {MergedBudgetDistribution[]} An array of merged budget distributions with details from their corresponding budget distribution group.
	 */
	public static mergeBudgetDistributionGroupsWithMergedBudgetDistributions(
		mergedBudgetDistributions: MergedBudgetDistribution[],
		budgetDistributionGroups: PublicBudgetDistributionGroup[]
	): MergedBudgetDistribution[] {
		return mergedBudgetDistributions.map((mergedBudgetDistribution) => {
			// Find the budget distribution group that corresponds to the current merged budget distribution.
			const budgetDistributionGroup = budgetDistributionGroups?.find(
				(bdg) => bdg.id === mergedBudgetDistribution.budgetDistributionGroupId
			);

			// Merge the budget distribution group's details with the merged budget distribution.
			return {
				...mergedBudgetDistribution,
				details: budgetDistributionGroup?.details,
			};
		});
	}

	/**
	 * Adds a new budget distribution to a budget entity.
	 * @param budgetEntity 		The budget entity to add the distribution to.
	 * @param mergedDistribution 		The merged distribution to add.
	 * @param totalKey 		The key to use to get the total from the budget entity.
	 * @param brandAllocationKey 		The key to use to get the brand allocations from the budget entity.
	 * @param prepend 		Whether to prepend the new distribution to the existing distributions.
	 * @param changeTotal 		Whether to change the total of the budget entity.
	 * @returns 		The updated budget entity.
	 */
	public static addMergedDistribution(
		budgetEntity: BudgetAllocation | Cost,
		mergedDistribution: MergedBudgetDistribution,
		totalKey: string,
		brandAllocationKey: string = 'brandAllocations',
		prepend: boolean = true,
		changeTotal: boolean = true
	) {
		// Create a budget distribution with the dates, but no split yet.  We will
		// get the split when we add totals in at the end.
		const newBudgetDistribution = this.createBudgetDistributionDto(mergedDistribution);

		// TODO: Fix typescript
		// Add the new budget distribution to the brand allocations
		const newBudgetEntity: any = {
			...budgetEntity,
			[brandAllocationKey]: budgetEntity[brandAllocationKey].map((brandAllocation) => {
				let budgetDistributions = brandAllocation.budgetDistributions;
				if (prepend) {
					budgetDistributions = [newBudgetDistribution, ...(budgetDistributions || [])];
				} else {
					budgetDistributions = [...(budgetDistributions || []), newBudgetDistribution];
				}

				return {
					...brandAllocation,
					budgetDistributions,
				};
			}),
		};

		// We need a version of the merged distribution without a total,
		// So that the next function will detect that it needs to add this total to the budget item.
		const mergedDistributionWithoutTotal = {
			...mergedDistribution,
			total: 0,
		};

		// Now update the MergedBudgetDistribution with the new total and split.
		// Use the applyMergedDistributionTotals method to update all the distributions with no id.
		return this.adjustMergedDistributionTotal(
			newBudgetEntity,
			mergedDistributionWithoutTotal,
			mergedDistribution.total,
			totalKey,
			brandAllocationKey,
			true,
			false,
			changeTotal
		);
	}

	/**
	 * Removes the merged budget distribution from the given budget entity's brand allocations.
	 *
	 * @param budgetEntity - The budget allocation or cost object to modify.
	 * @param mergedDistribution - The merged budget distribution to remove.
	 * @param totalKey - The key for the budget entity's total.
	 * @param brandAllocationKey - The key for the budget entity's brand allocations.
	 * @param keepTotal - Whether to keep the budget entity's total unchanged.
	 * @returns A new budget entity with the merged budget distribution removed.
	 */
	public static removeMergedDistribution(
		budgetEntity: BudgetAllocation | Cost,
		mergedDistribution: MergedBudgetDistribution,
		totalKey: string,
		brandAllocationKey: string = 'brandAllocations',
		keepTotal: boolean = false
	) {
		const newBudgetEntity: any = {
			...budgetEntity,
			[brandAllocationKey]: budgetEntity[brandAllocationKey].map((brandAllocation) => {
				return {
					...brandAllocation,
					budgetDistributions: brandAllocation.budgetDistributions.filter((d) => !mergedDistribution.ids.includes(d.id)),
				};
			}),
		};

		if (keepTotal) {
			return newBudgetEntity;
		} else {
			return this.adjustMergedDistributionTotal(newBudgetEntity, mergedDistribution, 0, totalKey, brandAllocationKey, true);
		}
	}

	/**
	 * Adjusts the merged budget distribution for a budget entity given a new distribution total.
	 * @param budgetEntity - The budget entity to adjust.
	 * @param mergedDistribution - The merged budget distribution object.
	 * @param newDistributionTotal - The new distribution total to use.
	 * @param totalKey - The key for the total property in the budget entity.
	 * @param brandAllocationKey - The key for the brand allocations property in the budget entity.
	 * @param alterToNewDistributions - Whether to alter undefined distributions to the new total.
	 * @param alterAllDistributions - Whether to alter all distributions to the new total.
	 * @param changeTotal - Whether to change the budget total.
	 * @returns The adjusted budget entity.
	 */
	public static adjustMergedDistributionTotal(
		budgetEntity: BudgetAllocation | Cost,
		mergedDistribution: MergedBudgetDistribution,
		newDistributionTotal: number,
		totalKey: string,
		brandAllocationKey: string = 'brandAllocations',
		alterToNewDistributions: boolean = false, // Alter undefined distributions to the new total
		alterAllDistributions: boolean = false,
		changeTotal: boolean = true
	) {
		/**
		 * Step 1: Get the new total for the budget entity
		 */

		// Calculate the difference between the new and old distribution totals.
		const difference = newDistributionTotal - Number(mergedDistribution.total);

		// Calculate the new total for the budget entity.
		let newTotal = Number((Number(budgetEntity[totalKey]) + difference).toFixed(3));

		// If we're not changing the budget total, reset this back to the previous total.
		if (!changeTotal) {
			newTotal = Number(budgetEntity[totalKey]);
		}

		console.log('Old Total', Number(budgetEntity[totalKey]));
		console.log('New Total', newTotal);
		console.log('Difference', difference);
		console.log('New Distribution Total', newDistributionTotal);

		console.log('Budget Entity', budgetEntity);

		/**
		 * Step 2: Rework the splits for distributions
		 */

		// Map over the brand allocations and rework the splits for each distribution.
		const brandAllocations = budgetEntity[brandAllocationKey].map((brandAllocation) => {
			// Get the previous and new totals with the brand split.
			const previousBrandTotal = Number(budgetEntity[totalKey]) * (brandAllocation.split || 1);
			const newBrandTotal = newTotal * (brandAllocation.split || 1);

			console.log('Brand Allocation', brandAllocation);
			console.log('Previous Brand Total', previousBrandTotal);
			console.log('New Brand Total', newBrandTotal);
			console.log('Total Number of Distributions', brandAllocation.budgetDistributions.length);

			return {
				...brandAllocation,
				budgetDistributions: brandAllocation.budgetDistributions.map((bD) => {
					// Get the integer total for this distribution based on previous total.
					let total = previousBrandTotal * bD.split;

					// If we're altering all distributions, use the new total instead of the previous total.
					if (alterAllDistributions) {
						total = newBrandTotal * bD.split;
					}

					console.log('Budget Distribution', bD);
					console.log('Previous Budget Distribution Total', total, bD.split);

					if (
						mergedDistribution.ids.includes(bD.id) ||
						// If we are applying to new distributions, then we need to include any distributions that are
						// undefined.  This is usually used to apply new totals to brand new distributions.
						(alterToNewDistributions && bD.id === undefined)
					) {
						console.log('Matched Distribution', bD);

						// Then change the dollar value to our affected distribution.
						total = newDistributionTotal * (brandAllocation.split || 1);

						console.log('Matched Distribution Total', total);
					}

					console.log('Final New Budget Distribution Split', total / newBrandTotal, total, newBrandTotal);

					// Then turn all the integers back into splits.
					return {
						...bD,
						split: total / newBrandTotal,
					};
				}),
			};
		});

		return {
			...budgetEntity,
			value: newTotal.toString(),
			[brandAllocationKey]: brandAllocations,
		};
	}

	/**
	 * Adjusts the dates of budget distributions in a merged budget distribution for a given budget entity.
	 *
	 * @param budgetEntity - The budget entity to adjust.
	 * @param mergedDistribution - The merged budget distribution containing the budget distributions to adjust.
	 * @param dates - The new start and end dates to set for the adjusted budget distributions.
	 * @param brandAllocationKey - The key to use for the brand allocations in the budget entity.
	 *
	 * @returns The adjusted budget entity.
	 */
	public static adjustMergedDistributionDates(
		budgetEntity: BudgetAllocation | Cost,
		mergedDistribution: MergedBudgetDistribution,
		dates: {
			start: string;
			end: string;
		},
		brandAllocationKey: string = 'brandAllocations'
	) {
		return {
			...budgetEntity,
			[brandAllocationKey]: budgetEntity[brandAllocationKey].map((brandAllocation) => {
				return {
					...brandAllocation,
					budgetDistributions: brandAllocation.budgetDistributions?.map((bD) => {
						if (mergedDistribution.ids.includes(bD.id)) {
							return {
								...bD,
								...dates,
							};
						}

						return bD;
					}),
				};
			}),
		};
	}

	/**
	 * Returns the type of the given budget entity as either "budget-allocation" or "cost".
	 *
	 * @param budgetEntity - The budget entity to determine the type of.
	 * @returns The type of the given budget entity as either "budget-allocation" or "cost".
	 * @throws An error if the type of the budget entity cannot be determined.
	 */
	public static getBudgetEntityType(budgetEntity: BudgetAllocation | Cost): 'budget-allocation' | 'cost' {
		// Use the right method to update the budget item.
		if ('programId' in budgetEntity) {
			return 'budget-allocation';
		} else if ('tacticId' in budgetEntity) {
			return 'cost';
		} else {
			console.error('Could not find the right budget entity type.');
			return;
		}
	}

	public static generateEvenBudgetDistributions(
		totalAmount: number,
		startDate: string,
		endDate: string,
		numFlights: number
	): BudgetDistribution[] {
		const start = new Date(startDate);
		const end = new Date(endDate);

		const totalDays = Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
		const daysPerFlight = Math.floor(totalDays / numFlights);
		const remainingDays = totalDays % numFlights;

		const distributions: BudgetDistribution[] = [];

		for (let i = 0; i < numFlights; i++) {
			const flightStart = new Date(start);
			flightStart.setDate(start.getDate() + i * daysPerFlight + Math.min(i, remainingDays));

			const flightEnd = new Date(flightStart);
			flightEnd.setDate(flightStart.getDate() + daysPerFlight + (i < remainingDays ? 1 : 0));

			const newDistribution = new BudgetDistribution();

			// Otherwise, let's default it to the last distribution's end date + 1 day.
			newDistribution.start = flightStart.toISOString();
			newDistribution.end = flightEnd.toISOString();
			newDistribution.split = totalAmount / numFlights / totalAmount;

			distributions.push(newDistribution);
		}

		return distributions;
	}
}
