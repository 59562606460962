// TODO: WIP

import { Filter } from '../../export/dtos/export.dto';
import { Condition } from '../models/math-operations';
import { ObjectUtils } from './utils.object';
import { StringUtils } from './utils.string';

/**
 * Local Dependencies
 */

const isDebug = typeof process !== 'undefined' && process.env && process.env.DEBUG ? process.env.DEBUG : false;

export enum Validator {
	String = 'string',
	Phone = 'phone',
}

export class Validation {
	// Async to support map validation
	public static async validate(input, validator: Validator, params?): Promise<boolean> {
		switch (validator) {
			case Validator.String:
				return this.validateString(input);
			case Validator.Phone:
				return this.validatePhone(input, params);
			default:
				return typeof input !== 'undefined';
		}
	}

	// and, optionally a custom RegExp
	public static validateEmail(eml, params?: RegExp) {
		const emailRgx =
			/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

		if (eml && eml.match(emailRgx)) {
			if (params) {
				return eml.match(params) ? true : false;
			}
			return true;
		} else {
			return false;
		}
	}

	public static validateConditionOnObject(obj: { [key: string]: any }, filter: Filter) {
		const path = ObjectUtils.resolveDotNotationPath(filter.path, obj);

		// If the path is a string, account for merge tags
		let value = filter.value;
		if (typeof filter.value === 'string') {
			value = StringUtils.replaceMergeTags(filter.value || '', obj);
		}

		return this.validateCondition(path, filter.condition, value);
	}

	// Matches email based on RFC 5322

	public static validateCondition(value: any, condition: Condition, expectedValue: any) {
		switch (condition) {
			case Condition.GT:
				return value > expectedValue;

			case Condition.GTE:
				return value >= expectedValue;

			case Condition.LT:
				return value < expectedValue;

			case Condition.LTE:
				return value <= expectedValue;

			case Condition.EQ:
				return ObjectUtils.deepEqual(value, expectedValue);

			case Condition.NEQ:
				return !ObjectUtils.deepEqual(value, expectedValue);

			default:
				return false;
		}
	}

	private static validateString(str) {
		if (typeof str === 'string' && str.trim().length > 0) {
			return true;
		}
		return false;
	}

	private static validatePhone(phone: string, params?) {
		if (!phone) {
			return false;
		}

		// Remove non-numbers
		phone = phone.replace(/[^0-9]+/g, '');

		// If no validators are passed,
		// default to checking US phone numbers
		if (!params || (!params.countries && !params.countries.length)) {
			params = {
				countries: ['US'],
			};
		}

		for (let i = 0; i < params.countries.length; i++) {
			const country = params.countries[i];
			switch (country) {
				case 'US':
				default:
					// +1 (###) ###-#### | (###) ###-#####
					// At some point we probably want to validate area codes
					// and prefixes. Right now we just test to see if it's
					// US (+1) and 10 digits.
					if (!phone.match(/(^1[0-9]{10}$)|(^[0-9]{10}$)/)) {
						return false;
					}
					break;
			}
		}

		return true;
	}
}
