import { Component, ContentChild, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Navigation, Pagination, Scrollbar } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnChanges {
	@Input() entities: any[];
	@Input() config: SwiperOptions;
	@Input() borderedSlide = false;

	@ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
	@ContentChild(TemplateRef) cardTemplate: TemplateRef<any>;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.config) {
			this.config = {
				slidesPerView: 'auto',
				spaceBetween: 15,
			};
		}
	}

	getSwiperRef() {
		return this.swiper.swiperRef;
	}

	slideNext(speed?: number): void {
		this.swiper.swiperRef.slideNext(speed);
	}

	slidePrev(speed?: number): void {
		this.swiper.swiperRef.slidePrev(speed);
	}
}
