import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Program } from '../program/program.entity';
import { DeliverableEntity } from '../deliverable/deliverable.entity';

@Entity({ name: 'programStrategicBriefs' })
export class ProgramStrategicBriefEntity {
	@PrimaryGeneratedColumn('uuid', { name: 'id' }) id: string;
	@Column({ name: 'data', type: 'json' }) data: any;

	@Column({ name: 'versionNumber', type: 'int' }) versionNumber: number;
	@Column({ name: 'versionDate', type: 'timestamp' }) versionDate: Date;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;

	@ManyToOne(() => Program, (program) => program.programStrategicBriefs, {
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'program_id' })
	program: Program | string;

	@ManyToOne(() => DeliverableEntity)
	@JoinColumn({ name: 'deliverable_id' })
	deliverable: DeliverableEntity;

	@Column({ name: 'briefId', type: 'uuid', nullable: true })
	briefId: string;
}
