import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TacticsPhase } from './tactics-phase.model';
import { TacticsPhaseStore } from './tactics-phase.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TacticsPhaseService {
	constructor(private tacticsPhaseStore: TacticsPhaseStore, private http: HttpClient) {}

	get(): Observable<TacticsPhase[]> {
		return this.http.get<TacticsPhase[]>('https://api.com').pipe(
			tap((entities) => {
				this.tacticsPhaseStore.set(entities);
			})
		);
	}

	set(tacticsPhase: TacticsPhase[]): void {
		this.tacticsPhaseStore.set(tacticsPhase);
	}

	add(tacticsPhase: TacticsPhase): void {
		this.tacticsPhaseStore.add(tacticsPhase);
	}

	update(id: TacticsPhase['id'], tP: Partial<TacticsPhase>) {
		this.tacticsPhaseStore.setLoading(true);
		if (tP) {
			return this.http
				.put<TacticsPhase>(
					`${environment.apiUrl}/organization/${environment.organizationId}/tactic-phase/${id}`,
					this.prepareForApi(tP)
				)
				.pipe(
					tap((newValue) => {
						this.tacticsPhaseStore.update(tP.id, newValue);
						this.tacticsPhaseStore.setLoading(false);
					}),
					catchError((err) => {
						this.tacticsPhaseStore.update(tP.id, this.tacticsPhaseStore.getValue().entities[tP.id]);
						console.log(err);
						return throwError(err);
					})
				);
		} else {
			console.log('Updating Akita', tP);
			this.tacticsPhaseStore.update(tP.id, tP);
			this.tacticsPhaseStore.setLoading(false);
			return of(tP);
		}
	}

	remove(id: ID): void {
		this.tacticsPhaseStore.remove(id);
	}

	setLoading(state: boolean): void {
		this.tacticsPhaseStore.setLoading(state);
	}

	prepareForApi(retailer: Partial<TacticsPhase>): object {
		const obj = {};

		console.log('Preparing for API', retailer);

		if (retailer) {
			Object.keys(retailer).forEach((key) => {
				switch (key) {
					case 'id':
					case 'created':
					case 'author':
						break;

					default:
						obj[key] = retailer[key];
						break;
				}
			});
		}

		return obj;
	}
}
