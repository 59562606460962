import { Column, Entity, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { Sortable } from '../../_core/decorators/sortable.decorator';
import { Organization } from '../../organization/organization.entity';
import { CustomFieldOption } from './custom-field-option.entity';
import { CustomFieldValue } from './custom-field-value.entity';

export type PublicCustomField = Pick<CustomField, 'id' | 'name' | 'type' | 'entity' | 'enabled' | 'options' | 'created'>;

export enum CustomFieldType {
	Text = 'Text',
	SingleSelect = 'Single Select',
	MultiSelect = 'Multi Select',
}

export enum CustomFieldEntity {
	Program = 'Program',
	Tactic = 'Tactic',
}

@Entity('customFields')
export class CustomField {
	constructor(value?: Partial<CustomField>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column({ name: 'name', nullable: false, type: 'varchar' })
	@Sortable
	name: string;

	@Column({ name: 'type', nullable: false, type: 'enum', enum: CustomFieldType })
	type: CustomFieldType;

	@Column({ name: 'entity', nullable: false, type: 'enum', enum: CustomFieldEntity })
	entity: string;

	@Column({ name: 'enabled', nullable: false, default: true, type: 'boolean' })
	enabled: boolean;

	@OneToMany(() => CustomFieldOption, (option) => option.customField, {
		cascade: true,
		eager: true,
	})
	options?: CustomFieldOption[];

	@ManyToOne(() => Organization, (organization) => organization.customFields, { onDelete: 'CASCADE' })
	@JoinColumn({ name: 'organizationId' })
	organization: Organization;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	created: string;

	@OneToMany(() => CustomFieldValue, (value) => value.customField, {
		cascade: true,
	})
	values?: CustomFieldValue[];

	public toPublic(): PublicCustomField {
		return {
			id: this.id,
			name: this.name,
			type: this.type,
			entity: this.entity,
			enabled: this.enabled,
			options: this.type === CustomFieldType.SingleSelect || this.type === CustomFieldType.MultiSelect ? this.options : undefined,
			created: this.created,
		};
	}
}
