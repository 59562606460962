import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../state/entities/user/user.model';
import { PublicUser } from '../../../../../api/src/user/user.entity';

@Pipe({
	name: 'userDisplayName',
})
export class UserDisplayNamePipe implements PipeTransform {
	constructor() {}

	transform(user: User | PublicUser, initials: boolean = false, nameOnly: boolean = false): string {
		return this.getUserDisplay(user, initials, nameOnly);
	}

	getUserDisplay(user: User | PublicUser, initials: boolean = false, nameOnly: boolean = false): string {
		if (!user) {
			return '';
		}

		if (!user?.nameFirst && !user?.nameLast) {
			if ('profile' in user) {
				user.nameFirst = user?.profile?.nameFirst;
			}
			if ('profile' in user) {
				user.nameLast = user?.profile?.nameLast;
			}
		}

		if (initials) {
			if (user?.nameFirst && user?.nameLast) {
				return `${user?.nameFirst?.charAt(0)}${user?.nameLast?.charAt(0)}`;
			}

			if (user?.nameFirst) {
				return user?.nameFirst?.substr(0, 2);
			}

			if (user?.nameLast) {
				return user?.nameLast?.substr(0, 2);
			}

			return '';
		}

		if (nameOnly) {
			return `${user?.nameFirst || ''} ${user?.nameLast || ''}`;
		}

		return user?.nameFirst || user?.nameLast ? `${user?.nameFirst || ''} ${user?.nameLast || ''}` : user?.email ?? '';
	}
}
