import { Pipe, PipeTransform } from '@angular/core';
import { resolveDotNotationPath } from '../../_core/utils/object.utils';
import { Column, TableRow } from '../../state/table/table.model';
import { isObject } from '../../_core/utils/types.utils';
import { getValueTypeMaskedValue } from '../../state/table/table.utils';

@Pipe({
	name: 'getTableCellValue',
})
export class GetTableCellValuePipe implements PipeTransform {
	private readonly _entityTypeChecks = ['country', 'funding-source', 'tags', 'brands'];

	private readonly _isEntityTypeMismatch = (column: Column, row: TableRow<any>): boolean =>
		this._entityTypeChecks.some((entityType) => column.id.includes(entityType)) &&
		!column.entityTypes.includes(row.type?.toLowerCase());

	transform(column: Column, row: TableRow<any>, cellValue: string | any, expectValueProperty: boolean): any {
		let data = '';

		// Skip pagination row
		if (row.type === 'pagination') return data;

		// Skip row if entity type is not allowed
		if (this._isEntityTypeMismatch(column, row)) return data;

		const content = resolveDotNotationPath(column.path, row);

		if (expectValueProperty) {
			data = content?.value || content?.rawValue?.value || content?.rawValue?.name;
		} else {
			data = content;
		}

		// Fallback if the value is an object
		if (column.id === 'id' && isObject(data)) {
			data = (data as any).value;
		}

		// Fallback if the value is a custom type
		if (column.type === 'custom' && !data) {
			data = getValueTypeMaskedValue(data, column.type, column, row, undefined, true) as string;
		}

		return data || cellValue || '';
	}
}
