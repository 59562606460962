import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Entity } from '../entities/entities.model';
import { InvoiceColumnCollection } from '../entities/invoice/invoice.model';
import { PlanColumnCollection } from '../entities/plan/plan.model';
import { ProgramColumnCollection } from '../entities/program/program.model';
import { TacticColumnCollection } from '../entities/tactic/tactic.model';
import { DynamicColumnCollection, OverallColumnCollection } from '../global/global.model';
import { CalendarSettings, defaultZoomLevel, TableCollection, TableItem, TableSettings, zoomLevels } from '../table/table.model';

export interface TableState {
	rawData: TableCollection<Entity>;
	rows: TableItem<Entity>[];
	currentEndpoint: string;
	tableSettings: TableSettings | CalendarSettings;
}

export function createInitialState(): TableState {
	return {
		rawData: undefined,
		rows: [],
		currentEndpoint: undefined,
		tableSettings: {
			section: 'activate',
			sort: {},
			columns: [
				OverallColumnCollection,
				PlanColumnCollection,
				ProgramColumnCollection,
				TacticColumnCollection,
				InvoiceColumnCollection,
				DynamicColumnCollection,
			],
			activeColumns: undefined,
			measurementTypes: undefined,
			activeCalendarColumns: ['Name'],
			calendarEditMode: false,
			calendarDateRange: {
				start: undefined,
				end: undefined,
			},
			hierarchy: ['retailer', 'plan', 'program', 'tactic', 'invoice'],
			expandedRows: [],
			zoomLevels,
			zoomLevel: defaultZoomLevel,
			columnWidths: [],
			contractedRows: false,
			header: {
				enabled: true,
			},
			items: {
				showBudgetDistributions: true,
			},
		},
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'table' })
export class TableStore extends Store<TableState> {
	constructor() {
		super(createInitialState());
	}
}
