import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionQuery } from '../../state/session/session.query';
import { SessionService } from '../../state/session/session.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	// Unsecured routes that shouldn't be subject to adding auth tokens go here
	readonly UNSECURED_ROUTES = [
		'/auth/basic/generate-sign-in-code',
		'/auth/basic/code-sign-in',
		'/auth/request-sign-in',
		'geo-objective-kpi-tool-cms',
		'/auth/wpp-open/sign-in',
		'/auth/wpp-open/request-access',
		'/public',
	];
	readonly USER_REFRESH_TOKEN_PATH = '/user/refresh';

	constructor(private readonly sessionQuery: SessionQuery, private readonly sessionService: SessionService) {}

	/**
	 * Intercept all outgoing http requests and run various operations on them
	 * @param request
	 * @param next
	 */
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// Don't add an access token to unsecured routes
		if (this.isUnsecuredRoute(request.url)) {
			return next.handle(request);
		}

		// Allow the refresh request itself to pass through immediately.
		if (request.url.includes(this.USER_REFRESH_TOKEN_PATH)) {
			return next.handle(this.setAccessToken(request, this.sessionQuery.getToken()));
		}

		// Wait for the refresh service to complete before handling other requests.
		return this.sessionService
			.waitForUserTokenRefresh()
			.pipe(switchMap(() => next.handle(this.setAccessToken(request, this.sessionQuery.getToken()))));
	}

	/**
	 * Clone a request with an Authorization token attached
	 * @param request
	 * @param accessToken
	 */
	private setAccessToken(request: HttpRequest<unknown>, accessToken: string | undefined): HttpRequest<unknown> {
		// We clone the request, because the original request is immutable
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
	}

	/**
	 * Check if the url contains a match to one of our unsecured routes
	 * @param requestUrl
	 */
	private isUnsecuredRoute(requestUrl: string): boolean {
		let match = false;
		this.UNSECURED_ROUTES.forEach((part) => {
			if (requestUrl.indexOf(part) > -1) {
				match = true;
			}
		});
		return match;
	}
}
