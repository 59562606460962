import { Injectable } from '@angular/core';
import { SessionStore } from './session.store';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { LoginResponse, VerifyResponse, WppOpenLoginResponse } from './session.model';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { AppSection } from '../global/global.model';
import { CacheService } from '../cache/cache.service';

/**
 * Session Service
 * This service is responsible for the session logic and API calls.
 */
@Injectable({
	providedIn: 'root',
})
export class SessionService {
	public defaultHeaders = new HttpHeaders({
		Accept: 'application/json',
	});

	// Flag indicating if the user refresh token call is done
	private refreshCompleted = true;
	// Subject that emits once the refresh is finished
	private refreshSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor(private sessionStore: SessionStore, protected httpClient: HttpClient, private readonly cacheService: CacheService) {}

	/**
	 * Verify a user's email on our system and then send the email a code
	 * to enter to verify that it is valid and they have access to it.
	 */
	public requestCode(email: string) {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient
			.post<VerifyResponse>(
				`${environment.apiUrl}/user/auth/request-sign-in`,
				{ email, organizationId: environment.organizationId },
				{ headers }
			)
			.pipe(
				tap((response) => {
					this.sessionStore.setLoading(false);
					this.sessionStore.updateLoginDetails({
						clientId: response.clientId,
						issuer: response.issuer,
						profile: {
							...this.sessionStore.getValue().profile,
							email,
						},
					});
				}),
				catchError((err) => {
					console.log('Login Error', err);
					this.sessionStore.setLoading(false);
					this.sessionStore.setError(err);
					return throwError(err);
				})
			);
	}

	/**
	 * Send the code provided by the user to make sure its valid, then return an access token.
	 */
	public activateEmail(email: string, singlePass: string, hasAcceptedTerms?: boolean): Observable<LoginResponse> {
		// http call to verify code
		const params = {
			singlePass,
			email,
			organizationId: environment.organizationId,
			hasAcceptedTerms,
		};
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient.post<LoginResponse>(`${environment.apiUrl}/user/auth/basic/code-sign-in`, params, { headers }).pipe(
			tap((resp) => {
				this.sessionStore.login({
					token: resp.token,
					profile: resp.profile,
				});

				Sentry.setUser({ email: resp?.profile?.email });

				this.sessionStore.setLoading(false);
			})
		);
	}

	/**
	 * Attempt to sign in via okta based on the org settings.
	 */
	public oktaSignIn(email: string, accessToken: AccessToken, idToken: IDToken): Observable<LoginResponse> {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient
			.post<LoginResponse>(
				`${environment.apiUrl}/user/auth/okta/sign-in`,
				{
					email,
					organizationId: environment.organizationId,
					accessToken,
					idToken,
				},
				{ headers }
			)
			.pipe(
				tap((resp) => {
					this.sessionStore.login({
						token: resp.token,
						profile: resp.profile,
					});

					Sentry.setUser({ email: resp?.profile?.email });

					this.sessionStore.setLoading(false);
				})
			);
	}

	/**
	 * Attempt to sign in via Wpp Open.
	 */
	public wppOpenSignIn(email: string, accessToken: string): Observable<WppOpenLoginResponse> {
		const headers = this.defaultHeaders;
		return this.httpClient
			.post<WppOpenLoginResponse>(
				`${environment.apiUrl}/user/auth/wpp-open/sign-in`,
				{
					email,
					organizationId: environment.organizationId,
					accessToken,
				},
				{ headers }
			)
			.pipe(
				tap((response) => {
					// Authenticate user
					this.sessionStore.login({
						token: response.token,
						profile: response.profile,
						isLoggedIn: true,
					});
				})
			);
	}

	public wppOpenRequestAccess(email: string, message: string, accessToken: string): Observable<void> {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient.post<void>(
			`${environment.apiUrl}/user/auth/wpp-open/request-access`,
			{
				email,
				message,
				organizationId: environment.organizationId,
				accessToken,
			},
			{ headers }
		);
	}

	public serverToServerSignIn(organizationId: string, userId: string, token: string): Observable<LoginResponse> {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient
			.post<LoginResponse>(
				`${environment.apiUrl}/user/auth/server-to-server/sign-in`,
				{
					organizationId,
					userId,
					token,
				},
				{
					headers,
				}
			)
			.pipe(
				tap((resp) => {
					this.sessionStore.login({
						token: resp.token,
						profile: resp.profile,
					});
					Sentry.setUser({ email: resp?.profile?.email });
					this.sessionStore.setLoading(false);
				})
			);
	}

	public azureSignIn(organizationId: string, userId: string, token: string): Observable<LoginResponse> {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient
			.post<LoginResponse>(
				`${environment.apiUrl}/user/auth/azure/sign-in`,
				{
					organizationId,
					userId,
					token,
				},
				{
					headers,
				}
			)
			.pipe(
				tap((resp) => {
					this.sessionStore.login({
						token: resp.token,
						profile: resp.profile,
					});
					Sentry.setUser({ email: resp?.profile?.email });
					this.sessionStore.setLoading(false);
				})
			);
	}

	/**
	 * Check a user's token for validity, return user profile.
	 */
	public getUserStatus(token: string): Observable<LoginResponse> {
		console.log('Getting User Status');
		this.refreshCompleted = false;
		this.refreshSubject.next(false);
		return this.httpClient.get<LoginResponse>(`${environment.apiUrl}/user/refresh`).pipe(
			tap((resp: LoginResponse) => {
				this.sessionStore.login({
					token: resp.token,
					profile: resp.profile,
				});

				Sentry.setUser({ email: resp?.profile?.email });

				this.refreshCompleted = true;
				this.refreshSubject.next(true);
			}),
			catchError((err) => {
				this.refreshCompleted = true;
				this.refreshSubject.next(true);
				return throwError(() => err);
			})
		);
	}

	/**
	 * Returns an Observable that waits until the refresh call has completed.
	 */
	public waitForUserTokenRefresh(): Observable<boolean> {
		if (this.refreshCompleted) {
			return of(true);
		}
		return this.refreshSubject.pipe(
			filter((status) => status === true),
			take(1)
		);
	}

	public setUserDefaultQuickview(section: AppSection, id: string | null): void {
		this.sessionStore.update({
			profile: {
				...this.sessionStore.getValue().profile,
				privateProfile: {
					...this.sessionStore.getValue().profile.privateProfile,
					defaultQuickViewIds: {
						...this.sessionStore.getValue().profile.privateProfile.defaultQuickViewIds,
						[section]: id,
					},
				},
			},
		});
	}

	/**
	 * Set the Akita loading state.
	 */
	public setLoading(state: boolean): void {
		this.sessionStore.setLoading(state);
	}

	/**
	 * Set the url the user was attempting to go to before having to login.
	 */
	public setInitialUrl(url: string): void {
		this.sessionStore.update({
			initialUrl: url,
		});
	}

	/**
	 * Remove the users's access token.
	 */
	public logout(): void {
		this.sessionStore.logout();
		this.cacheService.clear();
	}
}
