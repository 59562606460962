<footer>
	<div>© {{ YEAR }} SIMPLcx. All rights reserved.</div>
	<div>
		<ol>
			<li>
				<a href="https://shopper-planning.s3.us-east-2.amazonaws.com/terms.pdf" target="_blank">Terms and Conditions</a>
			</li>
			<li>
				<a routerLink="/faq"> FAQ </a>
			</li>
			<li>
				<a href="mailto:support@vml.freshdesk.com"> Report an Issue </a>
			</li>
		</ol>
	</div>
</footer>
