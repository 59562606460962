/**
 * A bunch of type checking utilties
 */

export const isBoolean = (arg: unknown): arg is boolean => arg === !!arg;

export const isDate = (d: unknown): d is Date => d instanceof Date && !isNaN(d.getTime());

export const isError = (err: unknown): err is Error => err instanceof Error;

export const isNil = (val: unknown): val is null | undefined => val == null;

export const isNull = (val: unknown): val is null => val === null;

export const isUndefined = (val: unknown): val is undefined => val === undefined;

export const isNumber = (a: unknown): a is number => typeof a === 'number';

export const isObject = <T extends object = { [key: string]: unknown }>(a: unknown): a is T => a !== null && typeof a === 'object';
export const isRegExp = (obj: unknown): obj is RegExp => obj instanceof RegExp;

export const isString = (a: unknown): a is string => typeof a === 'string';

export const isSVG = (str: unknown): str is SVGElement | string =>
	str instanceof SVGElement || (typeof str === 'string' && str.startsWith('<svg'));

export const isCanvas = (a: unknown): a is HTMLCanvasElement => a instanceof HTMLCanvasElement;
