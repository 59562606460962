import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PublicComment } from '../../../../../api/src/comment/comment.entity';
import { isString } from '../../_core/utils/types.utils';

@Pipe({
	name: 'mention',
})
export class MentionPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(comment: PublicComment): SafeHtml {
		// If we have tagged users saved, we don't need to transform the body
		// because the tagged users will be rendered as span elements with the class "tagged-user"

		if (comment.taggedUserIds || !comment.body || !isString(comment.body)) {
			return comment.body;
		}

		// If we don't have tagged users saved, we try to transform the body
		// This is more or less fallback for old comments that have tagged users

		// This regex matches:
		// 1. @ at the beginning of the string followed by non-whitespace characters, OR
		// 2. Space followed by @ followed by non-whitespace characters
		// In both cases, optionally followed by a space and a capital letter followed by more characters.
		const mentionRegex = /(^@[^\s]+(?:\s[A-Z][^\s]*)?|\s@[^\s]+(?:\s[A-Z][^\s]*)?)/g;
		const transformed = comment.body.replace(mentionRegex, (match) => {
			// Check if there's another "@" in the match after the first character.
			// If there is, it's likely part of an email address, so skip styling.
			if (match.indexOf('@', 1) !== -1) return match;

			// If there's a space in the match, check if the character after the space is a capital letter
			const spaceIndex = match.indexOf(' ');
			if (spaceIndex !== -1) {
				// If the character after the space is not a capital letter, only highlight up to the space
				if (!/[A-Z]/.test(match[spaceIndex + 1])) {
					const mention = match.substring(0, spaceIndex);
					return `<span style="color: rgba(0, 174, 239, 1);">${mention}</span>${match.substring(spaceIndex)}`;
				}
			}

			return `<span style="color: rgba(0, 174, 239, 1);">${match}</span>`;
		});

		return this.sanitizer.bypassSecurityTrustHtml(transformed);
	}
}
