import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HtmlDialogData } from './html-data-dialog.model';

@Component({
	selector: 'app-html-dialog',
	templateUrl: './html-dialog.component.html',
	styleUrls: ['./html-dialog.component.scss'],
})
export class HtmlDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<HtmlDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: HtmlDialogData
	) {}

	ngOnInit(): void {}

	dismiss(): void {
		this.dialogRef.close();
	}
}
