import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Entities, FileProgressData } from '../../../../state/global/global.model';
import { GlobalService } from '../../../../state/global/global.service';
import { Entity } from '../../entities.model';
import { createFileUploadRequest, EditFileDialog, FileCategories, FileCategory, FileUploadRequest } from '../file.model';
import { FileService } from '../file.service';
import { UpdateFileDto } from '../../../../../../../api/src/file/dtos/update-file.dto';
import { FileReviewTypeEnum } from '../../../../../../../api/src/file/enums/file-review-type.enum';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { GlobalQuery } from '../../../global/global.query';
import { OrganizationSettings } from '../../../../../../../api/src/organization/organization.settings';
import {
	FilePendingReviewersResult,
	FilePendingReviewerType,
	FileReviewersDialogComponent,
	ReviewerRole,
} from '../file-reviewers-dialog/file-reviewers-dialog.component';
import { from, Subject } from 'rxjs';
import { concatMap, first, takeUntil, tap } from 'rxjs/operators';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { User } from '../../user/user.model';
import { MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-file-upload-container',
	templateUrl: './file-upload-container.component.html',
	styleUrls: ['./file-upload-container.component.scss'],
})
export class FileUploadContainerComponent implements OnInit, OnChanges, OnDestroy {
	@Input() entityId: string;
	@Input() entityType: Entities;
	@Input() file: any;
	@Input() referencePath: string;
	@Input() referenceOptions: Entity[];
	@Input() isVersionMode: boolean;
	@Input() isCategoryIgnored: boolean;
	@Output() updated: EventEmitter<File> = new EventEmitter<File>();
	@Output() fileUploadRequestChanged: EventEmitter<FileUploadRequest> = new EventEmitter<FileUploadRequest>();
	@Output() stateChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() validFileList: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() validUpdateForm: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() uploadComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

	selectedFiles?: FileList;
	progressInfos: FileProgressData[] = [];

	files: any = [];
	previews: any = [];
	categories: FileCategory[] = FileCategories.filter((cat) => cat.id);
	state: 'adding' | 'uploading' | 'complete' = 'adding';

	formGroup: FormGroup;
	isUpdate: boolean = false;
	idFile: string;

	fileUploadRequest: FileUploadRequest;

	readonly FileReviewTypeEnum = FileReviewTypeEnum;
	readonly ReviewerRole = ReviewerRole;
	filePendingReviewers: FilePendingReviewersResult[];
	settings: OrganizationSettings;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '200px',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		enableToolbar: true,
		showToolbar: false,
		sanitize: true,
		placeholder: 'Type the version description here',
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
			['strikeThrough', 'subscript', 'superscript', 'fontName'],
			[
				'fontSize',
				'textColor',
				'backgroundColor',
				'customClasses',
				'link',
				'unlink',
				'insertImage',
				'insertVideo',
				'insertHorizontalRule',
				'removeFormat',
				'toggleEditorMode',
			],
		],
	};
	minDate = new Date();
	private addOrRemoveReviewersObserverActions$: any[];

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly fileService: FileService,
		private readonly globalService: GlobalService,
		private readonly globalQuery: GlobalQuery,
		private readonly dialog: MatDialog
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.referenceOptions = [{ id: null, name: 'None' }, ...(this.referenceOptions ?? [])];
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next(undefined);
		this._unsubscribe$.complete();
	}

	ngOnInit(): void {
		this.settings = this.globalQuery.getSetting('settings');
		this.initForm();

		// If a file is passed in, switch to edit mode
		if (this.file) {
			this.startEditMode();
		}
	}

	initForm(): void {
		this.formGroup = new FormGroup({
			name: new FormControl(null),
			category: new FormControl(null),
			description: new FormControl(this.settings?.fileReviewsNotesTemplate),
			reviewType: new FormControl(
				this.settings?.hideInternalReview ? FileReviewTypeEnum.CLIENT_REVIEW : FileReviewTypeEnum.INTERNAL_REVIEW
			),
			tactic: new FormControl(null),
			feedbackDueDate: new FormControl(undefined, Validators.required),
		});
	}

	startEditMode(): void {
		this.isUpdate = true;
		const editFileDialog: EditFileDialog = {
			category: this.file?.category,
			data: {
				name: this.file?.name,
				type: this.file?.mimeType,
				path: this.file?.path,
			},
			tactic: this.file?.tacticId ? this.file?.tactic : null,
			tacticId: this.file?.tacticId,
			id: this.file?.id,
			planId: this.file?.planId,
		};

		this.files.push(editFileDialog);
		this.previews.push(editFileDialog);
		this.idFile = editFileDialog.id;

		this.formGroup.patchValue({
			name: editFileDialog.data.name,
			category: {
				id: editFileDialog.category,
			},
			tactic: {
				id: editFileDialog.tacticId,
			},
		});
	}

	addFile(event): void {
		if (this.isUpdate) {
			const element = event[0];
			this.fileUploadRequest = createFileUploadRequest({
				name: element.name?.replace(/\.[^/.]+$/, ''),
				data: element,
			});

			if (this.fileService.isImage(element.type)) {
				this.getPreviewFile(element, 0);
			}

			this.validFileList.emit(this.isValidFileList());
			this.validUpdateForm.emit(this.isValidUpdateForm());
			this.fileUploadRequestChanged.emit(this.fileUploadRequest);
		} else {
			for (let index = 0; index < event.length; index++) {
				const element = event[index];
				this.files.push(
					createFileUploadRequest({
						name: element.name?.replace(/\.[^/.]+$/, ''),
						data: element,
					})
				);

				if (this.fileService.isImage(element.type)) {
					this.getPreviewFile(element, this.files.length - 1);
				}

				this.validFileList.emit(this.isValidFileList());
				this.validUpdateForm.emit(this.isValidUpdateForm());
				this.fileUploadRequestChanged.emit(this.fileUploadRequest);
			}
		}
	}

	update(index: number, property: string, ev: any): void {
		const val = ev?.value || ev?.target?.value || '';

		if (this.isUpdate) {
			if (property === 'name') {
				this.files[index]['data'][property] = val;
			} else if (property === 'tactic') {
				this.formGroup.get('tactic')?.patchValue(val);
			} else {
				this.files[index][property] = val;
			}
			this.validUpdateForm.emit(this.isValidUpdateForm());
		} else {
			this.files[index][property] = val;
			this.validFileList.emit(this.isValidFileList());
		}
	}

	deleteAttachment(index): void {
		this.files.splice(index, 1);
		this.previews.splice(index, 1);
		this.validFileList.emit(this.isValidFileList());
	}

	getPreviewFile(file: File, index: number): void {
		const reader = new FileReader();
		reader.onload = (e) => {
			this.previews[index] = e.target.result;
		};
		reader.readAsDataURL(file);
	}

	selectFiles(event: any): void {
		this.progressInfos = [];
		this.selectedFiles = event.target.files;
	}

	upload(idx: number, request: FileUploadRequest): void {
		this.progressInfos[idx] = { progress: 0, error: undefined };

		if (request) {
			this.fileService
				.upload(request, this.entityId, this.entityType)
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe(
					(event: any) => {
						if (event.type === HttpEventType.UploadProgress) {
							// No progress for this project
							// this.progressInfos[idx].progress = Math.round((100 * event.loaded) / event.total);
						} else if (event instanceof HttpResponse) {
							this.progressInfos[idx].progress = 100;
							this.fileService.add(event.body);
							this.checkIfAllCompleted();
						}
					},
					(err: Error) => {
						this.progressInfos[idx] = {
							progress: 0,
							error: 'Could not upload the file: ' + err.message,
						};
					}
				);
		}
	}

	uploadFiles(): void {
		this.state = 'uploading';
		this.stateChange.emit(this.state);

		if (this.files) {
			for (let i = 0; i < this.files.length; i++) {
				this.upload(i, this.files[i]);
			}
		}
	}

	updateFile(): void {
		this.state = 'uploading';
		// Save pending file reviewers first, then update file
		if (this.filePendingReviewers?.length) {
			this.addOrRemoveReviewersObserverActions$ = [];
			this.filePendingReviewers.forEach((reviewDialogResult) => {
				if (reviewDialogResult?.type === FilePendingReviewerType.CREATE) {
					this.addOrRemoveReviewersObserverActions$.push(
						this.fileService.addReviewers(
							this.file,
							[...new Set((reviewDialogResult.reviewers ?? []).map((reviewer) => reviewer.email))],
							reviewDialogResult.role,
							reviewDialogResult.message,
							reviewDialogResult.feedbackDueDate,
							false
						)
					);
				}
				if (reviewDialogResult?.type === FilePendingReviewerType.REMOVE) {
					this.addOrRemoveReviewersObserverActions$.push(
						this.fileService.removeReviewers(this.file, [reviewDialogResult.userId], reviewDialogResult.role)
					);
				}
			});

			this._executeFileReviewersObserverChanges();
		} else {
			this._updateFile();
		}
	}

	checkIfAllCompleted(): void {
		let completed = true;
		this.progressInfos.forEach((info) => {
			if (info.progress < 100) {
				completed = false;
			}
		});

		if (completed) {
			this.uploadComplete.emit();
			this.state = 'complete';
			this.stateChange.emit(this.state);
		}
	}

	getFileTypeIcon(mimeType: string): string {
		return this.fileService.getFileTypeIcon(mimeType);
	}

	compareWithId(a: any, b: any): boolean {
		return a?.id === b?.id;
	}

	isValidFileList(): boolean {
		if (this.files.length < 1) {
			return false;
		}

		return !this.files.find((file) => !file.name || file.name.length < 1 || !(file.category || this.isCategoryIgnored));
	}

	isValidUpdateForm(): boolean {
		if (this.files.length < 1) {
			return false;
		}
		if (this.isVersionMode && !this.formGroup.valid) {
			return false;
		}

		return !(!this.files[0].category || this.files[0].data?.name < 1);
	}

	isImage(file): boolean {
		return this.fileService.isImage(file?.data?.type);
	}

	onSetFileReviewType($event: MatButtonToggleChange): void {
		this.formGroup.get('reviewType').setValue($event.value);
	}

	onOpenManageReviewersDialog(file: PublicFile): void {
		const dialogRef = this.dialog.open(FileReviewersDialogComponent, {
			data: { file: file, previewOnly: true, filePendingReviewers: this.filePendingReviewers },
			panelClass: ['fullscreen', 'background-color', 'max-width-sm'],
			disableClose: false,
		});
		dialogRef
			.afterClosed()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((dialogResults: FilePendingReviewersResult[]) => {
				if (dialogResults !== undefined) {
					this.filePendingReviewers = dialogResults;
				}
			});
	}

	mergeExistingAndPendingReviewers(reviewers: User[], pendingReviewers: FilePendingReviewersResult[], role: ReviewerRole): User[] {
		let mergedReviewers = reviewers ?? [];
		(pendingReviewers ?? [])
			?.filter((val) => val.role === role)
			.forEach((pendingReviewer) => {
				if (pendingReviewer.type === FilePendingReviewerType.CREATE) {
					mergedReviewers = [...mergedReviewers, ...pendingReviewer.reviewers];
				} else if (pendingReviewer.type === FilePendingReviewerType.REMOVE) {
					mergedReviewers = mergedReviewers.filter((reviewer) => reviewer.id !== pendingReviewer.userId);
				}
			});

		return mergedReviewers;
	}

	onToggleDescriptionEditorFocus(): void {
		this.editorConfig = {
			...this.editorConfig,
			showToolbar: !this.editorConfig.showToolbar,
		};
	}

	private _updateFile(): void {
		let data = {
			category: this.formGroup.get('category')?.value?.id,
			name: this.formGroup.get('name').value,
			description: this.formGroup.get('description').value,
			reviewType: this.formGroup.get('reviewType').value,
			tacticId: this.formGroup.get('tactic').value?.id,
			feedbackDueDate: this.formGroup.get('feedbackDueDate').value,
		} as UpdateFileDto;

		this.stateChange.emit(this.state);
		if (this.isVersionMode) {
			data = {
				...data,
				createNewVersion: true,
			};
		}

		this.fileService
			.update(this.files[0], data, this.fileUploadRequest)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(res: any) => {
					this.updated.emit(res);
					this.state = 'complete';
					this.stateChange.emit(this.state);
				},
				(err: HttpErrorResponse) => {
					this.globalService.triggerErrorMessage(err);
				}
			);
	}

	private _executeFileReviewersObserverChanges(): void {
		let currentSavedFile;
		from(this.addOrRemoveReviewersObserverActions$)
			.pipe(
				concatMap((observable) =>
					observable.pipe(
						tap((current) => {
							currentSavedFile = current;
						})
					)
				),
				takeUntil(this._unsubscribe$)
			)
			.subscribe(
				(results) => {},
				(error) => {
					this.globalService.triggerErrorMessage(error);
				},
				() => {
					this._updateFile();
				}
			);
	}
}
