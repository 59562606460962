import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SessionQuery } from '../../../../state/session/session.query';
import { SessionService } from '../../../../state/session/session.service';
import { fade } from '../../../../_core/utils/animations.utils';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { ApiError } from '../../../../../../../api/src/_core/interfaces/api-error.interface';
import { DataService } from '../../../../_core/services/data.service';
import { WppOpenUserAuthDto } from '../../../../state/integrations/wpp-open/dtos/WppOpenUserAuthDto';

@Component({
	selector: 'app-wpp-open-request',
	templateUrl: './wpp-open-request.component.html',
	styleUrls: ['./wpp-open-request.component.scss'],
	animations: [fade('fade', 400, '-50%')],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WppOpenRequestComponent implements OnInit, OnDestroy {
	errorResponse: Partial<ApiError>;
	successMessage: string;

	public loading$: Observable<boolean>;
	email: string;
	accessToken: string;
	message: string;

	protected readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly sessionService: SessionService,
		private readonly sessionQuery: SessionQuery,
		private readonly activatedRoute: ActivatedRoute,
		private readonly dataService: DataService<Partial<WppOpenUserAuthDto>>
	) {
		this.loading$ = this.sessionQuery.selectLoading();
	}

	ngOnInit(): void {
		this.sessionService.setLoading(false);
		this.email = this.activatedRoute.snapshot.queryParams.email ?? this.dataService.getData()?.email;
	}

	public onRequestAccess(): void {
		this.email = this.activatedRoute.snapshot.queryParams.email ?? this.dataService.getData()?.email;
		this.accessToken = this.activatedRoute.snapshot.queryParams.accessToken ?? this.dataService.getData()?.token;

		this.sessionService
			.wppOpenRequestAccess(this.email, this.message, this.accessToken)
			.pipe(
				take(1),
				tap(() => {
					this.sessionService.setLoading(false);
					this.successMessage = 'Access request sent. You will receive an email when your request is approved.';
				}),
				catchError((err) => {
					return this._handleError(err.error);
				}),
				takeUntil(this._unsubscribe$)
			)
			.subscribe();
	}

	private async _handleError(err: ApiError): Promise<void> {
		this.errorResponse = err;
		if (err.statusCode === 403) {
			this.errorResponse = { message: 'Unable to authenticate user, try again later.' };
			this.sessionService.setLoading(false);
			return;
		}
		this.sessionService.setLoading(false);
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}
}
