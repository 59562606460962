import { Pipe, PipeTransform } from '@angular/core';

// checks if object is empty {} and returns undefined
@Pipe({
	name: 'emptyObject',
})
export class EmptyObjectPipe implements PipeTransform {
	transform<T extends object>(obj: T | null | undefined): T | undefined {
		return obj && Object.keys(obj).length > 0 ? obj : undefined;
	}
}
