import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PublicCustomField, CustomFieldType } from '../../../../../../api/src/custom-field/entities/custom-field.entity';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, take } from 'rxjs/operators';
import { GlobalService } from '../../../state/global/global.service';
import { HttpErrorResponse } from '@angular/common/http/http';

@Component({
	selector: 'app-custom-field',
	templateUrl: './custom-field.component.html',
	styleUrls: ['./custom-field.component.scss'],
})
export class CustomFieldComponent implements OnInit, OnDestroy {
	@Input() customField: PublicCustomField;
	@Input() entity: 'Program' | 'Tactic';
	@Input() entityId: string;
	@Input() value?: any;

	public CustomFieldType = CustomFieldType;
	public formGroup: FormGroup;

	private readonly _unsubscribe$ = new Subject<void>();

	constructor(private readonly formBuilder: FormBuilder, private readonly globalService: GlobalService) {}

	ngOnInit(): void {
		let value = this.value ? this.value : '';
		if (this.customField.type === CustomFieldType.MultiSelect && !value) {
			value = [];
		}
		console.log('%c value', 'color: red; font-weight: bold;', value);
		this.formGroup = this.formBuilder.group({
			[this.customField.id]: [value],
		});

		this.formGroup.valueChanges.pipe(takeUntil(this._unsubscribe$), debounceTime(1000)).subscribe((value) => {
			this.globalService
				.updateCustomFieldValue(
					this.entity === 'Program' ? 'program' : 'tactic',
					this.entityId,
					this.customField.id,
					value[this.customField.id]
				)
				.pipe(take(1))
				.subscribe({
					next: () => {
						this.globalService.triggerSaveMessage();
					},
					error: (err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err),
				});
		});
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}
}
