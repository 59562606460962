import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { GlobalService } from '../../../state/global/global.service';

@Injectable({
	providedIn: 'root',
})
export class WppOpenViewModeGuard implements CanActivate {
	constructor(private globalService: GlobalService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		Promise.resolve().then(() => {
			this.globalService.setViewMode('wpp-open');
		});
		return true;
	}
}
