import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/browser';

if (environment.production) {
	enableProdMode();
	// Remove logs in production
	window.console.log = (): void => {};
	window.console.error = (): void => {};
	window.console.debug = (): void => {};
	window.console.warn = (): void => {};

	Sentry.init({
		release: `shopper-planning-web@${window['version']}`,
		environment: window['environment'] ?? 'development',
		dsn: 'https://2305387dae7e4e379dd32c9cb66decfd@o506945.ingest.sentry.io/5597323',
		autoSessionTracking: true,
		integrations: [
			new BrowserTracing({
				tracingOrigins: ['localhost', environment.apiUrl], // TODO: Add real server urls
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
			new Sentry.Replay(),
		],

		replaysSessionSampleRate: 0.1,

		replaysOnErrorSampleRate: 1.0,

		tracesSampleRate: 1.0,
	});

	Sentry.setTag('organization', environment.organizationId);
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
