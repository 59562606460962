import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn, Unique } from 'typeorm';
import { Organization } from '../organization/organization.entity';

export const strategicBriefNestleRetailerActivationBriefSlug = 'nestle-retailer-activation-brief' as const;
export const strategicBrieVmlStrategyBriefSlug = 'vml-strategy-brief' as const;

export const strategicBriefSupportedBriefTypes = [
	strategicBriefNestleRetailerActivationBriefSlug,
	strategicBrieVmlStrategyBriefSlug,
] as const;

export type StrategicBriefSupportedType = typeof strategicBriefSupportedBriefTypes[number];

@Entity('deliverables')
@Unique(['slug', 'organization'])
@Index(['name'])
export class DeliverableEntity {
	@PrimaryGeneratedColumn('uuid', { name: 'id' }) id: string;
	@Column({ name: 'name', type: 'text', nullable: false }) name: string;
	@Column({ name: 'slug', type: 'text', nullable: true }) slug: string;
	@Column({ name: 'label', type: 'text', nullable: true }) label: string;
	@Column('boolean', { default: false, nullable: false }) deleted: boolean;
	@Column('boolean', { default: false, nullable: false }) enabled: boolean;

	@ManyToOne(() => Organization, { eager: false, nullable: true, onDelete: 'CASCADE' })
	@JoinColumn({ name: 'organization_id' })
	organization: string | Organization;
}
