import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SessionQuery } from '../../../../state/session/session.query';
import { SessionService } from '../../../../state/session/session.service';
import { fade } from '../../../../_core/utils/animations.utils';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiError } from '../../../../../../../api/src/_core/interfaces/api-error.interface';
import { DataService } from '../../../../_core/services/data.service';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'app-wpp-open-error-response',
	templateUrl: './wpp-open-error-response.component.html',
	styleUrls: ['./wpp-open-error-response.component.scss'],
	animations: [fade('fade', 400, '-50%')],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WppOpenErrorResponseComponent implements OnInit {
	public environment = environment;
	public loading$: Observable<boolean>;
	public data: Partial<ApiError>;

	constructor(
		private readonly sessionService: SessionService,
		private readonly sessionQuery: SessionQuery,
		private readonly activatedRoute: ActivatedRoute,
		private readonly dataService: DataService<Partial<ApiError>>
	) {
		this.loading$ = this.sessionQuery.selectLoading();
	}

	ngOnInit(): void {
		this.sessionService.setLoading(false);
		this.data = this.dataService.getData();
	}
}
