<div mat-dialog-title fxLayout="row">
	<div fxLayout="column">
		<div class="doc-dialog-title-row">
			<h3>{{ data.title }}</h3>
		</div>
		<p class="fw-300 mt-4">
			{{ data.description }}
		</p>
	</div>
	<div class="actions margin-left-auto">
		<button mat-flat-button color="secondary" class="button-extra-small" [mat-dialog-close]="false">Close</button>
	</div>
</div>

<div class="doc-light-container">
	<div *ngFor="let section of data.sections; first as firstElement" [ngSwitch]="section.type">
		<!-- Main Title -->
		<div class="doc-main-title" *ngSwitchCase="DocumentationQASectionTypeEnum.MAIN_TITLE" [class.mt-0]="firstElement">
			<app-documentation-dynamic-type
				[text]="section.text"
				[formats]="section.formats"
				[qaType]="true"
			></app-documentation-dynamic-type>
		</div>

		<!-- Title -->
		<div class="doc-container-title" *ngSwitchCase="DocumentationQASectionTypeEnum.TITLE" [class.mt-0]="firstElement">
			<app-documentation-dynamic-type
				[text]="section.text"
				[formats]="section.formats"
				[qaType]="true"
			></app-documentation-dynamic-type>
		</div>

		<!-- Subtitle -->
		<div class="doc-container-subtitle" *ngSwitchCase="DocumentationQASectionTypeEnum.SUBTITLE" [class.mt-0]="firstElement">
			<app-documentation-dynamic-type
				[text]="section.text"
				[formats]="section.formats"
				[qaType]="true"
			></app-documentation-dynamic-type>
		</div>

		<!-- Bulleted List -->
		<div *ngSwitchCase="DocumentationQASectionTypeEnum.BULLETED_LIST">
			<app-documentation-dynamic-type
				[text]="section.text"
				[formats]="section.formats"
				[qaType]="true"
			></app-documentation-dynamic-type>
			<ul class="doc-unordered-list" *ngIf="section?.bulletedList">
				<li *ngFor="let item of section.bulletedList">
					<app-documentation-dynamic-type
						[text]="item"
						[formats]="section.formats"
						[qaType]="true"
					></app-documentation-dynamic-type>
				</li>
			</ul>
		</div>

		<!-- Default Paragraph -->
		<p class="doc-default-paragraph" *ngSwitchCase="DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH">
			<app-documentation-dynamic-type
				[text]="section.text"
				[formats]="section.formats"
				[qaType]="true"
			></app-documentation-dynamic-type>
		</p>
	</div>

	<!-- QA Directions -->
	<div class="mt-6 mb-5 full-width" fxLayoutAlign="center center">
		<button mat-flat-button class="icon-left" fxLayoutAlign="center center" (click)="openQADirections()">
			<i class="uil-clipboard-notes"></i> <span>Open QA Directions</span>
		</button>
	</div>
</div>
