import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mergeArrays',
})
export class MergeArraysPipe implements PipeTransform {
	transform(...arrays: unknown[][]): unknown[] {
		let result: unknown[] = [];
		for (const arr of arrays) {
			result = [...(result ?? []), ...(arr ?? [])];
		}
		return result;
	}
}
