/**
 * Sanitizes a filename by removing any path components, replacing
 * potentially dangerous or problematic characters with underscores,
 * and ensuring that the filename doesn't start with a period (hidden
 * files).
 *
 * @param fileName The filename to sanitize.
 * @returns The sanitized filename.
 */
export function sanitizeFileName(fileName: string): string {
	// Remove any path components (for security)
	let sanitized = fileName.split(/[\/\\]/).pop() || '';

	// Replace potentially dangerous or problematic characters
	sanitized = sanitized.replace(/[^a-zA-Z0-9_\-\.]/g, '_');

	// Ensure the filename doesn't start with a period (hidden files)
	if (sanitized.startsWith('.')) {
		sanitized = '_' + sanitized;
	}

	return sanitized;
}

/**
 * Validates if a given string is a valid filename.
 * Rules:
 * - Can only contain English alphanumeric characters, underscores, and hyphens
 * - Cannot be empty
 * - Cannot be longer than 100 characters
 *
 * @param fileName The filename to validate
 * @returns true if the filename is valid, false otherwise
 */
export function isFileNameValid(fileName: string): boolean {
	// Check for empty name
	if (!fileName || fileName.trim() === '') {
		return false;
	}

	// Check file name length
	if (fileName.length > 100) {
		return false;
	}

	// Check if filename contains only allowed characters
	const validFileNameRegex = /^[a-zA-Z0-9_\-\.]+$/;
	return validFileNameRegex.test(fileName);
}

/**
 * Convert a Blob to a base64 image string.
 * @param blob The Blob to convert.
 * @returns A Promise that resolves with the base64 image string.
 */
export function getBase64ImageFromBlob(blob): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			function () {
				resolve(reader.result as string);
			},
			false
		);

		reader.onerror = (): void => {
			return reject(this);
		};

		reader.readAsDataURL(blob);
	});
}
