import moment from 'moment';

export enum Cadence {
	Weeks = 'weeks',
	Months = 'months',
	Years = 'years',
}

export class TimeUtils {
	public static getFormattedDate(date: Date, format: string = 'MM/dd/yyyy'): string {
		return moment(date).format(format);
	}

	public static isValidTimeZone(zone: string) {
		const parsed = Intl.DateTimeFormat('ia', {
			timeZoneName: 'short',
			timeZone: zone,
		});

		if (parsed?.formatToParts()?.find((p) => p.type === 'timeZoneName')) {
			return true;
		}

		return false;
	}

	public static shiftDateToTimeZone(date: Date, zone: string, addHours: number = 0): Error | number {
		const time = date?.getTime();
		if (!time || isNaN(time)) {
			return new Error(`Invalid date. Can't shift Date.`);
		}

		const parsed = Intl.DateTimeFormat('ia', {
			timeZoneName: 'short',
			timeZone: zone,
		});
		let zoneString = parsed?.formatToParts()?.find((p) => p.type === 'timeZoneName')?.value;
		if (!zoneString) {
			return new Error(`Invalid time zone. Can't shift Date.`);
		}

		// Remove the "GMT" prefix.
		zoneString = parsed.format(date).split('GMT')[1];
		const sign = zoneString.includes('+') ? '+' : '-';
		// Remove the sign.
		zoneString = zoneString.replace(sign, '');
		const zoneNumber = Number(zoneString);
		if (isNaN(zoneNumber)) {
			return new Error(`Unable to parse offset. Can't shift Date.`);
		}

		// The operation needs to be inverted because the sign is opposite of the operation we need to do.
		if (sign === '-') {
			return time + this.hToMs(zoneNumber) + this.hToMs(addHours);
		} else {
			return time - this.hToMs(zoneNumber) + this.hToMs(addHours);
		}
	}

	public static getMonthStartAndEnd(date: string) {
		const parsed = new Date(date);
		if (!parsed || parsed.toString() === 'Invalid Date') {
			return Error('Invalid Date.');
		}
		const year = parsed.getFullYear();
		const month = parsed.getMonth();
		return {
			start: new Date(year, month).getTime(),
			end: new Date(year, month + 1, 0).getTime(),
			//end: new Date(new Date(year, month + 1, 0).getTime() + this.durationStringToMs('1d'))
		};
	}

	public static percentOfItemDurationInPeriod(
		itemStartDate: string,
		itemEndDate: string,
		periodStartDate?: string,
		periodEndDate?: string,
		removeTzAndNormalize: boolean = false
	) {
		if (removeTzAndNormalize) {
			if (itemStartDate) {
				itemStartDate = this.stripTimeZoneFromDate(itemStartDate);
			}
			if (itemEndDate) {
				itemEndDate = this.stripTimeZoneFromDate(itemEndDate);
			}
			if (periodStartDate) {
				periodStartDate = this.stripTimeZoneFromDate(periodStartDate);
			}
			if (periodEndDate) {
				periodEndDate = this.stripTimeZoneFromDate(periodEndDate);
			}
		}

		const itemStart = new Date(itemStartDate).getTime();
		const itemEnd = new Date(itemEndDate).getTime() + (removeTzAndNormalize ? this.durationStringToMs('1d') : 0);
		let periodStart = new Date(periodStartDate).getTime();
		let periodEnd = new Date(periodEndDate).getTime();

		if (isNaN(periodStart)) {
			periodStart = itemStart;
		}
		if (isNaN(periodEnd)) {
			periodEnd = itemEnd;
		} else {
			periodEnd += removeTzAndNormalize ? this.durationStringToMs('1d') : 0;
		}

		const start = Math.max(itemStart, periodStart);
		const end = Math.min(itemEnd, periodEnd);

		// No overlap, return 0;
		if (end < start || itemEnd < itemStart) {
			return 0;
		}

		const itemDuration = itemEnd - itemStart;
		const duration = end - start;

		return duration / itemDuration;
	}

	public static stripTimeZoneFromDate(date: string | Date) {
		if (date instanceof Date) {
			date = date.toISOString();
		}
		const d = date.split('T')[0];
		return `${d}T00:00:00-00:00`;
	}

	public static addTimezoneToDate(date: Date, timezone: string) {
		const timezoneOffset = new Date(date.toLocaleString('en-US', { timeZone: timezone })).getTimezoneOffset();
		const timezoneOffsetHours = timezoneOffset / 60;
		const timezoneOffsetMinutes = timezoneOffset % 60;
		const sign = timezoneOffsetHours < 0 ? '+' : '-';
		const timezoneOffsetString = `${sign}${Math.abs(timezoneOffsetHours).toString().padStart(2, '0')}:${Math.abs(timezoneOffsetMinutes)
			.toString()
			.padStart(2, '0')}`;
		const dateString = date.toISOString().split('T')[0];
		const timeString = date.toTimeString().split(' ')[0];
		return `${dateString}T${timeString}${timezoneOffsetString}`;
	}

	public static isValidDuration(start: string, end: string): true | Error {
		// Make sure the start/end dates parse to a standard format.
		try {
			start = new Date(start).toISOString();
		} catch (err) {
			return new Error("Invalid value for parameter: 'start'.");
		}
		try {
			end = new Date(end).toISOString();
		} catch (err) {
			return new Error("Invalid value for parameter: 'end'.");
		}

		const startDate = new Date(start).getTime();
		const endDate = new Date(end).getTime();
		if (startDate > endDate) {
			return new Error("Something can't start after it has ended or end before it has started.");
		} else if (endDate === startDate) {
			return new Error('A duration of zero not valid.');
		}

		return true;
	}

	public static durationStringToMs(durationString: string): number {
		if (!durationString) {
			return 0;
		}

		const durationMultiplier = parseFloat(durationString);
		const durationType = durationString.replace(/[0-9\.]/g, '');

		switch (durationType) {
			case 'h':
				return this.hToMs(1 * durationMultiplier);
			case 'd':
				return this.dToMs(1 * durationMultiplier);
			case 'm':
				return this.mToMs(1 * durationMultiplier);
			case 's':
				return this.sToMs(1 * durationMultiplier);
			default:
				return 0;
		}
	}

	public static durationStringToSQLFormat(durationString: string): string {
		if (durationString.includes('h')) {
			return durationString.replace('h', ' hours');
		}
		if (durationString.includes('d')) {
			return durationString.replace('d', ' days');
		}
		if (durationString.includes('m')) {
			return durationString.replace('m', ' minutes');
		}
		if (durationString.includes('s')) {
			return durationString.replace('s', ' seconds');
		}
		return durationString;
	}

	public static getTimePeriods(from: string, to: string, cadence: Cadence) {
		let timePeriods = [];
		const startDate: Date = new Date(from);
		const endDate: Date = new Date(to);

		if (cadence === Cadence.Years) {
			timePeriods = this.yearsBetweenDates(startDate, endDate);
		} else if (cadence === Cadence.Months) {
			timePeriods = this.monthsBetweenDates(startDate, endDate);
		} else if (cadence === Cadence.Weeks) {
			timePeriods = this.weeksBetweenDates(startDate, endDate);
		}
		return timePeriods;
	}

	/**
	 *
	 * @param from start date iso string
	 * @param to start date iso string
	 * @returns an array of preprocessed months between two given dates
	 */
	public static monthsBetweenDates(from: Date, to: Date) {
		// converting iso strings in Dates
		const startDate = new Date(from);
		const endDate = new Date(to);

		const result = [];

		// difference in integer between months
		const diff = Math.max((endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth(), 0);

		// loop to get the information we need for each month
		for (let index = 0; index <= diff; index++) {
			const date = new Date(startDate);
			date.setMonth(date.getMonth() + index);
			const monthName = date.toLocaleString('default', { month: 'long' });
			const obj = {
				id: monthName.toLowerCase(),
				name: monthName,
				from: new Date(date.getFullYear(), date.getMonth(), 1),
				to: new Date(date.getFullYear(), date.getMonth() + 1, 0.99),
			};

			//If index matchs with the first/last month we make sure that the from/to property is correct
			if (index === 0) {
				obj.from = startDate;
			}
			if (index === diff) {
				obj.to = endDate;
			}

			//We make sure that at least there is one day between from and to dates. the !! operator will return false if 0.
			if (this.daysBetweenDates(obj.from, obj.to)) {
				result.push(obj);
			}
		}

		return result;
	}

	/**
	 *
	 * @param from start date iso string
	 * @param to start date iso string
	 * @returns an array of preprocessed weeks between two given dates
	 */
	public static weeksBetweenDates(from: Date, to: Date) {
		// TODO
		const startDate = new Date(from);
		const endDate = new Date(to);

		const result = [];

		// dates diff in weeks
		const diff = this.daysBetweenDates(from, to) / 7;

		// loop to get the information we need for each week
		for (let index = 0; index <= diff; index++) {
			const date = new Date(startDate);
			date.setDate(date.getDate() + 7 * index); // multiply per 7 to get the period start date
			const weekName = `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + (date.getDate() + 1)).slice(-2)}`;

			const obj = {
				id: weekName.toLowerCase(),
				name: weekName,
				from: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0),
				to: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7, 0, 0),
			};

			//If index matchs with the first/last month we make sure that the from/to property is correct
			if (index === 0) {
				obj.from = startDate;
			}
			if (index === diff) {
				obj.to = endDate;
			}

			//We make sure that at least there is one day between from and to dates. the !! operator will return false if 0.
			if (this.daysBetweenDates(obj.from, obj.to)) {
				result.push(obj);
			}
		}

		return result;
	}

	/**
	 *
	 * @param from start date iso string
	 * @param to start date iso string
	 * @returns an array of preprocessed weeks between two given dates
	 */
	public static yearsBetweenDates(from: Date, to: Date) {
		// converting iso strings in Dates
		const startDate = new Date(from);
		const endDate = new Date(to);

		const result = [];

		// difference in integer between years
		const diff = endDate.getFullYear() - startDate.getFullYear();

		// loop to get the information we need for each year
		for (let index = 0; index <= diff; index++) {
			const year = startDate.getFullYear() + index;
			const obj = {
				id: year.toString(),
				name: `Year ${year}`,
				from: new Date(year, 0, 1),
				to: new Date(year + 1, 0, 0.99),
			};

			// If index matches with the first/last year we make sure that the from/to property is correct
			if (index === 0) {
				obj.from = startDate;
			}
			if (index === diff) {
				obj.to = endDate;
			}

			// We make sure that at least there is one day between from and to dates. the !! operator will return false if 0.
			if (this.daysBetweenDates(obj.from, obj.to)) {
				result.push(obj);
			}
		}

		return result;
	}

	/**
	 *
	 * @param startDate
	 * @param endDate
	 * @param endInclusive Assumes the end starts at 00:00:00 and adds 24 hours.
	 * @returns the amount of days between two given dates
	 */
	public static daysBetweenDates(from: Date, to: Date, endInclusive?: boolean) {
		const startDate = from;
		const endDate = to;
		const diff = new Date(startDate).getTime() - (new Date(endDate).getTime() + (endInclusive ? this.durationStringToMs('1d') : 0));
		const days = Math.ceil(diff / this.durationStringToMs('1d'));
		return Math.abs(days);
	}

	/**
	 *
	 * @param monthDays Number of days of the month
	 * @param budgetPeriodDays Number of total days of the budgetPeriod
	 * @returns The percentage of days in the month based on the total number of days in the budgetPeriod
	 */
	public static getPercentageOfMonth(monthDays: number, budgetPeriodDays: number) {
		return (monthDays * 100) / budgetPeriodDays;
	}

	// From MS
	public static msToS(ms): number {
		return ms / 1000;
	}

	public static msToM(ms): number {
		return this.msToS(ms) / 60;
	}

	public static msToH(ms): number {
		return this.msToM(ms) / 60;
	}

	public static msToD(ms): number {
		return this.msToH(ms) * 24;
	}

	// To MS
	public static sToMs(s): number {
		return s * 1000;
	}

	public static mToMs(m): number {
		return this.sToMs(m) * 60;
	}

	public static hToMs(h): number {
		return this.mToMs(h) * 60;
	}

	public static dToMs(d): number {
		return this.hToMs(d) * 24;
	}

	public static hasNoOverlapDates(
		dates: { start: string | Date; end: string | Date }[] = [],
		startDate?: Date,
		endDate?: Date,
		ignoreTimeForComparison?: boolean
	): boolean {
		const rangeStart = typeof startDate === 'string' ? new Date(startDate) : startDate;
		const rangeEnd = typeof endDate === 'string' ? new Date(endDate) : endDate;

		if (ignoreTimeForComparison) {
			rangeStart.setHours(0, 0, 0, 0);
			rangeEnd.setHours(23, 59, 59, 999);
		}

		const sortedRanges = dates.slice().sort((a, b) => {
			const startDateA = typeof a.start === 'string' ? new Date(a.start) : a.start;
			const startDateB = typeof b.start === 'string' ? new Date(b.start) : b.start;
			return startDateA.getTime() - startDateB.getTime();
		});

		for (let i = 0; i < sortedRanges.length; i++) {
			const range = sortedRanges[i];

			const rangeStartDate = typeof range.start === 'string' ? new Date(range.start) : range.start;
			if (ignoreTimeForComparison) {
				rangeStartDate.setHours(0, 0, 0, 0);
			}
			const rangeEndDate = typeof range.end === 'string' ? new Date(range.end) : range.end;
			if (ignoreTimeForComparison) {
				rangeEndDate.setHours(23, 59, 59, 999);
			}

			if (rangeStartDate < rangeStart || rangeEndDate > rangeEnd) {
				return false; // Dates outside the specified range
			}

			if (rangeStartDate >= rangeEndDate) {
				return false; // Invalid start and end dates
			}

			if (i > 0) {
				const previousRange = sortedRanges[i - 1];
				const previousRangeEndDate = typeof previousRange.end === 'string' ? new Date(previousRange.end) : previousRange.end;
				if (ignoreTimeForComparison) {
					previousRangeEndDate.setHours(23, 59, 59, 999);
				}
				if (rangeStartDate <= previousRangeEndDate) {
					return false; // Overlapping ranges
				}
			}
		}

		const uniqueDates = new Set<string>();
		for (const range of sortedRanges) {
			const rangeStartStr = typeof range.start === 'string' ? range.start : range.start.toISOString();
			const rangeEndStr = typeof range.end === 'string' ? range.end : range.end.toISOString();
			if (uniqueDates.has(rangeStartStr) || uniqueDates.has(rangeEndStr)) {
				return false; // Duplicate dates
			}
			uniqueDates.add(rangeStartStr);
			uniqueDates.add(rangeEndStr);
		}

		return true;
	}
}
