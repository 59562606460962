<ng-container *ngIf="loaded">
	<div class="page-wrapper">
		<app-sidebar-nav *ngIf="showSidebar"></app-sidebar-nav>
		<div class="content">
			<router-outlet></router-outlet>
		</div>
		<app-footer *ngIf="showSidebar"></app-footer>
	</div>
</ng-container>

<div class="loading" *ngIf="!loaded">
	<mat-spinner [diameter]="40"></mat-spinner>
</div>

<div class="documentation-container" *ngIf="showDocumentationButton">
	<!-- The other buttons will only appear if the menu is expanded -->
	<div class="documentation-buttons-wrapper" [class.expanded]="documentationService.menuExpanded$ | async">
		<!-- Documentation Interactive Mode Button -->
		<!-- <div
			class="documentation-button"
			[class.documentation-button__enabled]="documentationService.isActive$ | async"
			(click)="documentationService.toggleInteractiveMode()"
		>
			<i class="uil-mouse-alt"></i>
		</div> -->

		<!-- Documentation Flows Button -->
		<div class="documentation-button ml-2 mr-2" (click)="openDocumentationFlows()">
			<i class="uil-layer-group"></i>
		</div>

		<!-- QA Button -->
		<div class="documentation-button mr-2" (click)="openDocumentationQA()">
			<i class="uil-notes"></i>
		</div>
	</div>

	<!-- Documentation Main Button -->
	<div class="documentation-button" (click)="documentationService.toggleMenu()" style="z-index: 1001">
		<i class="uil-document-info"></i>
	</div>
</div>
