<section class="entity">
	<div *ngIf="!loading; else showLoader" class="column-flex">
		<div class="header-container">
			<div class="header-title">{{ title }}</div>

			<button class="action-button" *ngIf="!hideRemove && imageUrl" (click)="removeImage()">Remove</button>
			<button class="action-button" *ngIf="!imageUrl" (click)="onFileInputClick()">Upload</button>
		</div>

		<div *ngIf="!imageUrl" appDropFile class="upload-container" (click)="onFileInputClick()" (filesDropped)="onFileDrop($event)">
			<p class="font-size-small">Click to upload or drop image file here</p>
			<input hidden type="file" #fileInput [accept]="validTypes.join(', ')" (change)="onFileDrop($event.target.files)" />
		</div>

		<div class="url-preview" *ngIf="imageUrl">{{ urlDisplay }}</div>

		<div *ngIf="!hidePreview && imageUrl" class="preview-container">
			<div class="preview-title">{{ previewTitle }}</div>

			<div class="preview-content">
				<img class="preview-image" [src]="imageUrl | secure | async" />
			</div>
		</div>
	</div>

	<ng-template #showLoader>
		<div class="loader">
			<div class="loading">
				<mat-spinner [diameter]="40"></mat-spinner>
			</div>
		</div>
	</ng-template>
</section>
