import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { getTimeDifferences, TimeDifferences } from '../../_core/utils/date.utils';

@Pipe({
	name: 'friendlyDateTime',
})
export class FriendlyDateTimePipe implements PipeTransform {
	constructor(private datePipe: DatePipe) {}
	/**
	 * Transforms a date into a human-readable relative string.
	 *
	 * For past dates:
	 *  - < 60 seconds: "just now"
	 *  - < 60 minutes: "X minutes ago"
	 *  - < 24 hours: if todayOption is 'today', returns "today"; otherwise returns the formatted time.
	 *  - Exactly 1 day: "yesterday"
	 *  - < 7 days: "X days ago"
	 *  - Otherwise: returns the date formatted using dateFormat.
	 *
	 * For future dates:
	 *  - < 60 seconds: "in a few seconds"
	 *  - < 60 minutes: "in X minutes"
	 *  - < 24 hours: if todayOption is 'today', returns "today"; otherwise returns the formatted time.
	 *  - Exactly 1 day: "tomorrow"
	 *  - < 7 days: "in X days"
	 *  - Otherwise: returns the date formatted using dateFormat.
	 *
	 * @param value The date to transform.
	 * @param dateFormat The fallback format for dates outside the relative threshold. Default is 'MM/dd/yyyy'.
	 * @param timeFormat The time format to use when todayOption is 'time'. Default is 'hh:mm a' (AM/PM format).
	 * @param todayOption Determines what to display for dates on the same day:
	 *                    - 'today': displays the literal "today"
	 *                    - 'time': displays the time (using timeFormat)
	 *                    Default is 'time'.
	 * @returns A human-readable relative time string.
	 */
	transform(
		value: Date | string,
		dateFormat: string = 'MM/dd/yyyy',
		timeFormat: string = 'hh:mm a',
		todayOption: 'today' | 'time' = 'time'
	): string {
		if (!value) {
			return '';
		}

		const date = new Date(value);
		if (isNaN(date.getTime())) {
			return 'Invalid date';
		}

		const now = new Date();
		const diffMs = now.getTime() - date.getTime();
		const isPast = diffMs >= 0;
		const timeDifferences = getTimeDifferences(date, now);

		return isPast
			? this._getFormattedPastRelativeTime(timeDifferences, date, dateFormat, timeFormat, todayOption)
			: this._getFormattedFutureRelativeTime(timeDifferences, date, dateFormat, timeFormat, todayOption);
	}

	/**
	 * Returns a formatted string for past dates.
	 *
	 * @param timeDifferences
	 * @param date
	 * @param dateFormat
	 * @param timeFormat
	 * @param todayOption
	 * @private
	 */
	private _getFormattedPastRelativeTime(
		timeDifferences: TimeDifferences,
		date: Date,
		dateFormat: string,
		timeFormat: string,
		todayOption: 'today' | 'time'
	): string {
		const { seconds: diffSeconds, minutes: diffMinutes, hours: diffHours, days: diffDays } = timeDifferences;

		if (diffSeconds < 60) {
			return 'just now';
		}
		if (diffMinutes < 60) {
			return diffMinutes === 1 ? '1 minute ago' : `${diffMinutes} minutes ago`;
		}
		if (diffHours < 24) {
			return todayOption === 'today' ? 'today' : this.datePipe.transform(date, timeFormat) || '';
		}
		if (diffDays === 1) {
			return 'yesterday';
		}
		if (diffDays < 7) {
			return diffDays === 1 ? '1 day ago' : `${diffDays} days ago`;
		}
		return this.datePipe.transform(date, dateFormat) || '';
	}

	/**
	 * Returns a formatted string for future dates.
	 *
	 * @param timeDifferences
	 * @param date
	 * @param dateFormat
	 * @param timeFormat
	 * @param todayOption
	 * @private
	 */
	private _getFormattedFutureRelativeTime(
		timeDifferences: TimeDifferences,
		date: Date,
		dateFormat: string,
		timeFormat: string,
		todayOption: 'today' | 'time'
	): string {
		const { seconds: diffSeconds, minutes: diffMinutes, hours: diffHours, days: diffDays } = timeDifferences;
		if (diffSeconds < 60) {
			return 'in a few seconds';
		}
		if (diffMinutes < 60) {
			return diffMinutes === 1 ? 'in 1 minute' : `in ${diffMinutes} minutes`;
		}
		if (diffHours < 24) {
			return todayOption === 'today' ? 'today' : this.datePipe.transform(date, timeFormat) || '';
		}
		if (diffDays === 1) {
			return 'tomorrow';
		}
		if (diffDays < 7) {
			return diffDays === 1 ? 'in 1 day' : `in ${diffDays} days`;
		}
		return this.datePipe.transform(date, dateFormat) || '';
	}
}
