import { Location } from '@angular/common';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, Routes } from '@angular/router';
import { OsService } from '@wppopen/angular';
import { OsContextValue } from '@wppopen/angular/wpp-open.service';
import { catchError, concatMap, EMPTY, filter, map, Observable, of, Subject, take, takeUntil, tap, throwError } from 'rxjs';
import { ApiError } from '../../../../../../api/src/_core/interfaces/api-error.interface';
import { DataService } from '../../../_core/services/data.service';
import { appRoutes, emptyRoutes } from '../../../app-routing.module';
import { GlobalService } from '../../../state/global/global.service';
import { WppOpenAppContextDto } from '../../../state/integrations/wpp-open/dtos/wpp-open-app-context.dto';
import { WppOpenUserAuthDto } from '../../../state/integrations/wpp-open/dtos/WppOpenUserAuthDto';
import { ApiErrorSource, WppOpenMiddlewareService } from '../../../state/integrations/wpp-open/wpp-open-middleware.service';
import { WppOpenUtils } from '../../../state/integrations/wpp-open/wpp-open.utils';
import { SessionQuery } from '../../../state/session/session.query';

export enum WppOpenCustomContextKeys {
	ROUTE = 'route',
}

@Injectable({ providedIn: 'root' })
export class WppOpenMicroAppInitializationService implements OnDestroy {
	readonly LOG_TAG = 'WppOpenMicroApp';
	baseUrl: string;

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly injector: Injector,
		private readonly router: Router,
		private readonly location: Location,
		private readonly sessionQuery: SessionQuery,
		private readonly globalService: GlobalService,
		private readonly dataService: DataService<Partial<WppOpenUserAuthDto> | Partial<ApiError>>,
		private readonly wppOpenMiddlewareService: WppOpenMiddlewareService
	) {
		if (WppOpenUtils.isRunningUnderSingleSpa) {
			this.router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					map((event: NavigationEnd) => {
						// Interect all routes that don't include the baseUrl
						if (!event.url.includes(this.baseUrl)) {
							this.router.navigateByUrl(this.router.url, {
								skipLocationChange: true,
								replaceUrl: true,
							});

							this.location.go(`${this.baseUrl.replace(/\/$/, '')}${event.url}`);
							this._registerRoutes(this.baseUrl);
							return EMPTY;
						}

						return event;
					})
				)
				.pipe(takeUntil(this._unsubscribe$))
				.subscribe();
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	init(): Observable<unknown> {
		// If running under single-spa, initialize the Wpp Open micro app
		console.log(this.LOG_TAG, 'isRunningUnderSingleSpa', WppOpenUtils.isRunningUnderSingleSpa);

		if (WppOpenUtils.isRunningUnderSingleSpa) {
			return this._initializeWppOpenMicroApp();
		} else {
			// If not running under single-spa, initialize the app as usual
			return of(undefined);
		}
	}

	private _initializeWppOpenMicroApp(): Observable<unknown> {
		const osService = this.injector.get(OsService, null);
		console.log(this.LOG_TAG, 'Initializing Wpp Open Micro App', osService);

		if (!osService) {
			return throwError(() => new Error('No OS service found'));
		}
		let appContext: WppOpenAppContextDto;
		return osService.context$.pipe(
			take(1),
			// Chain the sign-in observable if needed and pass the context along
			concatMap((osContext) => {
				// Initialize view for WppOpen
				this.globalService.setViewMode('wpp-open');
				// Register base routes
				this.baseUrl = osContext?.osContext?.baseUrl;

				if (!this.baseUrl) {
					return throwError(() => new Error('No base URL found'));
				}

				console.log(this.LOG_TAG, 'Wpp Open Micro OS context', osContext);

				appContext = WppOpenUtils.extractMicroAppContext((osContext as OsContextValue)?.osContext);
				const wppOpenUserAuthDto = WppOpenUtils.extractUserAuth(osContext);

				// If the user is not logged in, perform wppOpenSignIn and wait for it
				if (!this.sessionQuery.isLoggedIn()) {
					return this.wppOpenMiddlewareService
						.authAndSetGlobalOrganizationConfig(
							wppOpenUserAuthDto.email,
							wppOpenUserAuthDto.token,
							appContext.tenant.id,
							appContext.tenant.homeUrl
						)
						.pipe(
							tap(() => this.globalService.setViewMode('wpp-open')),
							// After sign-in completes, emit the original context for the next step
							map(() => osContext),
							catchError((err: ApiErrorSource) => {
								const navigateOn404 = err.source === 'wppOpenSignIn';
								if (err.statusCode === 404 && navigateOn404) {
									// Navigate to request page
									this.dataService.setData({
										token: wppOpenUserAuthDto.token,
										email: wppOpenUserAuthDto.email,
									});
									this.location.go(`${osContext.osContext.baseUrl.replace(/\/$/, '')}/wpp-open/request`);
									this._registerRoutes(this.baseUrl);
									return EMPTY;
								} else {
									this.dataService.setData(err);
									this.location.go(`${osContext.osContext.baseUrl.replace(/\/$/, '')}/wpp-open/error`);
									this._registerRoutes(this.baseUrl);
									return EMPTY;
								}
							})
						);
				}
				// If already logged in, simply continue with the context
				return of(osContext);
			}),
			// Next, chain the baseUrl observable
			concatMap(() => osService.baseUrl$.pipe(take(1))),
			tap((baseUrl) => {
				console.log(this.LOG_TAG, 'Version data: ', appContext.appInstance.devhubMetadata.version);
				// Key for custom route
				const definedRoute = (
					(appContext?.appInstance?.devhubMetadata?.version?.customContext as unknown[]).find((value: unknown) => {
						return (value as { key: string }).key === WppOpenCustomContextKeys.ROUTE;
					}) as { value }
				)?.value as string;

				if (!definedRoute) {
					this.globalService.triggerErrorMessage(undefined, 'Missing defined custom context.');
					return throwError(() => new Error('Missing defined custom context.'));
				}
				// If the current path is not the baseUrl and not the defined route, navigate to the current path
				if (
					this.cleanPath(window.location.pathname) !== this.cleanPath(this.baseUrl) &&
					!window.location.href.includes(definedRoute)
				) {
					this.location.go(this.cleanPath(window.location.pathname));
					this._registerRoutes(baseUrl as string);
					return;
				}
				// Navigate to the defined route
				this.location.go(`${(baseUrl as string).replace(/\/$/, '')}/${definedRoute}`);
				this._registerRoutes(baseUrl as string);
			})
		);
	}

	/**
	 * Register routes
	 *
	 * @param baseUrl
	 * @private
	 */
	private _registerRoutes(baseUrl: string): void {
		const mappedRoutes = appRoutes.map((route) => ({
			...route,
			path: route.path ? `${baseUrl}/${route.path}` : baseUrl,
			redirectTo: route.redirectTo ? `${baseUrl}/${route.redirectTo}` : undefined,
		}));

		const routerConfig: Routes = [...mappedRoutes, ...emptyRoutes];
		console.log(this.LOG_TAG, 'Mapped Routes', routerConfig);
		this.router.resetConfig(routerConfig);
		if (!this.router.navigated) {
			this.router.initialNavigation();
		}
	}

	// Clean path by removing leading and trailing slashes
	private cleanPath(path: string): string {
		return path.replace(/^\/+|\/+$/g, '');
	}
}
