import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
	name: 'textSwitcher'
})
export class TextSwitcherPipe implements PipeTransform {
	transform(
		value: any,
		text: [string, string],
		translation?: {
			wordsToBeChanged: string[];
			string$: Observable<string | null>;
		}
	): string | Observable<string> {
		if (!translation) {
			if (value) {
				return text[0];
			} else {
				return text[1];
			}
		}
		return translation.string$.pipe(
			map(string => {
				const str = value ? text[0] : text[1];
				const wordsToBeChanged = translation.wordsToBeChanged;
				const modifiedString = str
					.split(' ')
					.map(word => (wordsToBeChanged.includes(word) ? string : word))
					.join(' ');
				return modifiedString;
			})
		);
	}
}
