import { Injectable, Inject } from '@angular/core';

import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import 'prismjs';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-sass';
import 'prismjs/components/prism-scss';

declare let Prism: any;

@Injectable({ providedIn: 'root' })
export class HighlightService {
	constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

	highlightAll() {
		if (isPlatformBrowser(this.platformId)) {
			Prism.highlightAll();
		}
	}
}
