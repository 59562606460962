export enum CommentSelect {
	Author = 'author',
	CommentFiles = 'commentFiles',
	CommentReplies = 'replies',
}

export class Query {
	private static readonly COMMENT_QUERY_ALIAS = 'ccm';

	public static getSelects(
		alias: string = this.COMMENT_QUERY_ALIAS,
		targets: CommentSelect[] = Object.values(CommentSelect),
		asJsonSelect: boolean = false
	) {
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${json ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${json ? `'status', ${alias}."status"` : `${alias}."status"`}
		`);
		selects.push(`
			${json ? `'body', ${alias}."body"` : `${alias}."body"`}
		`);
		selects.push(`
			${json ? `'annotations', ${alias}."annotations"` : `${alias}."annotations"`}
		`);
		selects.push(`
			${json ? `'taggedUserIds', ${alias}."taggedUserIds"` : `${alias}."taggedUserIds"`}
		`);
		selects.push(`
			${json ? `'fileId', ${alias}."fileId"` : `${alias}."fileId"`}
		`);
		selects.push(`
			${json ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);
		selects.push(`
			${json ? `'created', ${alias}.created` : `${alias}.created`}
		`);
		selects.push(`
			${json ? `'updated', ${alias}.updated` : `${alias}.updated`}
		`);
		selects.push(`
			${json ? `'parentCommentId', ${alias}."parentCommentId"` : `${alias}."parentCommentId"`}
		`);

		if (targets.includes(CommentSelect.Author)) {
			if (json) {
				selects.push(`
					'author', ${alias}_author.author
				`);
			} else {
				selects.push(`
					${alias}_author.author AS author
				`);
			}
		}

		if (targets.includes(CommentSelect.CommentFiles)) {
			if (json) {
				selects.push(`
					'commentFiles', ${alias}_files.array
				`);
			} else {
				selects.push(`
					${alias}_files.array AS "commentFiles"
				`);
			}
		}

		if (targets.includes(CommentSelect.CommentReplies)) {
			if (json) {
				selects.push(`
					'commentReplies', ${alias}_replies.array
				`);
			} else {
				selects.push(`
					${alias}_replies.array AS "commentReplies"
				`);
			}
		}

		return selects.join(',');
	}

	public static getSubqueries(alias: string = this.COMMENT_QUERY_ALIAS, targets: CommentSelect[] = Object.values(CommentSelect)) {
		if (!targets.length) {
			return '';
		}

		const subQueries: string[] = [];

		if (targets.includes(CommentSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSONB_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if (targets.includes(CommentSelect.CommentFiles)) {
			subQueries.push(`
					LATERAL (
						SELECT ARRAY (
							SELECT JSONB_BUILD_OBJECT (
								'id', f2."id",
								'name', f2."name",
								'mimeType', f2."mimeType",
								'path', f2."path"
							)
						FROM
							"files" AS f2
						WHERE
							f2."commentId" = ${alias}."id"
						)) AS ${alias}_files
				`);
		}

		if (targets.includes(CommentSelect.CommentReplies)) {
			subQueries.push(`
			LATERAL (
				SELECT ARRAY (
					SELECT JSONB_BUILD_OBJECT (
						'id', c2."id",
						'body', c2."body",
						'updated', c2."updated",
						'parentCommentId', c2."parentCommentId",
						'taggedUserIds', c2."taggedUserIds",
						'author', JSONB_BUILD_OBJECT(
							'id', u3.id,
							'email', u3."email",
							'profile', u3."profile"
						)
					)
					FROM "comments" AS c2
					LEFT JOIN "users" AS u3 ON c2."authorId" = u3.id
					WHERE c2."parentCommentId" = ${alias}."id"
					ORDER BY c2."created" ASC
				)
			) AS ${alias}_replies
		`);
		}

		if (!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}
