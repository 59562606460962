import { DocumentationQABasic } from '../../../models/qa/doc-qa-basic.model';
import { DocumentationQASectionTypeEnum } from '../../../models/qa/doc-qa-types.model';

export const documentationTacticCostByFlightDashboardQA: DocumentationQABasic = {
	title: 'Tactic Cost by Flight Dashboard',
	description:
		'The Tactic Cost by Flight Dashboard provides a summary of Tactic cost for all tactics within programs in the "activate" phase and breaks it out by Flight Start Month. It allows clients to view their budgets broken down by key segments such as brand and retailer, and Month.',
	sections: [
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Overview',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'This dashboard provides a detailed breakdown of tactic costs, organized by the month in which each flight starts, offering visibility into spending patterns across different brands, retailers, and time periods.',
		},
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Widgets',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Tactic Cost by Flight',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'This widget provides a table view of Tactic Cost (Spend) by Flight Start Month:',
			bulletedList: [
				'Every tactic is shown with total costs.',
				'If the tactic has flights created under it, each flight is summed based on what month the tactic starts in.',
			],
		},
	],
	directions: [
		{
			title: 'Create a New Budget Period',
			completed: false,
			items: [
				{
					title: 'Begin by setting up a new budget period in the system.',
					completed: false,
				},
			],
		},
		{
			title: 'Create 1 Brand Program',
			completed: false,
			items: [
				{
					title: 'Ensure the following details are filled out in the Tactic Tab:',
					completed: false,
					items: [
						{
							title: 'Tactic 1: Includes no tactic cost.',
							completed: false,
						},
						{
							title: 'Tactic 2: Includes tactic cost with no flights.',
							completed: false,
						},
						{
							title: 'Tactic 3: Includes tactic cost with multiple flights.',
							completed: false,
							items: [
								{
									title: '2 Flights should start in the same month.',
									completed: false,
								},
								{
									title: '1 Flight should start in its own month.',
									completed: false,
								},
							],
						},
					],
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the Tactic Cost by Flight Dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist for 1 Brand Program',
			completed: false,
			items: [
				{
					title: 'Confirm the widget shows all 3 tactics with the appropriate Total Tactic Cost.',
					completed: false,
				},
				{
					title: 'Confirm that the tactic with flights is showing the correct values in the corresponding month columns. For the month with multiple flights starting in it, the total flight cost should be summed.',
					completed: false,
				},
			],
		},
		{
			title: 'Create a Multi-Brand Program',
			completed: false,
			items: [
				{
					title: 'Ensure the following fields are filled in the Tactic Tab:',
					completed: false,
					items: [
						{
							title: 'Tactic 1: Includes no tactic cost (uses both brands).',
							completed: false,
						},
						{
							title: 'Tactic 2: Includes tactic cost with no flights (uses both brands).',
							completed: false,
						},
						{
							title: 'Tactic 3: Includes tactic cost with multiple flights (uses both brands).',
							completed: false,
							items: [
								{
									title: '2 Flights should start in the same month.',
									completed: false,
								},
								{
									title: '1 Flight should start in its own month.',
									completed: false,
								},
							],
						},
						{
							title: 'Tactic 4: Includes tactic cost with multiple flights (uses only 1 brand).',
							completed: false,
							items: [
								{
									title: '2 Flights should start in the same month.',
									completed: false,
								},
								{
									title: '1 Flight should start in its own month.',
									completed: false,
								},
							],
						},
					],
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the Tactic Cost by Flight Dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist for Multi-Brand Program',
			completed: false,
			items: [
				{
					title: 'Confirm the widget shows all 4 tactics with the appropriate Total Tactic Cost.',
					completed: false,
				},
				{
					title: 'Confirm that the tactics with flights are showing the correct values in the corresponding month columns. For the month with multiple flights starting in it, the total flight cost should be summed.',
					completed: false,
				},
				{
					title: 'Filter to just 1 of the brands that is part of the multi-brand program, and recheck the dashboard.',
					completed: false,
				},
			],
		},
	],
};
