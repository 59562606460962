import { DocumentationQABasic } from '../../models/qa/doc-qa-basic.model';
import { DocumentationQATypeEnum } from '../../models/qa/doc-qa-types.model';
import { documentationBudgetDashboardQA } from './reporting/budget-dashboard.qa';
import { documentationClientDropDownDashboardQA } from './reporting/client-dropdown-dashboard.qa';
import { documentationComplianceTrackerDashboardQA } from './reporting/compliance-tracker-dasdhboard.qa';
import { documentationNestleBriefSummaryDashboardQA } from './reporting/nestle-brief-summary-dashboard.qa';
import { documentationProgramSpendDashboardQA } from './reporting/program-spend-dashboard.qa';
import { documentationRMNvsNonRMNDashboardQA } from './reporting/rmn-vs-non-rmn-dashboard.qa';
import { documentationTacticCostByFlightDashboardQA } from './reporting/tactic-cost-by-flight.qa';

export const documentationQAData: { [type in DocumentationQATypeEnum]: DocumentationQABasic } = {
	[DocumentationQATypeEnum.BUDGET_DASHBOARD]: documentationBudgetDashboardQA,
	[DocumentationQATypeEnum.PROGRAM_SPEND_DASHBOARD]: documentationProgramSpendDashboardQA,
	[DocumentationQATypeEnum.RMN_VS_NON_RMN_DASHBOARD]: documentationRMNvsNonRMNDashboardQA,
	[DocumentationQATypeEnum.COMPLIANCE_TRACKER_DASHBOARD]: documentationComplianceTrackerDashboardQA,
	[DocumentationQATypeEnum.CLIENT_DROPDOWN_DASHBOARD]: documentationClientDropDownDashboardQA,
	[DocumentationQATypeEnum.TACTIC_COST_BY_FLIGHT_DASHBOARD]: documentationTacticCostByFlightDashboardQA,
	[DocumentationQATypeEnum.NESTLE_BRIEF_SUMMARY_DASHBOARD]: documentationNestleBriefSummaryDashboardQA,
};
