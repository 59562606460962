import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to convert a string to a number.
 * For example '$1,000.00' becomes 1000 and '50%' becomes 0.5.
 *
 */

@Pipe({
	name: 'maskToNumber',
})
export class MaskToNumberPipe implements PipeTransform {
	transform(value: string | null): number {
		const str = value == null ? '' : value.toString();
		const isPercentage = str.includes('%');
		const cleanedStr = str.replace(/[$,%]/g, '').replace(',', '');
		let num = parseFloat(cleanedStr);
		if (isPercentage) {
			return (num /= 100);
		}
		return num;
	}
}
