import { Query as TacticQuery, QueryOptions as TacticQueryOptions, TacticSelect } from '../../tactic/utils/query.utils';
import { Query as BudgetAllocationQuery } from '../../budget-allocation/utils/query.utils';
import { Query as WarningQuery } from '../../warning/utils/query.utils';
import { Query as FileQuery } from '../../file/utils/query.utils';
import { Query as ExternalIdQuery } from '../../external-id/utils/query.utils';
import { InvestmentSelect, Query as InvestmentQuery } from '../../investment/utils/query.utils';

export enum ProgramSelect {
	Plans = 'plans',
	Retailer = 'retailer',
	Agency = 'agency',
	Location = 'location',
	ProgramPhase = 'programPhase',
	ProgramSector = 'programSector',
	ProgramType = 'programType',
	ProgramUtilization = 'programUtilization',
	BrandInitiative = 'brandInitiative',
	BudgetPeriod = 'budgetPeriod',
	BudgetAllocations = 'budgetAllocations',
	BudgetCache = 'budgetCache',
	BrandCaches = 'brandCaches',
	Investments = 'investments',
	Tactics = 'tactics',
	TacticsGroups = 'tacticsGroups',
	Invoices = 'invoices',
	Brands = 'brands',
	Notes = 'notes',
	Author = 'author',
	Tags = 'tags',
	Products = 'products',
	Categories = 'categories',
	Owners = 'owners',
	Warnings = 'warnings',
	Files = 'files',
	ExternalIds = 'externalIds',
	FundingSource = 'fundingSource',
	ProgramStrategicBriefs = 'programStrategicBriefs',
	Measurements = 'measurements',
	CustomFieldValues = 'customFieldValues',
}

export interface ProgramSubSelects {
	tacticSelects?: TacticSelect[];
}

export interface QueryOptions {
	includeDeletedBudgetAllocations?: boolean;
	tacticOptions?: TacticQueryOptions;
}
export class Query {
	private static readonly PROGRAM_QUERY_ALIAS: string = 'p';

	public static getSelects(
		alias: string = this.PROGRAM_QUERY_ALIAS,
		targets: ProgramSelect[] = Object.values(ProgramSelect),
		asJsonSelect: boolean = false,
		excludes: ProgramSelect[] = [],
		hasTotal: boolean = false,
		asStringArraySelect: boolean = false
	): string | string[] {
		if (!targets) {
			targets = [];
		}
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${json ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${json ? `'status', ${alias}.status` : `${alias}.status`}
		`);
		selects.push(`
			${json ? `'name', ${alias}.name` : `${alias}.name`}
		`);
		selects.push(`
			${json ? `'start', ${alias}.start` : `to_char((${alias}.start)::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"') AS start`}
		`);
		selects.push(`
			${json ? `'end', ${alias}.end` : `to_char((${alias}.end)::date + interval '12 hour', 'YYYY-MM-DD"T"HH24:MI:SS"Z"') AS end`}
		`);
		selects.push(`
			${json ? `'investmentRecap', ${alias}."investmentRecap"` : `${alias}."investmentRecap"`}
		`);
		selects.push(`
			${json ? `'atRisk', ${alias}."atRisk"` : `${alias}."atRisk"`}
		`);
		selects.push(`
			${json ? `'objectives', ${alias}.objectives` : `${alias}.objectives`}
		`);
		selects.push(`
			${json ? `'budgetRecommendation', ${alias}."budgetRecommendation"` : `${alias}."budgetRecommendation"`}
		`);
		selects.push(`
			${json ? `'brandStrategy', ${alias}."brandStrategy"` : `${alias}."brandStrategy"`}
		`);
		selects.push(`
			${json ? `'customerStrategy', ${alias}."customerStrategy"` : `${alias}."customerStrategy"`}
		`);
		selects.push(`
			${json ? `'description', ${alias}."description"` : `${alias}."description"`}
		`);
		selects.push(`
			${json ? `'goal', ${alias}."goal"` : `${alias}."goal"`}
		`);
		selects.push(`
			${json ? `'keyLearnings', ${alias}."keyLearnings"` : `${alias}."keyLearnings"`}
		`);
		selects.push(`
			${json ? `'recommendations', ${alias}."recommendations"` : `${alias}."recommendations"`}
		`);
		selects.push(`
			${json ? `'map', ${alias}."map"` : `${alias}."map"`}
		`);
		selects.push(`
			${json ? `'brandInitiativeId', ${alias}."brandInitiativeId"` : `${alias}."brandInitiativeId"`}
		`);
		selects.push(`
			${json ? `'planIds', ${alias}_programplans."planIds"` : `${alias}_programplans."planIds"`}
		`);
		selects.push(`
			${json ? `'budgetPeriodId', ${alias}."budgetPeriodId"` : `${alias}."budgetPeriodId"`}
		`);
		selects.push(`
			${json ? `'retailerId', ${alias}."retailerId"` : `${alias}."retailerId"`}
		`);
		selects.push(`
			${json ? `'agencyId', ${alias}."agencyId"` : `${alias}."agencyId"`}
		`);
		selects.push(`
			${json ? `'locationId', ${alias}."locationId"` : `${alias}."locationId"`}
		`);
		selects.push(`
			${json ? `'programPhaseId', ${alias}."programPhaseId"` : `${alias}."programPhaseId"`}
		`);
		selects.push(`
			${json ? `'programSectorId', ${alias}."programSectorId"` : `${alias}."programSectorId"`}
		`);
		selects.push(`
			${json ? `'programTypeId', ${alias}."programTypeId"` : `${alias}."programTypeId"`}
		`);
		selects.push(`
			${json ? `'programUtilizationId', ${alias}."programUtilizationId"` : `${alias}."programUtilizationId"`}
		`);
		selects.push(`
			${json ? `'commercePlatformWorkflow', ${alias}."commercePlatformWorkflow"` : `${alias}."commercePlatformWorkflow"`}
		`);
		selects.push(`
			${json ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);
		selects.push(`
			${json ? `'created', ${alias}.created` : `${alias}.created`}
		`);
		selects.push(`
			${json ? `'deleted', ${alias}.deleted` : `${alias}.deleted`}
		`);
		selects.push(`
			${json ? `'classification', ${alias}.classification` : `${alias}.classification`}
		`);
		selects.push(`
			${json ? `'classificationStatus', ${alias}."classificationStatus"` : `${alias}."classificationStatus"`}
		`);

		if (hasTotal) {
			selects.push(`
				${json ? `'total', ${alias}.total` : `${alias}.total`}
			`);
		}

		if (targets.includes(ProgramSelect.Plans) && !excludes.includes(ProgramSelect.Plans)) {
			if (json) {
				selects.push(`
					'plans', ${alias}_plans."plansArr"
				`);
			} else {
				selects.push(`
					${alias}_plans."plansArr" AS "plans"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Retailer) && !excludes.includes(ProgramSelect.Retailer)) {
			if (json) {
				selects.push(`
					'retailer', ${alias}_retailer."retailer"
				`);
			} else {
				selects.push(`
					${alias}_retailer."retailer" AS "retailer"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Agency) && !excludes.includes(ProgramSelect.Agency)) {
			if (json) {
				selects.push(`
					'agency', ${alias}_agency."agency"
				`);
			} else {
				selects.push(`
					${alias}_agency."agency" AS "agency"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Location) && !excludes.includes(ProgramSelect.Location)) {
			if (json) {
				selects.push(`
					'location', ${alias}_location."location"
				`);
			} else {
				selects.push(`
					${alias}_location."location" AS "location"
				`);
			}
		}

		if (targets.includes(ProgramSelect.ProgramPhase) && !excludes.includes(ProgramSelect.ProgramPhase)) {
			if (json) {
				selects.push(`
					'programPhase', ${alias}_programphase."programPhase"
				`);
			} else {
				selects.push(`
					${alias}_programphase."programPhase" AS "programPhase"
				`);
			}
		}

		if (targets.includes(ProgramSelect.ProgramSector) && !excludes.includes(ProgramSelect.ProgramSector)) {
			if (json) {
				selects.push(`
					'programSector', ${alias}_programsector."programSector"
				`);
			} else {
				selects.push(`
					${alias}_programsector."programSector" AS "programSector"
				`);
			}
		}

		if (targets.includes(ProgramSelect.ProgramType) && !excludes.includes(ProgramSelect.ProgramType)) {
			if (json) {
				selects.push(`
					'programType', ${alias}_programtype."programType"
				`);
			} else {
				selects.push(`
					${alias}_programtype."programType" AS "programType"
				`);
			}
		}

		if (targets.includes(ProgramSelect.ProgramUtilization) && !excludes.includes(ProgramSelect.ProgramUtilization)) {
			if (json) {
				selects.push(`
					'programUtilization', ${alias}_programutilization."programUtilization"
				`);
			} else {
				selects.push(`
					${alias}_programutilization."programUtilization" AS "programUtilization"
				`);
			}
		}

		if (targets.includes(ProgramSelect.BrandInitiative) && !excludes.includes(ProgramSelect.BrandInitiative)) {
			if (json) {
				selects.push(`
					'brandInitiative', ${alias}_brandinitiative."brandInitiative"
				`);
			} else {
				selects.push(`
					${alias}_brandinitiative."brandInitiative" AS "brandInitiative"
				`);
			}
		}

		if (targets.includes(ProgramSelect.BudgetPeriod) && !excludes.includes(ProgramSelect.BudgetPeriod)) {
			if (json) {
				selects.push(`
					'budgetPeriod', ${alias}_budgetperiod."budgetPeriod"
				`);
			} else {
				selects.push(`
					${alias}_budgetperiod."budgetPeriod" AS "budgetPeriod"
				`);
			}
		}

		if (targets.includes(ProgramSelect.BudgetAllocations) && !excludes.includes(ProgramSelect.BudgetAllocations)) {
			if (json) {
				selects.push(`
					'budgetAllocations', ${alias}_budgetallocations."budgetAllocationsArr"
				`);
			} else {
				selects.push(`
					${alias}_budgetallocations."budgetAllocationsArr" AS "budgetAllocations"
				`);
			}
		}

		if (targets.includes(ProgramSelect.BudgetCache) && !excludes.includes(ProgramSelect.BudgetCache)) {
			if (json) {
				selects.push(`
					'budgetCache', ${alias}_budgetcache."budgetCache"
				`);
			} else {
				selects.push(`
					${alias}_budgetcache."budgetCache" AS "budgetCache"
				`);
			}
		}

		if (targets.includes(ProgramSelect.BrandCaches)) {
			if (json) {
				selects.push(`
					'brandCaches', ${alias}_brandcaches."brandCaches"
				`);
			} else {
				selects.push(`
					${alias}_brandcaches."brandCaches" AS "brandCaches"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Investments) && !excludes.includes(ProgramSelect.Investments)) {
			if (json) {
				selects.push(`
					'investments', ${alias}_investments."investmentsArr"
				`);
			} else {
				selects.push(`
					${alias}_investments."investmentsArr" AS "investments"
				`);
			}
		}

		if (
			(targets.includes(ProgramSelect.Tactics) && !excludes.includes(ProgramSelect.Tactics)) ||
			(targets.includes(ProgramSelect.Invoices) && !excludes.includes(ProgramSelect.Invoices))
		) {
			if (json) {
				selects.push(`
					'tactics', ${alias}_tactics."tacticsArr"
				`);
			} else {
				selects.push(`
					${alias}_tactics."tacticsArr" AS "tactics"
				`);
			}
		}

		if (targets.includes(ProgramSelect.TacticsGroups) && !excludes.includes(ProgramSelect.TacticsGroups)) {
			if (json) {
				selects.push(`
					'tacticsGroups', ${alias}_tacticsGroups."tacticsGroupsArr"
				`);
			} else {
				selects.push(`
					${alias}_tacticsGroups."tacticsGroupsArr" AS "tacticsGroups"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Brands) && !excludes.includes(ProgramSelect.Brands)) {
			if (json) {
				selects.push(`
					'brands', ${alias}_brands."brandsArr"
				`);
			} else {
				selects.push(`
					${alias}_brands."brandsArr" AS "brands"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Notes) && !excludes.includes(ProgramSelect.Notes)) {
			if (json) {
				selects.push(`
					'notes', ${alias}_notes."notesArr"
				`);
			} else {
				selects.push(`
					${alias}_notes."notesArr" AS "notes"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Author) && !excludes.includes(ProgramSelect.Author)) {
			if (json) {
				selects.push(`
					'author', ${alias}_author."author"
				`);
			} else {
				selects.push(`
					${alias}_author."author" AS "author"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Tags) && !excludes.includes(ProgramSelect.Tags)) {
			if (json) {
				selects.push(`
					'tags', ${alias}_tags."tagsArr"
				`);
			} else {
				selects.push(`
					${alias}_tags."tagsArr" AS "tags"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Products) && !excludes.includes(ProgramSelect.Products)) {
			if (json) {
				selects.push(`
					'products', ${alias}_products."productsArr"
				`);
			} else {
				selects.push(`
					${alias}_products."productsArr" AS "products"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Categories) && !excludes.includes(ProgramSelect.Categories)) {
			if (json) {
				selects.push(`
					'categories', ${alias}_categories."categoriesArr"
				`);
			} else {
				selects.push(`
					${alias}_categories."categoriesArr" AS "categories"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Owners) && !excludes.includes(ProgramSelect.Owners)) {
			if (json) {
				selects.push(`
					'owners', ${alias}_owners."ownersArr"
				`);
			} else {
				selects.push(`
					${alias}_owners."ownersArr" AS "owners"
				`);
			}
		}

		if (targets.includes(ProgramSelect.Warnings) && !excludes.includes(ProgramSelect.Warnings)) {
			if (json) {
				selects.push(`
					'warnings', ${alias}_warnings."warningsArr"
				`);
			} else {
				selects.push(`
					${alias}_warnings."warningsArr" AS warnings
				`);
			}
		}

		if (targets.includes(ProgramSelect.Files) && !excludes.includes(ProgramSelect.Files)) {
			if (json) {
				selects.push(`
					'files', ${alias}_files."filesArr"
				`);
			} else {
				selects.push(`
					${alias}_files."filesArr" AS files
				`);
			}
		}

		if (targets.includes(ProgramSelect.ExternalIds) && !excludes.includes(ProgramSelect.ExternalIds)) {
			if (json) {
				selects.push(`
					'externalIds', ${alias}_externalids."externalIdsArr"
				`);
			} else {
				selects.push(`
					${alias}_externalids."externalIdsArr" AS "externalIds"
				`);
			}
		}

		if (targets.includes(ProgramSelect.ProgramStrategicBriefs) && !excludes.includes(ProgramSelect.ProgramStrategicBriefs)) {
			if (json) {
				selects.push(`
				'programStrategicBriefs', ${alias}_programstrategicbriefs."programStrategicBriefsArr"
			`);
			} else {
				selects.push(`
				${alias}_programstrategicbriefs."programStrategicBriefsArr" AS "programStrategicBriefs"
			`);
			}
		}

		if (targets.includes(ProgramSelect.CustomFieldValues) && !excludes.includes(ProgramSelect.CustomFieldValues)) {
			if (json) {
				selects.push(`
				'customFieldValues', ${alias}_customfieldvalues."customFieldValuesArr"
			  `);
			} else {
				selects.push(`
				${alias}_customfieldvalues."customFieldValuesArr" AS "customFieldValues"
			  `);
			}
		}

		if (asStringArraySelect) {
			return selects;
		}

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.PROGRAM_QUERY_ALIAS,
		targets: ProgramSelect[] = Object.values(ProgramSelect),
		subSelects?: ProgramSubSelects,
		excludes: ProgramSelect[] = [],
		options?: QueryOptions
	): string {
		const subQueries: string[] = [];

		subQueries.push(`
			LATERAL (
				SELECT ARRAY (
					SELECT
						ppl2."plansId" AS id
					FROM
						"programPlans" AS ppl2
					WHERE
						ppl2."programsId" = ${alias}.id
				) AS "planIds"
			) AS ${alias}_programplans
		`);

		if (!targets?.length) {
			return ',\n' + subQueries.join(',');
		}

		if (targets.includes(ProgramSelect.Plans) && !excludes.includes(ProgramSelect.Plans)) {
			const planAlias = 'pl2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', plans.id,
								'name', plans.name
							)
						FROM
							"programPlans" AS ${planAlias}
						LEFT JOIN
							plans
							ON
								plans.id = ${planAlias}."plansId"
						WHERE
							${planAlias}."programsId" = ${alias}.id
					) AS "plansArr"
				) AS ${alias}_plans
			`);
		}

		if (targets.includes(ProgramSelect.Retailer) && !excludes.includes(ProgramSelect.Retailer)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', r2.id,
								'name', r2.name
							)
						FROM
							retailers AS r2
						WHERE
							r2.id = ${alias}."retailerId"
					) AS "retailer"
				) AS ${alias}_retailer
			`);
		}

		if (targets.includes(ProgramSelect.Agency) && !excludes.includes(ProgramSelect.Agency)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', a2.id,
								'name', a2.name
							)
						FROM
							agencies AS a2
						WHERE
							a2.id = ${alias}."agencyId"
					) AS "agency"
				) AS ${alias}_agency
			`);
		}

		if (targets.includes(ProgramSelect.Location) && !excludes.includes(ProgramSelect.Location)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', l2.id,
								'name', l2.name,
								'type', l2.type,
								'map', l2.map,
								'parent', COALESCE(ROW_TO_JSON(lparent), NULL)
							)
						FROM
							locations AS l2
						LEFT JOIN
							locations AS lparent
							ON
								NLEVEL(l2."map") > 1
								AND
								lparent."map" = SUBPATH(l2."map", 0, -1)
						WHERE
							l2.id = ${alias}."locationId"
					) AS "location"
				) AS ${alias}_location
			`);
		}

		if (targets.includes(ProgramSelect.ProgramPhase) && !excludes.includes(ProgramSelect.ProgramPhase)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', pp2.id,
								'name', pp2.name,
								'color', pp2.color
							)
						FROM
							"programPhases" AS pp2
						WHERE
							pp2.id = ${alias}."programPhaseId"
					) AS "programPhase"
				) AS ${alias}_programphase
			`);
		}

		if (targets.includes(ProgramSelect.ProgramSector) && !excludes.includes(ProgramSelect.ProgramSector)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', ps2.id,
								'name', ps2.name
							)
						FROM
							"programSectors" AS ps2
						WHERE
							ps2.id = ${alias}."programSectorId"
					) AS "programSector"
				) AS ${alias}_programsector
			`);
		}

		if (targets.includes(ProgramSelect.ProgramType) && !excludes.includes(ProgramSelect.ProgramType)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', pt2.id,
								'name', pt2.name,
								'hidden', pt2.hidden
							)
						FROM
							"programTypes" AS pt2
						WHERE
							pt2.id = ${alias}."programTypeId"
					) AS "programType"
				) AS ${alias}_programtype
			`);
		}

		if (targets.includes(ProgramSelect.ProgramUtilization) && !excludes.includes(ProgramSelect.ProgramUtilization)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', pu2.id,
								'name', pu2.name
							)
						FROM
							"programUtilizations" AS pu2
						WHERE
							pu2.id = ${alias}."programUtilizationId"
					) AS "programUtilization"
				) AS ${alias}_programutilization
			`);
		}

		if (targets.includes(ProgramSelect.BrandInitiative) && !excludes.includes(ProgramSelect.BrandInitiative)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', bi2.id,
								'name', bi2.name
							)
						FROM
							"brandInitiatives" AS bi2
						WHERE
							bi2.id = ${alias}."brandInitiativeId"
					) AS "brandInitiative"
				) AS ${alias}_brandinitiative
			`);
		}

		if (targets.includes(ProgramSelect.BudgetPeriod) && !excludes.includes(ProgramSelect.BudgetPeriod)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', bp2.id,
								'name', bp2.name,
								'organizationId', bp2."organizationId",
								'start', bp2.start,
								'end', bp2.end,
								'state', bp2.state,
								'author', JSONB_BUILD_OBJECT (
									'id', u2.id,
									'email', u2."email",
									'profile', u2."profile"
								),
								'hidePlanningFundingTypes', bp2."hidePlanningFundingTypes"
							)
						FROM
							"budgetPeriods" AS bp2
						LEFT JOIN
							users AS u2
							ON
								u2.id = bp2."authorId"
						WHERE
							bp2.id = ${alias}."budgetPeriodId"
					) AS "budgetPeriod"
				) AS ${alias}_budgetperiod
			`);
		}

		if (targets.includes(ProgramSelect.BudgetAllocations) && !excludes.includes(ProgramSelect.BudgetAllocations)) {
			const budgetAllocationAlias = 'ba2';

			let deletedQuery = `AND ${budgetAllocationAlias}.deleted != true`;
			if (options?.includeDeletedBudgetAllocations) {
				deletedQuery = '';
			}

			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								${BudgetAllocationQuery.getSelects(budgetAllocationAlias, undefined, true)}
							)
						FROM
							"budgetAllocations" AS ${budgetAllocationAlias}
							${BudgetAllocationQuery.getSubqueries(budgetAllocationAlias)}
						WHERE
							${budgetAllocationAlias}."programId" = ${alias}.id
							${deletedQuery}
					) AS "budgetAllocationsArr"
				) AS ${alias}_budgetallocations
			`);
		}

		if (targets.includes(ProgramSelect.BudgetCache) && !excludes.includes(ProgramSelect.BudgetCache)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', bc2.id,
								'amountEstimated', bc2."amountEstimated",
								'amountPlanned', bc2."amountPlanned",
								'amountActual', bc2."amountActual",
								'spendEstimated', bc2."spendEstimated",
								'spendActual', bc2."spendActual",
								'details', bc2."details"
							)
						FROM
							"budgetCaches" AS bc2
						WHERE
							bc2."programId" = ${alias}.id
							AND bc2."planId" IS NULL
							AND bc2."brandId" IS NULL
							AND bc2."tacticId" IS NULL
					) AS "budgetCache"
				) AS ${alias}_budgetcache
			`);
		}

		if (targets.includes(ProgramSelect.BrandCaches)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							COALESCE(
								JSON_AGG(
									JSONB_BUILD_OBJECT (
										'amountEstimated', bc2."amountEstimated",
										'amountPlanned', bc2."amountPlanned",
										'amountActual', bc2."amountActual",
										'spendEstimated', bc2."spendEstimated",
										'spendActual', bc2."spendActual",
										'details', bc2."details"
									)
								), '[]'
							)
						FROM
							"budgetCaches" AS bc2
						WHERE
							bc2."programId" = ${alias}.id
							AND bc2."planId" IS NULL
							AND bc2."brandId" IS NOT NULL
							AND bc2."tacticId" IS NULL
					) AS "brandCaches"
				) AS ${alias}_brandcaches
			`);
		}

		if (targets.includes(ProgramSelect.Investments) && !excludes.includes(ProgramSelect.Investments)) {
			const investmentAlias = 'inv2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								${InvestmentQuery.getSelects(investmentAlias, undefined, true, [InvestmentSelect.Program])}
							)
						FROM
							"investments" AS ${investmentAlias}
							${InvestmentQuery.getSubqueries(investmentAlias)}
						WHERE
							${investmentAlias}."programId" = ${alias}.id
					) AS "investmentsArr"
				) AS ${alias}_investments
			`);
		}

		if (
			(targets.includes(ProgramSelect.Tactics) && !excludes.includes(ProgramSelect.Tactics)) ||
			(targets.includes(ProgramSelect.Invoices) && !excludes.includes(ProgramSelect.Invoices))
		) {
			const tacticAlias = 't2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								${TacticQuery.getSelects(tacticAlias, subSelects?.tacticSelects ?? undefined, true)}
							)
						FROM
							"tactics" AS ${tacticAlias}
							${TacticQuery.getSubqueries(tacticAlias, subSelects?.tacticSelects ?? undefined, undefined, undefined, options?.tacticOptions)}
						WHERE
							${tacticAlias}."programId" = ${alias}.id
							AND ${tacticAlias}.deleted != true
					) AS "tacticsArr"
				) AS ${alias}_tactics
			`);
		}

		if (targets.includes(ProgramSelect.TacticsGroups) && !excludes.includes(ProgramSelect.TacticsGroups)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', tg.id,
								'name', tg.name,
								'status', tg.status
							)
						FROM
							"tacticsGroups" AS tg
						WHERE
							tg."programId" = ${alias}.id
							AND tg.deleted != true
					) AS "tacticsGroupsArr"
				) AS ${alias}_tacticsGroups
			`);
		}

		if (targets.includes(ProgramSelect.Brands) && !excludes.includes(ProgramSelect.Brands)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', b2.id,
								'name', b2.name
							)
						FROM
							"programBrands" AS pb2
						LEFT JOIN
							brands AS b2
							ON
								b2.id = pb2."brandsId"
						WHERE
							pb2."programsId" = ${alias}.id
					) AS "brandsArr"
				) AS ${alias}_brands
			`);
		}

		if (targets.includes(ProgramSelect.Notes) && !excludes.includes(ProgramSelect.Notes)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', n2.id,
								'layoutLocationCode', n2."layoutLocationCode",
								'body', n2.body,
								'author', JSONB_BUILD_OBJECT (
									'id', u2.id,
									'email', u2."email",
									'profile', u2."profile"
								),
								'created', n2.created
							)
						FROM
							"programNotes" AS pn2
						LEFT JOIN
							notes AS n2
							ON
								n2.id = pn2."notesId"
						LEFT JOIN
							users AS u2
							ON
								n2."authorId" = u2.id
						WHERE
							pn2."programsId" = ${alias}.id
						ORDER BY
							n2.created DESC
					) AS "notesArr"
				) AS ${alias}_notes
			`);
		}

		if (targets.includes(ProgramSelect.Author) && !excludes.includes(ProgramSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSONB_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if (targets.includes(ProgramSelect.Tags) && !excludes.includes(ProgramSelect.Tags)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', tags.id,
								'name', tags.name
							)
						FROM
							"programTags" AS ptags2
						LEFT JOIN
							tags
							ON
								tags.id = ptags2."tagsId"
						WHERE
							ptags2."programsId" = ${alias}.id
					) AS "tagsArr"
				) AS ${alias}_tags
			`);
		}

		if (targets.includes(ProgramSelect.Products) && !excludes.includes(ProgramSelect.Products)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', products.id,
								'name', products.name
							)
						FROM
							"programProducts" AS pproducts2
						LEFT JOIN
							products
							ON
								products.id = pproducts2."productsId"
						WHERE
							pproducts2."programsId" = ${alias}.id
					) AS "productsArr"
				) AS ${alias}_products
			`);
		}

		if (targets.includes(ProgramSelect.Categories) && !excludes.includes(ProgramSelect.Categories)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							"catData".category
						FROM
							"programCategories" AS pc2,
							LATERAL (
								SELECT
									JSONB_BUILD_OBJECT (
										'id', c2.id,
										'name', c2.name,
										'brands', b2."brandsArr"
									) AS category
								FROM
									categories AS c2,
									LATERAL (
										SELECT ARRAY (
											SELECT
												JSONB_BUILD_OBJECT (
													'id', brands.id,
													'name', brands.name
												)
											FROM
												"categoryBrands" AS pcb2
											LEFT JOIN
												brands
												ON
													brands.id = pcb2."brandsId"
											WHERE
												pcb2."categoriesId" = c2.id
										) AS "brandsArr"
									) AS b2
								WHERE
									pc2."categoriesId" = c2.id
							) AS "catData"
						WHERE
							pc2."programsId" = ${alias}.id
					) AS "categoriesArr"
				) AS ${alias}_categories
			`);
		}

		if (targets.includes(ProgramSelect.Owners) && !excludes.includes(ProgramSelect.Owners)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								'id', users.id,
								'email', users.email,
								'profile', users.profile
							)
						FROM
							"programOwners" AS po2
						LEFT JOIN
							users
							ON
							users.id = po2."usersId"
						WHERE
							po2."programsId" = ${alias}.id
					) AS "ownersArr"
				) AS ${alias}_owners
			`);
		}

		if (targets.includes(ProgramSelect.Warnings) && !excludes.includes(ProgramSelect.Warnings)) {
			const warningAlias: string = 'w';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								${WarningQuery.getSelects(warningAlias, true)}
							)
						FROM
							"warnings" AS ${warningAlias}
						WHERE
							${warningAlias}."programId" = ${alias}.id
							AND ${warningAlias}."tacticId" IS NULL
							AND ${warningAlias}."invoiceId" IS NULL
							AND ${warningAlias}.dismissed = false
					) AS "warningsArr"
				) AS ${alias}_warnings
			`);
		}

		if (targets.includes(ProgramSelect.Files) && !excludes.includes(ProgramSelect.Files)) {
			const fileAlias: string = 'f';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								${FileQuery.getSelects(fileAlias, null, true)}
							)
						FROM
							"files" AS ${fileAlias}
						WHERE
							${fileAlias}."programId" = ${alias}.id AND ${fileAlias}."isVersion" IS NOT TRUE
					) AS "filesArr"
				) AS ${alias}_files
			`);
		}

		if (targets.includes(ProgramSelect.ExternalIds) && !excludes.includes(ProgramSelect.ExternalIds)) {
			const externalIdAlias: string = 'eid';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								${ExternalIdQuery.getSelects('eid', undefined, true)}
							)
						FROM
							"externalIds" AS ${externalIdAlias}
						${ExternalIdQuery.getSubqueries('eid')}
						WHERE
							${externalIdAlias}."programId" = ${alias}.id
					) AS "externalIdsArr"
				) AS ${alias}_externalids
			`);
		}

		if (targets.includes(ProgramSelect.ProgramStrategicBriefs) && !excludes.includes(ProgramSelect.ProgramStrategicBriefs)) {
			subQueries.push(`
			LATERAL (
				SELECT ARRAY (
					SELECT
						JSONB_BUILD_OBJECT(
							'id', psb."id",
							'briefId', psb."briefId",
							'data', psb."data",
							'versionNumber', psb."versionNumber",
							'versionDate', psb."versionDate",
							'created', psb."created",
							'deliverable', JSONB_BUILD_OBJECT(
								'id', d."id",
								'name', d."name",
								'slug', d."slug",
								'enabled', d."enabled",
								'label', d."label"
							)
						)
					FROM (
						SELECT
							psb_inner.*,
							ROW_NUMBER() OVER (
								PARTITION BY COALESCE(psb_inner."briefId", '00000000-0000-0000-0000-000000000000')
								ORDER BY psb_inner."versionDate" DESC, psb_inner."id" DESC
							) AS rn
						FROM
							"programStrategicBriefs" AS psb_inner
						WHERE
							psb_inner."program_id" = ${alias}.id
					) AS psb
					LEFT JOIN "deliverables" AS d ON psb."deliverable_id" = d."id"
					WHERE
						psb.rn = 1
					ORDER BY
						psb."versionDate" DESC, psb."id"
				) AS "programStrategicBriefsArr"
			) AS ${alias}_programstrategicbriefs
		`);
		}

		if (targets.includes(ProgramSelect.CustomFieldValues) && !excludes.includes(ProgramSelect.CustomFieldValues)) {
			const customFieldValueAlias = 'cfv';
			subQueries.push(`
			  LATERAL (
				SELECT ARRAY (
				  SELECT
					JSONB_BUILD_OBJECT (
					  'id', ${customFieldValueAlias}.id,
					  'customFieldId', ${customFieldValueAlias}."customFieldId",
					  'value', ${customFieldValueAlias}.value,
					  'customField', JSONB_BUILD_OBJECT (
						'id', cf.id,
						'name', cf.name,
						'type', cf.type,
						'options', CASE
						  WHEN cf.type IN ('Single Select', 'Multi Select')
						  THEN COALESCE(
							(SELECT JSONB_AGG(
							  JSONB_BUILD_OBJECT (
								'id', cfo.id,
								'name', cfo.name
							  )
							)
							FROM "customFieldOptions" AS cfo
							WHERE cfo."customFieldId" = cf.id),
							'[]'::jsonb
						  )
						  ELSE NULL
						END
					  )
					)
				  FROM
					"customFieldValues" AS ${customFieldValueAlias}
				  LEFT JOIN
					"customFields" AS cf
					ON
					  cf.id = ${customFieldValueAlias}."customFieldId"
				  WHERE
					${customFieldValueAlias}."programId" = ${alias}.id
					AND ${customFieldValueAlias}."tacticId" IS NULL
				) AS "customFieldValuesArr"
			  ) AS ${alias}_customfieldvalues
			`);
		}

		if (!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}
