import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, startWith, take, takeUntil } from 'rxjs/operators';
import { Validation } from '../../../../../../../api/src/_core/utils';
import { DebouncedValueChange, FormPropertyUpdateObject, OptionGroup } from '../../../../state/global/global.model';
import { GlobalService } from '../../../../state/global/global.service';
import { DateRangePreset, getDateRangeFromPreset } from '../../../../_core/utils/date.utils';
import { percentMask } from '../../../../_core/utils/input-mask.utils';
import { resolveDotNotationPath } from '../../../../_core/utils/object.utils';
import { replaceMergeTags } from '../../../../_core/utils/string.utils';
import { GlobalQuery } from '../../../global/global.query';
import { LocationService } from '../../location/location.service';
import { ProductService } from '../../product/product.service';
import { TagService } from '../../tag/tag.service';
import { UserService } from '../../user/user.service';
import { VendorService } from '../../vendor/vendor.service';
import { Filter, FilterOption, FilterParameters } from '../filter.model';
import { getFormButtonCurrentValueText } from '../filter.utils';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
	selector: 'app-filter-input',
	templateUrl: './filter-input.component.html',
	styleUrls: ['./filter-input.component.scss'],
})
export class FilterInputComponent implements OnInit, OnDestroy {
	@Input() filter: Filter;
	@Input() fileId?: string;

	@Input() formGroup: FormGroup;
	@Output() update: EventEmitter<string> = new EventEmitter();
	@Output() updateFullValue: EventEmitter<FormPropertyUpdateObject> = new EventEmitter();
	@Output() applyFilters: EventEmitter<FilterParameters> = new EventEmitter();

	@Output() cdkOverlayOpened: EventEmitter<void> = new EventEmitter();
	@Output() cdkOverlayClosed: EventEmitter<void> = new EventEmitter();

	public filteredOptions: any[];
	public suggestedItems: any[];
	public debouncedValueChange: Subject<DebouncedValueChange> = new Subject();

	public percentMask = percentMask;
	public entitySettings = this.globalQuery.getEntitySettings();

	// Mat Select with Groups properties
	public filteredOptionsSearchCtrl: FormControl = new FormControl();
	public filteredOptionsSubject: ReplaySubject<OptionGroup<any>[]> = new ReplaySubject<OptionGroup<any>[]>(1);
	public filteredOptions2Subject: ReplaySubject<FilterOption[]> = new ReplaySubject<FilterOption[]>(1);

	isMultiselectPanelOpen: boolean;
	isMultiselectPanelLoading: boolean;
	timeouts: any[] = [];
	panelMultiSelectClosedProgrammatically = false;

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly tagService: TagService,
		private readonly productService: ProductService,
		private readonly userService: UserService,
		private readonly vendorService: VendorService,
		private readonly locationService: LocationService,
		private readonly globalQuery: GlobalQuery,
		private readonly globalService: GlobalService,
		private readonly cdr: ChangeDetectorRef
	) {
		// Debounce changes before they hit the form
		this.debouncedValueChange
			.pipe(
				debounce((change) => timer(change.debounceTime)),
				distinctUntilChanged(),
				takeUntil(this._unsubscribe$)
			)
			.subscribe((change) =>
				this.formGroup.patchValue({
					[change.key]: change.value,
				})
			);
	}

	ngOnInit(): void {
		// console.log('Filter Input', this.filter, this.formGroup);

		// If we have this setting, we need to keep updating our options based on other conditions
		if (this.filter.extra?.optionsFromSettings || this.filter.options?.length) {
			this.getFilteredOptions();
		}

		if (!this.formGroup) {
			console.error('No form group provided to filter input component');
		}

		// Check if the filter is mult-select but the form value is not an array.
		if (
			this.filter.type === 'multi-select' &&
			this.formGroup.value[this.filter.slug] &&
			!Array.isArray(this.formGroup.value[this.filter.slug])
		) {
			console.error('Filter is multi-select but form value is not an array', this.filter, this.formGroup.value[this.filter.slug]);
		}

		if (this.filter.type === 'single-select-with-groups') {
			// listen for search field value changes
			this.filteredOptionsSearchCtrl.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe((obj) => {
				this.filterOptionGroup();
			});

			this.filterOptionGroup();
		}

		if (this.filter.type === 'multi-select-with-search') {
			// listen for search field value changes
			this.filteredOptionsSearchCtrl.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe((obj) => {
				this.filterOptions();
			});

			this.filterOptions();
		}
	}

	emitUpdate(): void {
		// Use a timeout to make sure the form value has been updated before we emit the update event.
		this.timeouts.push(setTimeout(() => this.update.emit(this.filter.slug), 0));
	}

	emitOpenedChange(event: boolean): void {
		if (event) {
			this.cdkOverlayOpened.emit();
		} else {
			this.cdkOverlayClosed.emit();
		}

		// If dropdown is closed, emit the update event
		if (!event) {
			this.timeouts.push(setTimeout(() => this.update.emit(this.filter.slug), 0));
		}
	}

	// Call this when you want to debounce a form change
	changeWithDebounce(key: string, value: any, debounceTime = 500): void {
		this.debouncedValueChange.next({
			key,
			value,
			debounceTime,
		});
	}

	getPercentValue(value: any): number {
		return Number(value) * 100;
	}

	getButtonSelectText(): string {
		return getFormButtonCurrentValueText(this.filter, this.formGroup, this.entitySettings);
	}

	percentChange(key: string, value: any): void {
		const float = parseFloat(value) / 100;
		console.log('Percent Change', key, value, parseFloat(value));

		this.formGroup.patchValue({
			[key]: float,
		});
	}

	patchValue(key: string, value: any): void {
		if (value === this.formGroup.value[key]) return;

		if (!this.filter.extra.buttonSelect.applyButton) {
			this.update.emit(value);
		}

		this.formGroup.patchValue({
			[key]: value,
		});
	}

	/**
	 * Listens to any changes to the filter form and reloads its options from the settings endpoint
	 */
	getFilteredOptions(): void {
		this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._unsubscribe$)).subscribe(() => {
			const setting = this.globalQuery.getSetting(this.filter.extra?.optionsFromSettings?.path);

			const selectedTacticCategories = this.formGroup.value?.tacticCategories || [];
			const isTacticTypesFilter = this.filter.slug === 'tacticTypes';

			this.filteredOptions = (setting || this.filter.options)?.filter((option) => {
				let match = true;

				// Set up data object for merge tags
				const data = {
					form: this.formGroup.value,
					option: option,
				};

				// Test our condition if we have one
				const condition = this.filter.extra?.optionsFromSettings?.condition;
				if (condition) {
					const path = resolveDotNotationPath(condition.path, option);
					const value = replaceMergeTags(condition.value, data);
					match = value === path;
				}

				// Test for option conditions
				if (option.visibilityCondition) {
					return Validation.validateConditionOnObject(this.globalQuery.getValue().settings, option.visibilityCondition);
				}

				// Test for parent conditions
				if (isTacticTypesFilter) {
					const tacticCategory = option.tacticCategory;
					if (selectedTacticCategories.length) {
						match = selectedTacticCategories.map((category) => category.id).includes(tacticCategory.id);
					}
				}

				return match;
			});
		});
	}

	/**
	 * Used for the mat-select with groups to get proper results
	 */
	public filterOptionGroup(): void {
		// get the search keyword
		let search = this.filteredOptionsSearchCtrl.value;
		const options = this.filteredOptions;

		// console.log('filterOptionGroup', search, options);

		if (!search) {
			this.filteredOptionsSubject.next(options);
			return;
		} else {
			search = search.toLowerCase();
		}

		// filter the banks
		this.filteredOptionsSubject.next(
			options
				.filter((group) => {
					// Should we show the whole category based on the name?
					let showGroup = group.name.toLowerCase().indexOf(search) > -1;
					if (!showGroup) {
						// If not, should we show the results?
						const items = group.items.filter((item) => item.name.toLowerCase().indexOf(search) > -1);
						showGroup = items.length > 0;
					}
					return showGroup;
				})
				.map((group) => ({
					...group,
					items: group.items.filter((item) => item.name.toLowerCase().indexOf(search) > -1),
				}))
		);
	}

	public filterOptions(): void {
		// get the search keyword
		let search = this.filteredOptionsSearchCtrl.value;
		const options = this.filteredOptions;

		if (!search) {
			this.filteredOptions2Subject.next(options);
			return;
		} else {
			search = search.toLowerCase();
		}

		// filter the banks
		this.filteredOptions2Subject.next(
			options.filter((option) => {
				return option.name.toLowerCase().indexOf(search) > -1;
			})
		);
	}

	getSuggestedItems(text: string): void {
		switch (this.filter.extra?.suggestEntity) {
			case 'tag-program':
				this.tagService
					.suggest(text, 'program')
					.pipe(take(1), takeUntil(this._unsubscribe$))
					.subscribe(
						(tags) => (this.suggestedItems = tags),
						(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
					);
				break;

			case 'tag-tactic':
				this.tagService
					.suggest(text, 'tactic')
					.pipe(take(1), takeUntil(this._unsubscribe$))
					.subscribe(
						(tags) => (this.suggestedItems = tags),
						(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
					);
				break;

			case 'product':
				this.productService
					.suggest(text)
					.pipe(take(1), takeUntil(this._unsubscribe$))
					.subscribe(
						(products) => (this.suggestedItems = products),
						(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
					);
				break;

			case 'user':
				this.userService
					.suggest(text)
					.pipe(take(1), takeUntil(this._unsubscribe$))
					.subscribe(
						(users) => {
							this.suggestedItems = users;
						},
						(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
					);
				break;

			case 'reviewers':
				this.userService
					.suggestReviewers(text, this.fileId)
					.pipe(take(1), takeUntil(this._unsubscribe$))
					.subscribe(
						(users) => (this.suggestedItems = users),
						(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
					);
				break;

			case 'vendor':
				this.vendorService
					.suggest(text)
					.pipe(take(1), takeUntil(this._unsubscribe$))
					.subscribe(
						(vendors) => (this.suggestedItems = vendors),
						(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
					);
				break;

			case 'location':
				this.locationService
					.suggest(text)
					.pipe(take(1), takeUntil(this._unsubscribe$))
					.subscribe(
						(locations) => (this.suggestedItems = locations),
						(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
					);
				break;
		}
	}

	setDateRangeFromPreset(preset: DateRangePreset, startField: string, endField: string): void {
		const dateRange = getDateRangeFromPreset(
			preset,
			new Date(),
			this.formGroup.value?.budgetPeriods ?? [this.formGroup.value.budgetPeriod]
		);

		if (dateRange) {
			this.formGroup.patchValue(dateRange);
		}
	}

	compareWithId(a: any, b: any): boolean {
		return a?.id === b?.id;
	}

	fullValueChanged(event: any, fieldName: string): void {
		this.updateFullValue.emit({
			fieldName: fieldName,
			data: event,
		});
	}

	onMultiSelectPanelClosed(select, filter): void {
		if (!this.panelMultiSelectClosedProgrammatically) {
			// User clicked outside; execute cancel action
			const existingFilterValue = this.formGroup.get(this.filter.slug)?.value;
			this.formGroup.get(this.filter.slug).patchValue([...(existingFilterValue ?? [])]);
			this.timeouts.push(
				setTimeout(() => {
					select.close();
				}, 0)
			);
		}
		this.panelMultiSelectClosedProgrammatically = false; // Reset the flag
	}

	onCloseMultiSelect(select: MatSelect): void {
		const existingFilterValue = this.formGroup.get(this.filter.slug)?.value;
		this.formGroup.get(this.filter.slug).patchValue([...(existingFilterValue ?? [])]);
		// Delay closing to ensure flag is set before 'closed' event fires
		this.timeouts.push(
			setTimeout(() => {
				select.close();
			}, 0)
		);
	}

	onApplyMultiSelect(select: MatSelect, filter: Filter): void {
		this.panelMultiSelectClosedProgrammatically = true;
		this.formGroup.get(filter.slug).patchValue(select.value);
		// Delay closing to ensure flag is set before 'closed' event fires
		this.timeouts.push(
			setTimeout(() => {
				select.close();
			}, 0)
		);
	}

	// Add fake loader to prevent glitch of ngx-mat-select-search panel
	onMultiselectOpenedChange(isOpen: boolean): void {
		this.isMultiselectPanelOpen = isOpen;
		this.isMultiselectPanelLoading = isOpen;
		if (isOpen) {
			this.timeouts.push(
				setTimeout(() => {
					this.isMultiselectPanelLoading = false;
				}, 100)
			);
		} else {
			this.isMultiselectPanelOpen = false;
		}
	}

	onDateChange(event: MatDatepickerInputEvent<any>, filter: Filter): void {
		const value = event.value;
		const key = filter.slug;
		this.formGroup.get(key).patchValue(new Date(value));
		this.applyFilters.emit(this.formGroup.value);
	}

	ngOnDestroy(): void {
		this.timeouts.forEach((timeout) => clearTimeout(timeout));
		this._unsubscribe$.next(undefined);
		this._unsubscribe$.complete();
	}
}
