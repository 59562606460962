<div [formGroup]="formGroup">
	<mat-form-field class="input" fxFlex="100%" *ngIf="customField.type === CustomFieldType.Text">
		<mat-label>{{ customField.name }}</mat-label>
		<input matInput [formControlName]="customField.id" />
	</mat-form-field>

	<mat-form-field class="input" fxFlex="100%" *ngIf="customField.type === CustomFieldType.SingleSelect">
		<mat-label>{{ customField.name }}</mat-label>
		<mat-select [formControlName]="customField.id">
			<mat-option *ngFor="let option of customField.options" [value]="option.id">{{ option.name }}</mat-option>
		</mat-select>
	</mat-form-field>

	<app-autocomplete
		*ngIf="customField.type === CustomFieldType.MultiSelect"
		[formGroup]="formGroup"
		[controlName]="customField.id"
		[label]="customField.name"
		[allItems]="customField.options"
		class="input"
		fxFlex="100%"
	></app-autocomplete>
</div>
