import { ObjectUtils } from './utils.object';

const isDebug = typeof process !== 'undefined' && process.env && process.env.DEBUG ? process.env.DEBUG : false;

export interface Address {
	street: string;
	city: string;
	state: string;
	zip: string;
}

export class StringUtils {
	// Note, this will NOT work for anything but the simplest cases.
	// ex: McTavish, will not come out properly.
	public static titleCase(input: any): any {
		if (!(typeof input === 'string')) {
			return input;
		}
		return input
			.split(' ')
			.map((i) => {
				return i[0].toUpperCase() + (i.length > 1 ? i.slice(1).toLowerCase() : '');
			})
			.join(' ');
	}

	public static slugify(input: any) {
		if (!input) {
			input = '';
		}
		if (typeof input !== 'string') {
			if (input.toString) {
				input = input.toString();
			} else {
				input = `${input}`;
			}
		}
		return input.trim().toLowerCase().replace(/\s/g, '_');
	}

	public static underscoreToDash(input: string) {
		return input.replace(/_/g, '-');
	}

	public static dashToUnderscore(input: string) {
		return input.replace(/-/g, '_');
	}

	/**
	 * Looks through a string and replaces any merge tags using a ${} syntax with variables.
	 * Example: A ${option.color} fox jumps over a ${option.size} dog.
	 * @param str
	 * @param obj
	 */
	public static replaceMergeTags(str: string, obj: any) {
		// Replace ${} with evaluated variable paths
		return str.replace(/\${(.*?)}/g, (match) => {
			return ObjectUtils.resolveDotNotationPath(match.replace('${', '').replace('}', ''), obj);
		});
	}

	public static toAddress(address: string): Address {
		if (!address || !address.length) {
			return {
				street: undefined,
				city: undefined,
				state: undefined,
				zip: undefined,
			};
		}
		// Remove double spaces.
		address = address.replace(/\s\s/g, ' ');
		// Remove comma space.
		address = address.replace(/\s,/g, ',');
		// Remove junk whitespace.
		address = address.trim();
		let street, city, state, zip;

		// Parse what we can.
		try {
			const stateZip = address
				.substring(address.lastIndexOf(',') + 1, address.length)
				.trim()
				.split(' ');
			const streetCity = address.substring(0, address.lastIndexOf(',')).trim();

			street = streetCity.substring(0, streetCity.lastIndexOf(',')).trim();
			city = streetCity.substring(streetCity.lastIndexOf(',') + 1, streetCity.length).trim();
			state = stateZip[0];
			zip = stateZip[1];
		} catch (err) {
			if (isDebug) {
				console.log(err);
			}
		}

		return {
			street,
			city,
			state,
			zip,
		};
	}
}
