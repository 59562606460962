import { AppSection } from '../global/global.model';
import { ActivateOverviewParams, MediaPlanOverviewParams, PlanOverviewParams } from './overview.model';

export function getSectionConfig(
	section: AppSection
): typeof ActivateOverviewParams | typeof PlanOverviewParams | typeof MediaPlanOverviewParams {
	switch (section) {
		case 'activation':
			return ActivateOverviewParams;
		case 'planning':
			return PlanOverviewParams;
		case 'media-plan':
			return MediaPlanOverviewParams;
	}
}
