import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { PublicTactic, Tactic } from '../tactic/tactic.entity';
import { PublicUser, User } from '../user/user.entity';
import { PublicVendor, Vendor } from '../vendor/vendor.entity';

import { BrandAllocation, PublicBrandAllocation } from '../brand-allocation/brand-allocation.entity';
import { PublicWarning, Warning } from '../warning/warning.entity';
import { ExternalId, PublicExternalId } from '../external-id/external-id.entity';
import { CacheResultItem } from '../budget-cache/models/budget-cache.models';
import { SortHelper } from '../_core/decorators/sort-helper.decorator';
import { VendorFragmentGenerator } from '../vendor/utils/vendor-fragment-generator';

export enum InvoiceStatus {
	Sent = 'Sent',
	ReceivedUnpaid = 'Received - Unpaid',
	ReceivedPaid = 'Received - Paid',
}

export type PublicInvoice = Pick<Invoice, 'id' | 'tacticId' | 'number' | 'amount' | 'status' | 'created' | 'dueDate'> & {
	tactic?: PublicTactic;
	brandAllocations?: PublicBrandAllocation[];
	vendor?: PublicVendor;
	author: PublicUser;
	budgetCacheBrand?: CacheResultItem;
	warnings?: PublicWarning[];
	externalIds?: PublicExternalId[];
};
export type PublicInvoiceStatus = Pick<Status, 'name'> & { id?: string };

export class Status {
	constructor(value?: Partial<Invoice>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	public status: InvoiceStatus;
	public name: string;
	public id?: string;

	public toPublic(): PublicInvoiceStatus {
		return {
			id: this.id ? this.id : null,
			name: this.status ? this.status.toString() : null,
		};
	}
}

@Entity('invoices')
@Index(['tacticId'])
export class Invoice {
	constructor(value?: Partial<Invoice>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	@Sortable
	number: string;

	@Column('decimal', { nullable: false })
	@Sortable
	amount: number;

	@OneToMany(() => BrandAllocation, (brandAllocation) => brandAllocation.invoice, {
		nullable: true,
		eager: true,
		cascade: true,
		onDelete: 'CASCADE',
	})
	brandAllocations?: BrandAllocation[];

	@Column({
		type: 'enum',
		enum: InvoiceStatus,
		default: InvoiceStatus.Sent,
	})
	@Sortable
	status: InvoiceStatus;

	@Column({ type: 'timestamptz', nullable: true })
	@Sortable
	dueDate?: string;

	@OneToMany(() => ExternalId, (externalId) => externalId.invoice, {
		eager: true,
		cascade: true,
	})
	externalIds?: ExternalId[] | Partial<ExternalId>[];

	@Column('text', { nullable: true })
	vendorId?: string;
	@ManyToOne(() => Vendor, {
		eager: true,
		nullable: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'vendorId' })
	@Sortable
	@SortHelper(new VendorFragmentGenerator({ invoiceMatch: true }))
	vendor?: Vendor | Partial<Vendor>;

	@Column('text', { nullable: false })
	tacticId: string;
	@ManyToOne((type) => Tactic, {
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'tacticId' })
	tactic: Tactic;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne((type) => User, {
		eager: true,
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'authorId' })
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	@Sortable
	created: string;

	warnings?: Warning[];
	budgetCacheBrand?: CacheResultItem;

	public toPublic(): PublicInvoice {
		const pub: Partial<PublicInvoice> = {
			id: this.id,
			tacticId: this.tacticId,
			number: this.number,
			status: this.status,
			dueDate: this.dueDate,
			created: this.created,
			vendor: this.vendor ? new Vendor(this.vendor).toPublic() : null,
		};

		if (typeof this.amount !== 'undefined' && this.amount !== null) {
			pub.amount = Number(this.amount);
		}

		if (this.author) {
			pub.author = new User(this.author).toPublic();
		}

		if (this.tactic) {
			pub.tactic = new Tactic(this.tactic).toPublic();
		}

		if (this.brandAllocations?.length) {
			pub.brandAllocations = (this.brandAllocations as BrandAllocation[])?.map((a) => new BrandAllocation(a).toPublic());
		}

		if (this.budgetCacheBrand) {
			pub.budgetCacheBrand = this.budgetCacheBrand;
		}

		if (this.externalIds) {
			pub.externalIds = (this.externalIds as Partial<ExternalId>[]).map((i) => {
				return new ExternalId(i).toPublic();
			});
		}

		if (this.vendor) {
			pub.vendor = new Vendor(this.vendor).toPublic();
		}

		if (this.warnings?.length) {
			pub.warnings = (this.warnings as Partial<Warning>[]).map((w) => {
				return new Warning(w).toPublic();
			});
		}

		return pub as PublicInvoice;
	}
}
