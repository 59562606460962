import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-step-breadcrumbs',
	templateUrl: './step-breadcrumbs.component.html',
	styleUrls: ['./step-breadcrumbs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepBreadcrumbsComponent implements OnInit {
	@Input() steps: string[];
	@Input() currentStep: string;
	@Input() currentStepIndex: number;
	@Input() clickable = true;
	@Output() stepClicked = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	stepClickedHandler(index: number, step: any): void {
		this.stepClicked.emit({ index, step });
	}
}
