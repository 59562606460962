import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export class CacheTarget {
	method: string;
	routeFragments: string[];
}

@Injectable({ providedIn: 'root' })
export class CacheService {
	private maxCacheSize = 50 * 1024 * 1024; // 50 MB
	private currentCacheSize = 0;

	// Cache will now store both the HttpEvent and the timestamp
	private cache: Record<string, Record<string, { event: HttpEvent<any> | Blob; timestamp: number }>> = {};
	private readonly cacheTargets: CacheTarget[] = [
		{ method: 'POST', routeFragments: [environment.apiUrl, '/find/'] },
		{ method: 'GET', routeFragments: [environment.apiUrl, '/file/review'] },
		{ method: 'GET', routeFragments: [environment.apiUrl, '/file'] },
		{ method: 'GET', routeFragments: [environment.apiUrl, '/file/'] },
	];
	private readonly poisonousTargets: CacheTarget[] = [
		{ method: 'POST', routeFragments: [environment.apiUrl, '/plan'] },
		{ method: 'PUT', routeFragments: [environment.apiUrl, '/plan'] },
		{ method: 'DELETE', routeFragments: [environment.apiUrl, '/plan'] },
		{ method: 'POST', routeFragments: [environment.apiUrl, '/program'] },
		{ method: 'PUT', routeFragments: [environment.apiUrl, '/program'] },
		{ method: 'DELETE', routeFragments: [environment.apiUrl, '/program'] },
		{ method: 'POST', routeFragments: [environment.apiUrl, '/tactic'] },
		{ method: 'PUT', routeFragments: [environment.apiUrl, '/tactic'] },
		{ method: 'DELETE', routeFragments: [environment.apiUrl, '/tactic'] },
	];
	private readonly CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

	public get(method: string, url: string, hash: string) {
		const key = `${method}-${encodeURI(url)}`;
		const cachedItem = this.cache?.[key]?.[hash];

		if (cachedItem) {
			const currentTime = Date.now();
			// Check if the cached item is older than the cache duration
			if (currentTime - cachedItem.timestamp < this.CACHE_DURATION) {
				return cachedItem.event;
			} else {
				// If the cached item is too old, remove it
				delete this.cache[key][hash];
			}
		}
		return;
	}

	public put(method: string, url: string, hash: string, value: HttpEvent<any> | Blob): void {
		const key = `${method}-${encodeURI(url)}`;
		if (!this.cache[key]) {
			this.cache[key] = {};
		}
		this.cache[key][hash] = { event: value, timestamp: Date.now() };
		// Save current cache size
		const newSize = value instanceof Blob ? value.size : new Blob([JSON.stringify(value)]).size;
		this.currentCacheSize += newSize;
		console.info('CACHE: Current cache size', (this.currentCacheSize / 1024 / 1024).toFixed(1), 'MB');
		if (this.currentCacheSize > this.maxCacheSize) {
			this.clear();
		}
	}

	private _removeKeysWithMatch(obj: any, search: string): any {
		const result = {};

		for (const key in obj) {
			if (!key.includes(search)) {
				result[key] = obj[key];
			}
		}

		return result;
	}

	public clear(endpoint?: string): void {
		if (endpoint) {
			this.cache = this._removeKeysWithMatch(this.cache, endpoint);
		} else {
			this.cache = {};
		}
	}

	public canCache(method: string, url: string): boolean {
		for (const group of this.cacheTargets) {
			let match = true;
			if (group.method === method) {
				for (const segment of group.routeFragments) {
					if (!url.includes(segment)) {
						match = false;
					}
				}
				if (match) {
					return true;
				}
			}
		}
		return false;
	}

	public isPoisonous(method: string, url: string): boolean {
		// First check if the URL should be cached
		if (this.canCache(method, url)) {
			return false;
		}

		for (const group of this.poisonousTargets) {
			let match = true;
			if (group.method === method) {
				for (const segment of group.routeFragments) {
					if (!url.includes(segment)) {
						match = false;
					}
				}
				if (match) {
					return true;
				}
			}
		}
		return false;
	}

	public getCacheSize(): number {
		let totalSize = 0;

		for (const method in this.cache) {
			if (Object.prototype.hasOwnProperty.call(this.cache, method)) {
				for (const url in this.cache[method]) {
					if (Object.prototype.hasOwnProperty.call(this.cache[method], url)) {
						const item = this.cache[method][url];
						if (item.event instanceof Blob) {
							// Add the size of the Blob
							totalSize += item.event.size;
						} else {
							// Estimate the size of non-Blob data
							const serializedEvent = JSON.stringify(item.event);
							totalSize += new Blob([serializedEvent]).size;
						}
					}
				}
			}
		}

		return totalSize / (1024 * 1024); // Convert to MB
	}
}
