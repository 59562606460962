import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SessionState, Profile } from './session.model';

export const SESSION_KEY = 'session';
export const ORG_SETTINGS = 'orgSettings';

export function createInitialSessionState(): SessionState {
	return {
		token: null,
		clientId: null,
		issuer: null,
		profile: {
			email: undefined,
			role: undefined,
			photo: undefined,
			social: {},
		},
		...getSession(),
		isLoggedIn: false,
		ui: {
			emailInput: undefined,
		},
		initialUrl: undefined,
	};
}

export function getSession(): any {
	const session = localStorage.getItem(SESSION_KEY);
	return session ? JSON.parse(session) : {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
	constructor() {
		super(createInitialSessionState());
	}

	updateLoginDetails(session: Partial<SessionState>): void {
		localStorage.setItem(SESSION_KEY, JSON.stringify(session));
		this.update((state) => ({
			...state,
			...session,
		}));
	}

	login(session: Partial<SessionState>): void {
		localStorage.setItem(SESSION_KEY, JSON.stringify(session));
		this.update((state) => ({
			...state,
			...session,
			isLoggedIn: true,
		}));
	}

	logout(): void {
		localStorage.removeItem(SESSION_KEY);
		localStorage.removeItem(ORG_SETTINGS);
		this.update(createInitialSessionState());
	}

	/**
	 * Merge any profile properties in with existing profile
	 */
	updateProfile(profile: Partial<Profile>): void {
		this.update((state) => ({
			...state,
			profile: {
				...state.profile,
				...profile,
			},
		}));
	}
}
