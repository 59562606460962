import { Entity } from '../entities.model';
import { Tactic } from '../tactic/tactic.model';
import { TacticGroupStatus } from '../../../../../../api/src/tactic-group/tactic-group.entity';

export interface TacticsGroup extends Entity {
	id: string;
	name: string;
	status: TacticGroupStatus;
	tactics: Tactic[];
}

/**
 * A tactic group section represents a UI section / tab in the tactic page
 * Tactic group sections are statically build below.
 */
export interface TacticGroupSection {
	label: string;
	slug: string;
}

/**
 * The sections for a tactic group page.
 */
export const TacticGroupSections: TacticGroupSection[] = [
	{
		label: 'Scenario Details',
		slug: 'details',
	},
	{
		label: 'Tactics',
		slug: 'tactics',
	},
];

/**
 * Get the default color of a status
 */
// TODO: We need to move these colors into a similar setup as other settings endpoint entities.
export function getTacticGroupStatusColor(status: TacticGroupStatus): string {
	switch (status) {
		case TacticGroupStatus.Draft:
			return '#0075A1';

		case TacticGroupStatus.Approved:
			return '#00D185';
	}
}

export function createTacticsGroup(params: Partial<TacticsGroup>) {
	return {} as TacticsGroup;
}
