import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Objectives } from '../../../state/entities/program/program.model';
import { GlobalQuery } from '../../../state/global/global.query';

/**
 * Geometrics Dialog
 * This dialog houses the Geometrics application in a modal.
 */
@Component({
	selector: 'app-geometrics-dialog',
	templateUrl: './geometrics-dialog.component.html',
	styleUrls: ['./geometrics-dialog.component.scss'],
})
export class GeometricsDialogComponent implements OnInit {
	public organization = 'testsite';
	public startingStep = 'macro-objectives';

	constructor(
		public dialogRef: MatDialogRef<GeometricsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public objectives: Objectives,
		public globalQuery: GlobalQuery
	) {}

	ngOnInit(): void {
		const organization = this.globalQuery.getOrganizationSetting('geometricsOrganization');
		if (organization) {
			this.organization = organization;
		}

		const startingStep = this.globalQuery.getOrganizationSetting('geometricsStartingStep');
		if (startingStep) {
			this.startingStep = startingStep;
		}
	}

	onComplete(objectives: Objectives): void {
		this.dialogRef.close(objectives);
	}
}
