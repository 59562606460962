import * as Highcharts from 'highcharts';

export const defaultStyleOptions = {
	colors: [
		'#00AEEF',
		'#00D185',
		'#FF0159',
		'#FFB000',
		'#8a06ee',
		'#ee7106',
		'#07002D',
		'#777777',
		'#00e0ff',
		'#00ed82',
		'#ffcb00',
		'#ffa400',
		'#c14fff',
		'#6f71a4',
		'#ffa142',
		'#2e2b56',
	],
	title: {
		text: '',
	},
	chart: {
		plotBackgroundColor: null,
		plotBorderWidth: null,
		plotShadow: false,

		style: {
			fontFamily: 'YRThree',
		},
	},
	tooltip: {
		borderWidth: 0,
		borderColor: null,
		useHTML: true,
		shadow: null,
		borderRadius: 4,
		style: {
			color: '#ffffff',
		},
		backgroundColor: '#07002D',
		pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
	},
	exporting: {
		buttons: {
			contextButton: {
				enabled: false,
			},
		},
		// buttons: {
		// 	contextButton: {
		// 		symbol: 'download',
		// 		symbolFill: '#CCCCCC',
		// 		symbolStroke: '#CCCCCC',
		// 		y: -10,
		// 		menuItems: [
		// 			'viewFullscreen',
		// 			'printChart',
		// 			'separator',
		// 			'downloadPNG',
		// 			'downloadJPEG',
		// 			'downloadPDF',
		// 			'downloadSVG',
		// 			'separator',
		// 			'downloadCSV',
		// 			'downloadXLS',
		// 		],
		// 	}
		// }
	},
	accessibility: {
		point: {
			valueSuffix: '%',
		},
	},
	credits: {
		enabled: false,
	},
};

export function getDefaultColorByIndex(index: number): string {
	return defaultStyleOptions.colors[index % defaultStyleOptions.colors.length];
}

export const defaultPieChartOptions = {
	...defaultStyleOptions,
	chart: {
		...defaultStyleOptions,
		type: 'pie',
	},
	plotOptions: {
		pie: {
			borderColor: null,
			allowPointSelect: true,
			cursor: 'pointer',
			dataLabels: {
				enabled: false,
				style: {
					textOutline: '#000',
				},
			},
			showInLegend: true,
		},
	},
	legend: {
		layout: 'vertical',
		align: 'left',
		verticalAlign: 'middle',
		itemMarginBottom: 2,
		itemStyle: {
			fontWeight: 'bold',
		},
		navigation: {
			activeColor: '#3E576F',
			animation: true,
			arrowSize: 12,
			inactiveColor: '#CCC',
			style: {
				color: '#333',
				fontSize: '12px',
				marginBottom: '2px',
			},
		},
	},
};

export const defaultSunBurstChartOptions = {
	...defaultStyleOptions,
	chart: {
		...defaultStyleOptions,
		type: 'sunburst',
	},
	plotOptions: {
		sunburst: {
			borderColor: '#ffffff',
			borderWidth: 3,
			allowPointSelect: true,
			cursor: 'pointer',
			dataLabels: {
				enabled: false,
			},
			colors: defaultStyleOptions.colors,
			showInLegend: true,
		},
	},
	legend: {
		layout: 'vertical',
		align: 'left',
		verticalAlign: 'middle',
		itemMarginBottom: 2,
		itemStyle: {
			fontWeight: 'bold',
		},
		navigation: {
			activeColor: '#3E576F',
			animation: true,
			arrowSize: 12,
			inactiveColor: '#CCC',
			style: {
				color: '#333',
				fontSize: '12px',
				marginBottom: '2px',
			},
		},
	},
};

export const defaultStackedBarChartOptions: Highcharts.Options = {
	...defaultStyleOptions,
	chart: {
		...defaultStyleOptions.chart,
		type: 'bar',
	},
	yAxis: {
		min: 0,
		title: {
			text: '',
		},
	},
	legend: {
		reversed: true,
	},
	plotOptions: {
		bar: {
			borderColor: null,
			maxPointWidth: 36,
		},
		series: {
			stacking: 'normal',
		},
	},
};

export function addDownloadSymbol(highcharts: any) {
	highcharts.SVGRenderer.prototype.symbols.download = (x, y, w, h) => {
		return [
			// Arrow stem
			'M',
			x + w * 0.5,
			y,
			'L',
			x + w * 0.5,
			y + h * 0.7,
			// Arrow head
			'M',
			x + w * 0.3,
			y + h * 0.5,
			'L',
			x + w * 0.5,
			y + h * 0.7,
			'L',
			x + w * 0.7,
			y + h * 0.5,
			// Box
			'M',
			x,
			y + h * 0.9,
			'L',
			x,
			y + h,
			'L',
			x + w,
			y + h,
			'L',
			x + w,
			y + h * 0.9,
		];
	};
}
