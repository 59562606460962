import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

/**
 * Fill Height Directive
 * This directive will make a component's height extend down to the bottom of the page based on where it is located
 */
@Directive({
	selector: '[fillHeight]',
})
export class FillHeightDirective implements OnInit, OnDestroy {
	@Input() paddingBottom = 0;
	@Input() disableFillHeight: boolean;
	@Input() elementClass: string;
	@Input() fitParentClass?: string;
	@Input() fillHeightOnResize?: boolean = true;
	@Input() applyFillHeightMultipleTimes: boolean;

	tryCount = 0;

	public domElement;

	timeouts: any[] = [];

	constructor(private renderer: Renderer2, private el: ElementRef) {}

	ngOnInit(): void {
		let element = this.el?.nativeElement;

		if (this.elementClass) {
			element = document.getElementsByClassName(this.elementClass)[0];
		}

		if (element) {
			this.domElement = element;
			// Use ResizeObserver to fill height on window resize
			if (this.fillHeightOnResize && typeof ResizeObserver !== 'undefined') {
				const resizeObserver = new ResizeObserver((entries) => {
					this.fillHeight();
				});
				// Observe the element whose size is critical (e.g., the parent container)
				resizeObserver.observe(this.el?.nativeElement?.parentElement);
			}

			this.timeouts.push(setTimeout(() => this.fillHeight(), 100));
			// Fill height multiple times, needed for some calendar layers to work properly
			if (this.applyFillHeightMultipleTimes) {
				// TODO - Use this for calendar layers
				this.timeouts.push(setTimeout(() => this.fillHeight(), 1000));
				this.timeouts.push(setTimeout(() => this.fillHeight(), 2000));
				this.timeouts.push(setTimeout(() => this.fillHeight(), 3000));
				this.timeouts.push(setTimeout(() => this.fillHeight(), 5000));
			}
		}
	}

	ngOnDestroy(): void {
		this.timeouts.forEach((timeout) => clearTimeout(timeout));
	}

	fillHeight(): void {
		if (this.disableFillHeight) {
			return;
		}

		const top = this.domElement.getBoundingClientRect().top;
		const calculatedHeight = window.innerHeight - top - this.paddingBottom;

		if (calculatedHeight < 100 && this.tryCount < 10) {
			this.timeouts.push(
				setTimeout(() => {
					this.tryCount++;
					this.fillHeight();
				}, 1000)
			);
			return;
		}

		let parentHeight;
		if (this.fitParentClass) {
			const parent = document.querySelector(`.${this.fitParentClass}`);
			parentHeight = parent?.clientHeight;
		}

		const height = parentHeight ? parentHeight - this.paddingBottom : calculatedHeight;
		this.renderer.setStyle(this.el.nativeElement, 'overflow', `auto`);
		this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
		this.renderer.setStyle(this.el.nativeElement, 'max-height', `${height}px`);
		this.renderer.setStyle(this.el.nativeElement, 'min-height', `${height}px`);
	}
}
