import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FileService } from '../../../state/entities/file/file.service';
import { pipe, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { GlobalService } from '../../../state/global/global.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FileUploadRequest } from '../../../state/entities/file/file.model';
import { environment } from '../../../../environments/environment';
import { PublicFile } from '../../../../../../api/src/file/file.entity';
import { sanitizeFileName } from '../../../_core/utils/file.utils';
import { isFileNameValid } from '../../../_core/utils/file.utils';

@Component({
	selector: 'app-image-upload',
	templateUrl: 'image-upload.component.html',
	styleUrls: ['image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit, OnDestroy {
	@Input() title: string = 'Image Upload';
	@Input() previewTitle: string = 'Image Preview:';
	@Input() publicResource: boolean = false;

	@Input() hidePreview: boolean = false;
	@Input() hideRemove: boolean = false;

	@Input() imageUrl: string = '';

	@Output() onFileUpload: EventEmitter<string> = new EventEmitter();
	@Output() onFileRemove: EventEmitter<void> = new EventEmitter();

	@ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

	public loading = false;
	public urlDisplay = '';

	public validTypes = ['image/jpeg', 'image/png', 'image/x-icon', 'image/svg+xml', 'image/webp'];
	public maxSize = 500 * 1024; // 500kb

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(private fileService: FileService, private globalService: GlobalService) {}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	ngOnInit(): void {
		if (this.imageUrl) {
			if (this.imageUrl.startsWith('http')) {
				this.urlDisplay = this.imageUrl;
			} else {
				this.urlDisplay = environment.apiUrl + this.imageUrl;
			}
		}
	}

	removeImage(): void {
		this.loading = true;

		// Public resource, just remove it from settings
		if (this.imageUrl.startsWith('http')) {
			this.onFileRemove.emit();
			this.loading = false;
			return;
		}

		this.fileService
			.removeSettingsFile(this.imageUrl)
			.pipe(take(1), takeUntil(this._unsubscribe$))
			.subscribe(
				(response) => {
					if (response.status === 'succeeded') {
						this.onFileRemove.emit();
						this.loading = false;
					}
				},
				(err: HttpErrorResponse) => {
					this.globalService.triggerErrorMessage(err);
					this.loading = false;
				}
			);
	}

	uploadImage(file: File): void {
		this.loading = true;

		const fileReq: FileUploadRequest = {
			name: file.name,
			data: file,
		};

		this.fileService
			.uploadSettingsFile(fileReq, this.publicResource)
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(
				(response) => {
					if (response instanceof HttpResponse) {
						const file = response.body as PublicFile;
						const url = this.publicResource ? file.s3Path : file.path;
						this.urlDisplay = this.publicResource ? url : environment.apiUrl + url;

						this.onFileUpload.emit(url);
						this.loading = false;
					}
				},
				(err: HttpErrorResponse) => {
					this.globalService.triggerErrorMessage(err);
					this.loading = false;
				}
			);
	}

	onFileInputClick(): void {
		this.fileInput.nativeElement.click();
	}

	onFileDrop(files: FileList): void {
		if (files.length) {
			const file = files[0];

			if (!this.validTypes.includes(file.type)) {
				this.globalService.triggerErrorMessage(null, 'Unsupported file type!');
				this.fileInput.nativeElement.value = '';
				return;
			}

			if (file.size > this.maxSize) {
				this.globalService.triggerErrorMessage(null, `File size exceeds limit of ${this.maxSize / 1024}kb!`);
				this.fileInput.nativeElement.value = '';
				return;
			}

			const sanitizedFileName = sanitizeFileName(file.name);

			if (!isFileNameValid(sanitizedFileName)) {
				this.globalService.triggerErrorMessage(
					null,
					'Invalid file name! File names can only contain letters, numbers, underscores, hyphens, and periods.'
				);
				this.fileInput.nativeElement.value = '';
				return;
			}

			this.uploadImage(file);
		}
	}
}
