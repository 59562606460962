import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { SessionStore, getSession } from './session.store';
import { Profile, SessionState } from './session.model';
import { map } from 'rxjs/operators';
import { RoleOption, UserRole } from '../global/global.model';

@Injectable({
	providedIn: 'root',
})
export class SessionQuery extends Query<SessionState> {
	isLoggedIn$ = this.select('isLoggedIn');
	profile$ = this.select((state) => state.profile);
	isAdmin$ = this.select('profile').pipe(map((profile) => profile.role === 'admin' || profile.role === 'super-admin'));
	isSuperAdmin$ = this.select('profile').pipe(map((profile) => profile.role === 'super-admin'));

	constructor(protected store: SessionStore) {
		super(store);
	}

	isLoggedIn(): boolean {
		return toBoolean(this.getValue().isLoggedIn);
	}

	isAtLeastAdmin(profile?: Profile): boolean {
		if (!profile) {
			profile = this.getValue().profile;
		}

		return profile.role === UserRole.Admin || profile.role === UserRole.SuperAdmin;
	}

	isAgencyUser(profile?: Profile): boolean {
		if (!profile) {
			profile = this.getValue().profile;
		}

		console.log('isAgencyUser', profile.agencyUser, profile);
		return profile.agencyUser;
	}

	getEmailInput(): string {
		return this.getValue().ui.emailInput;
	}

	getProfile(): Profile {
		return this.getValue().profile;
	}

	getRole(): UserRole {
		return this.getValue().profile?.role;
	}

	getRoleOptions(): RoleOption[] {
		return [
			{ value: UserRole.SuperAdmin.toString(), viewValue: 'Super Admin' },
			{ value: UserRole.Admin.toString(), viewValue: 'Admin' },
			{ value: UserRole.Analyst.toString(), viewValue: 'Analyst' },
			{ value: UserRole.User.toString(), viewValue: 'User' },
			{ value: UserRole.Guest.toString(), viewValue: 'Guest' },
		];
	}

	getToken(): string {
		// Actually, we want to always get the token from localstorage
		// in case its changed in a new tab
		const session = getSession();
		return session?.token;
	}
}
