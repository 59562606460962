<div class="box-container">
	<!-- If there is no success message yet, show the request form -->
	<ng-container *ngIf="!successMessage">
		<h2>We’re excited to see you here!</h2>
		<p>
			Currently, VML employees only have a license to view this product. Use the request form below to request access and we’ll review
			it shortly.
		</p>

		<!-- Request Access Form -->
		<form>
			<mat-form-field appearance="fill">
				<mat-label>Your Email</mat-label>
				<input matInput type="email" [ngModel]="email" name="userEmail" disabled readonly />
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Message</mat-label>
				<textarea #messageInput matInput rows="5" [(ngModel)]="message" name="message" required [maxlength]="400"></textarea>
				<small class=""> Characters Remaining: {{ messageInput?.value?.length ?? 0 }} /{{ 400 }}</small>
			</mat-form-field>

			<button
				mat-flat-button
				class="button-small"
				style="width: 150px"
				color="primary"
				type="submit"
				[disabled]="!message || !!successMessage || !!errorResponse"
				(click)="onRequestAccess()"
			>
				Submit Request
			</button>
		</form>
	</ng-container>
	<p class="text-center mt-2">
		<ng-container *ngIf="loading$ | async">
			<p class="spinner full-width display-flex">
				<mat-spinner></mat-spinner>
			</p>
		</ng-container>

		<!-- Once loading is done, show any error or success message -->
		<ng-container *ngIf="(loading$ | async) === false">
			<ng-container *ngIf="errorResponse">
				{{ errorResponse?.message }}
			</ng-container>
			<ng-container *ngIf="successMessage">
				{{ successMessage }}
			</ng-container>
		</ng-container>
	</p>
</div>
