import { Column, Entity, JoinColumn, JoinTable, ManyToMany, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { BudgetPeriod, PublicBudgetPeriod } from '../budget-period/budget-period.entity';
import { Plan, PublicPlan } from '../plan/plan.entity';
import { Program, PublicProgram } from '../program/program.entity';
import { PublicTactic, Tactic } from '../tactic/tactic.entity';
import { PublicUser, User } from '../user/user.entity';
import { Comment, PublicComment } from '../comment/comment.entity';
import { Sortable } from '../_core/decorators/sortable.decorator';
import { SortHelper } from '../_core/decorators/sort-helper.decorator';
import { ProgramFragmentGenerator } from '../program/utils/program-fragment-generator';
import { FileReviewTypeEnum } from './enums/file-review-type.enum';

export enum FileCategory {
	AudienceDefinition = 'audienceDefinition',
	Brief = 'brief',
	Creative = 'creative',
	MeasurementRecap = 'measurementRecap',
	Other = 'other',
	StrategyDocument = 'strategyDocument',
	Contract = 'contract',
	Invoice = 'invoice',
	Estimate = 'estimate',
	StoreList = 'storeList',
	Panel = 'panel',
	Asset = 'asset',
	SKUList = 'skuList',
	ConsiderationSet = 'considerationSet',
	ActivationMediaPlan = 'activationMediaPlan',
	OnePager = 'onePager',
	ProgramOverview = 'programOverview',
	SellingDeck = 'sellingDeck',
	RFP = 'rfp',
	AdminSettings = 'admin-settings',
}

export enum FileReviewStatus {
	ChangesRequested = 'changesRequested',
	Approved = 'approved',
	Pending = 'pending',
	NA = 'n/a',
	ReviewOnly = 'reviewOnly',
}

export enum ReviewerRole {
	Reviewer = 'reviewer',
	Approver = 'approver',
}

export type PublicFileVersion = Pick<
	FileVersion,
	| 'id'
	| 'name'
	| 'mimeType'
	| 'description'
	| 'reviewType'
	| 'created'
	| 'updated'
	| 'path'
	| 's3Path'
	| 'approved'
	| 'feedbackDueDate'
	| 'thumbnail'
	| 'converted'
> & {
	author?: PublicUser;
	approvers?: PublicUser[];
	reviewers?: PublicUser[];
	comments?: PublicComment[];
};

export class FileVersion {
	id: string;
	name: string;
	description?: string;
	feedbackDueDate?: string;
	path: string;
	s3Path: string;
	mimeType?: string;
	approved?: boolean;
	created?: string;
	updated?: string;
	reviewers?: User[];
	comments?: Comment[];
	approvers?: User[];
	reviewType?: FileReviewTypeEnum;
	thumbnail?: string;
	converted?: {
		mimeType: string;
		path: string;
		s3Path: string;
	};

	constructor(value?: Partial<FileVersion>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	public toPublic(): PublicFileVersion {
		const pub: Partial<PublicFileVersion> = {
			id: this.id,
			name: this.name,
			description: this.description,
			reviewType: this.reviewType,
			mimeType: this.mimeType,
			path: this.path?.replace(':id', this.id),
			s3Path: this.s3Path,
			approved: this.approved,
			created: this.created,
			updated: this.updated,
			feedbackDueDate: this.feedbackDueDate,
			thumbnail: this.thumbnail ? `${this.thumbnail}?thumbnail=true`.replace(':id', this.id) : undefined,
			converted: this.converted
				? {
						...this.converted,
						path: this.converted.path.replace(':id', this.id),
				  }
				: null,
		};
		if (this.approvers?.length) {
			pub.approvers = this.approvers?.map((a) => new User(a).toPublic());
		}

		if (this.reviewers?.length) {
			pub.reviewers = this.reviewers?.map((r) => new User(r).toPublic());
		}

		if (this.comments?.length) {
			pub.comments = this.comments?.map((c) => new Comment(c).toPublic());
		}
		return pub as PublicFileVersion;
	}
}

export type PublicFile = Pick<
	File,
	| 'id'
	| 'name'
	| 'mimeType'
	| 'category'
	| 'created'
	| 'updated'
	| 'path'
	| 's3Path'
	| 'budgetPeriodId'
	| 'planId'
	| 'programId'
	| 'tacticId'
	| 'commentId'
	| 'requiresApproval'
	| 'approved'
	| 'feedbackDueDate'
	| 'thumbnail'
	| 'reviewMessage'
	| 'converted'
> & {
	budgetPeriod?: PublicBudgetPeriod;
	plan?: PublicPlan;
	program?: PublicProgram;
	tactic?: PublicTactic;
	author?: PublicUser;
	approvers?: PublicUser[];
	reviewers?: PublicUser[];
	comments?: PublicComment[];
	versions?: PublicFileVersion[];
};

@Entity('files')
export class File {
	constructor(value?: Partial<File>) {
		if (value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text')
	name: string;

	@Column({
		type: 'enum',
		enum: FileCategory,
		default: FileCategory.Other,
	})
	@Sortable
	category?: FileCategory;

	@Column('text')
	mimeType: string;

	@Column('text')
	path: string;

	@Column('text')
	s3Path: string;

	@Column('boolean', { nullable: true })
	requiresApproval?: boolean;

	@Column('boolean', { nullable: true })
	@Sortable
	approved?: boolean;

	@Column('text', { nullable: true })
	budgetPeriodId?: string;

	@ManyToOne(() => BudgetPeriod, {
		nullable: true,
		orphanedRowAction: 'delete',
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'budgetPeriodId' })
	budgetPeriod?: BudgetPeriod | Partial<BudgetPeriod>;

	@Column('text', { nullable: true })
	planId?: string;

	@ManyToOne(() => Plan, {
		nullable: true,
		orphanedRowAction: 'delete',
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'planId' })
	plan?: Plan | Partial<Plan>;

	@Column('text', { nullable: true })
	programId?: string;

	@ManyToOne(() => Program, {
		nullable: true,
		orphanedRowAction: 'delete',
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'programId' })
	@Sortable
	@SortHelper(new ProgramFragmentGenerator({ fileMatch: true }))
	program?: Program | Partial<Program>;

	@Column('text', { nullable: true })
	commentId?: string;

	@ManyToOne(() => Comment, {
		nullable: true,
		orphanedRowAction: 'delete',
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'commentId' })
	comment?: Comment | Partial<Comment>;

	@Column('text', { nullable: true })
	tacticId?: string;

	@ManyToOne(() => Tactic, {
		nullable: true,
		orphanedRowAction: 'delete',
		onDelete: 'CASCADE',
	})
	@JoinColumn({ name: 'tacticId' })
	tactic?: Tactic | Partial<Tactic>;

	@ManyToMany(() => User, {
		nullable: true,
	})
	@JoinTable({ name: 'fileApprovers' })
	approvers?: User[] | Partial<User>[];

	@ManyToMany(() => User, {
		nullable: true,
	})
	@JoinTable({ name: 'fileReviewers' })
	reviewers?: User[] | Partial<User>[];

	@OneToMany(() => Comment, (comment) => comment.file, {
		nullable: true,
		cascade: true,
		onDelete: 'CASCADE',
	})
	comments?: Comment[];

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	@Sortable
	created: string;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	@Sortable
	updated: string;

	@Column('jsonb', { nullable: true })
	versions: FileVersion[];

	@Column('boolean', { nullable: true, default: false })
	isVersion: boolean;

	@Column('timestamptz', { nullable: true })
	@Sortable
	feedbackDueDate: string;

	@Column('text', { nullable: true })
	thumbnail?: string;

	@Column('text', { nullable: true })
	thumbnailS3Path?: string;

	@Column('text', { nullable: true })
	reviewMessage?: string;

	@Column('jsonb', { nullable: true })
	converted?: {
		path: string;
		mimeType: string;
		s3Path: string;
	};

	public toPublic(): PublicFile {
		const pub: Partial<PublicFile> = {
			id: this.id,
			name: this.name,
			category: this.category,
			mimeType: this.mimeType,
			path: this.path?.replace(':id', this.id),
			s3Path: this.s3Path,
			budgetPeriodId: this.budgetPeriodId,
			planId: this.planId,
			programId: this.programId,
			tacticId: this.tacticId,
			commentId: this.commentId,
			requiresApproval: this.requiresApproval,
			approved: this.approved,
			created: this.created,
			updated: this.updated,
			feedbackDueDate: this.feedbackDueDate,
			thumbnail: this.thumbnail ? `${this.thumbnail}?thumbnail=true`.replace(':id', this.id) : undefined,
			reviewMessage: this.reviewMessage,
			converted: this.converted
				? {
						...this.converted,
						path: this.converted.path.replace(':id', this.id),
				  }
				: null,
		};

		if (this.budgetPeriod) {
			pub.budgetPeriod = new BudgetPeriod(this.budgetPeriod).toPublic();
		}

		if (this.plan) {
			pub.plan = new Plan(this.plan).toPublic();
		}

		if (this.program) {
			pub.program = new Program(this.program).toPublic(undefined, ['objectives', 'readOnly']);
		}

		if (this.plan) {
			// TODO: Probably optimize this?
			pub.plan = new Plan(this.plan).toPublic();
		}

		if (this.tactic) {
			pub.tactic = new Tactic(this.tactic).toPublic(undefined, ['readOnly']);
		}

		if (this.approvers) {
			pub.approvers = this.approvers?.map((a) => new User(a).toPublic());
		}

		if (this.reviewers) {
			pub.reviewers = this.reviewers?.map((r) => new User(r).toPublic());
		}

		if (this.comments) {
			pub.comments = this.comments?.map((c) => new Comment(c).toPublic());
		}

		if (this.versions?.length) {
			pub.versions = this.versions.map((v) => new FileVersion(v).toPublic());
		}

		return pub as PublicFile;
	}
}
