import { DocumentationQABasic } from '../../../models/qa/doc-qa-basic.model';
import { DocumentationQASectionTypeEnum } from '../../../models/qa/doc-qa-types.model';

export const documentationNestleBriefSummaryDashboardQA: DocumentationQABasic = {
	title: 'Nestle Brief Summary Dashboard',
	description:
		'The Nestle Brief Summary Dashboard provides a consolidated summary of all Nestle Brief Types submitted within a specified period. It displays all relevant details in a single location, allowing users to view an aggregate of all submissions and track when they were last modified.',
	sections: [
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Overview',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'This dashboard aggregates data from all submitted Nestle Brief Types, providing insights into submission counts by brand and retailer, as well as detailed submission information, including creation and modification dates.',
		},
		{
			type: DocumentationQASectionTypeEnum.MAIN_TITLE,
			text: 'Dashboard Widgets',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Total Briefs Submitted',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'Displays the total number of Nestle Brief Types submitted.',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Briefs by Brand',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'A pie chart and table summarizing the number of Nestle Brief Types submitted per brand.',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Briefs by Retailer',
		},
		{
			type: DocumentationQASectionTypeEnum.DEFAULT_PARAGRAPH,
			text: 'A pie chart and table summarizing the number of Nestle Brief Types submitted per retailer.',
		},
		{
			type: DocumentationQASectionTypeEnum.SUBTITLE,
			text: 'Brief Details',
		},
		{
			type: DocumentationQASectionTypeEnum.BULLETED_LIST,
			text: 'A table displaying all submitted Nestle Briefs, including:',
			bulletedList: ['Date Created', 'Last Submitted Date', 'Program Name', 'All other submitted details'],
		},
	],
	directions: [
		{
			title: 'Create a New Budget Period',
			completed: false,
			items: [
				{
					title: 'Begin by setting up a new budget period in the system.',
					completed: false,
				},
			],
		},
		{
			title: 'Create 1 Brand Program',
			completed: false,
			items: [
				{
					title: 'Ensure the following details are filled out in the Details Tab:',
					completed: false,
					items: [
						{
							title: 'Brand',
							completed: false,
						},
						{
							title: 'Retailer',
							completed: false,
						},
						{
							title: 'Start and End Date',
							completed: false,
						},
					],
				},
				{
					title: 'Tactic Tab:',
					completed: false,
					items: [
						{
							title: 'Tactic Type',
							completed: false,
						},
					],
				},
				{
					title: 'Brief Tab:',
					completed: false,
					items: [
						{
							title: 'Complete a Nestle Brief Type. Fill out all fields within the brief and submit.',
							completed: false,
						},
					],
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the Nestle Brief Summary Dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist for 1 Brand Program',
			completed: false,
			items: [
				{
					title: 'Confirm the first 3 sections of the dashboard accurately reflect the 1 brief submitted.',
					completed: false,
				},
				{
					title: 'Check the Brief Details table to ensure it accurately reflects the Date Created and all other content submitted.',
					completed: false,
				},
			],
		},
		{
			title: 'Update the Brief',
			completed: false,
			items: [
				{
					title: 'Navigate back to the brief and make several updates throughout. Then click resubmit.',
					completed: false,
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the Nestle Brief Summary Dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist for Updated Brief',
			completed: false,
			items: [
				{
					title: 'Confirm the first 3 sections of the dashboard accurately reflect the 1 brief submitted.',
					completed: false,
				},
				{
					title: 'Check the Brief Details table to ensure it accurately reflects the Date Created, Last Submitted Date, and all other content submitted has updated.',
					completed: false,
				},
			],
		},
		{
			title: 'Create a Multi-Brand Program',
			completed: false,
			items: [
				{
					title: 'Ensure the following details are filled out in the Details Tab:',
					completed: false,
					items: [
						{
							title: 'Brands',
							completed: false,
						},
						{
							title: 'Retailer',
							completed: false,
						},
						{
							title: 'Start and End Date',
							completed: false,
						},
					],
				},
				{
					title: 'Tactic Tab:',
					completed: false,
					items: [
						{
							title: 'Tactic Type',
							completed: false,
						},
					],
				},
				{
					title: 'Brief Tab:',
					completed: false,
					items: [
						{
							title: 'Complete a Nestle Brief Type. Fill out all fields within the brief and submit.',
							completed: false,
						},
					],
				},
			],
		},
		{
			title: 'Refresh and Check the Dashboard',
			completed: false,
			items: [
				{
					title: 'Refresh the page, then navigate to the Nestle Brief Summary Dashboard and filter for the newly created budget period.',
					completed: false,
				},
			],
		},
		{
			title: 'QA Checklist for Multi-Brand Program',
			completed: false,
			items: [
				{
					title: 'Confirm the first 3 sections of the dashboard accurately reflect the 1 brief submitted. If the new brief submitted has 2 brands, the 1 submission should count as a whole brief in both brand sections.',
					completed: false,
				},
				{
					title: 'Check the Brief Details table to ensure it accurately reflects the Date Created, Last Submitted Date, and all other content submitted.',
					completed: false,
				},
				{
					title: 'Check the brand and retailer filters to ensure proper filtering of the dashboard.',
					completed: false,
				},
			],
		},
	],
};
