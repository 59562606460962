import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Objectives } from '../../../state/entities/program/program.model';
import { GraphFileExportEnvelope } from '../../../state/entities/graph/graph.model';
import html2canvas from 'html2canvas';

/**
 * Geometrics Objectives component
 * This component shows the objectives for a program that were selected via the Geometrics app
 */
@Component({
	selector: 'app-geometrics-objectives',
	templateUrl: './geometrics-objectives.component.html',
	styleUrls: ['./geometrics-objectives.component.scss'],
})
export class GeometricsObjectivesComponent implements OnInit {
	@ViewChild('exportContainer') exportContainer: ElementRef;
	@Input() objectives: Objectives;

	title: string;

	constructor() {
		this.title = 'Objectives';
	}

	ngOnInit(): void {}

	/**
	 * Used by parent containers to extract an image of this graph to export in a file.
	 */
	async getFilesForExport(type: string): Promise<GraphFileExportEnvelope[]> {
		switch (type) {
			// This case is when a parent wants an export for a certain type of batch export
			case 'for-pdf':
			case 'for-ppt':
				try {
					this.exportContainer.nativeElement.style.width = '1100px';
					const canvas = await html2canvas(this.exportContainer.nativeElement, {
						scale: 3,
						y: 0,
						x: 0,
						width: 1100,
						onclone: (doc) => {
							const ele = doc.querySelector('#geometrics-objectives') as HTMLElement;
							ele.style.position = 'fixed';
							ele.style.left = '0';
							ele.style.top = `0`;
							ele.style.width = '1100px';
						},
						scrollY: 0,
						scrollX: -window.scrollX,
						windowWidth: 1400,
						windowHeight: 766,
					});

					const result: GraphFileExportEnvelope[] = [
						{
							file: canvas.toDataURL('image/png'),
							type: 'image',
							width: 520,
						},
					];

					// Reset width back to 100%
					this.exportContainer.nativeElement.style.width = '100%';

					return result;
				} catch (error) {
					// Reset width even if there's an error
					this.exportContainer.nativeElement.style.width = '100%';
					throw error;
				}

			default:
				return [];
		}
	}
}
