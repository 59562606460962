import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-loader-to-checkmark',
	templateUrl: 'loader-to-checkmark.component.html',
	styleUrls: ['loader-to-checkmark.component.scss'],
	animations: [trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))])])],
})
export class LoaderToCheckMarkComponent implements OnInit, OnChanges {
	@Input() loading: boolean;
	@Input() size = 40;

	showCheckmark = false;

	ngOnInit(): void {
		/* setTimeout(() => {
		  this.loading = false;
		  this.ngOnChanges({ loading: { previousValue: true, currentValue: false, firstChange: false, isFirstChange: () => false } });
		}, 2000); */
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.loading) {
			if (!this.loading && changes.loading.previousValue === true) {
				this.showCheckmark = true;
				setTimeout(() => {
					this.showCheckmark = false;
				}, 1500);
			} else {
				this.showCheckmark = false;
			}
		}
	}
}
