/**
 * Reporting Group IDs
 */
export enum ReportingGroupId {
	BUDGET_GROUP = 'budget-group',
	FINANCE_GROUP = 'finance-group',
	PROGRAM_DETAILS_GROUP = 'program-details-group',
	FILE_REVIEWS_GROUP = 'file-reviews-group',
	ADMIN_GROUP = 'admin',
	RMN_GROUP = 'rmn',
}

/**
 * Reporting Group Names
 */
export enum ReportingGroupName {
	BUDGET = 'Budget',
	FINANCE = 'Finance',
	PROGRAM_DETAILS = 'Program Details',
	SUMMARIES = 'Summaries',
	ADMIN = 'Admin',
	RMN = 'RMN',
}

/**
 * Reporting Page IDs
 */
export enum ReportingPageId {
	// Budget
	PROGRAM_SPEND_ROLL_UPS = 'program-spend-roll-ups',
	BUDGET_DASHBOARD = 'budget-dashboard',
	TACTIC_COST_BY_FLIGHT = 'tactic-cost-by-flight',

	// Finance
	INVOICING_DASHBOARD = 'invoicing',

	// Program Details
	INITIATIVE_RECAP = 'intiative-snapshot',
	VENDOR_SNAPSHOT = 'vendor-snapshot',
	PROGRAM_COUNTS = 'program-counts',
	CREATIVE_PLAYBOOK = 'creative-playbook',
	PROGRAM_PERFORMANCE_ANALYSIS = 'program-results-roll-up',
	OFFER_PERFORMANCE_ANALYSIS = 'offer-performance-analysis',

	// Summaries / File Reviews
	FILE_REVIEWS = 'file-reviews-analytics',
	NESTLE_BRIEF_SUBMISSION_SUMMARY = 'nestle-brief-submission-summary',

	// Admin
	CLIENT_DROP_DOWNS = 'client-drop-downs',
	COMPLIANCE_TRACKER = 'compliance-tracker',
	UTILIZATION_DASHBOARD = 'kimberly-clark',
	POS_DATA = 'pos-data',

	// RMN
	RMN_VS_NON_RMN_SPEND = 'rmn-vs-non-rmn-spend',
}

/**
 * Reporting Page Names
 */
export enum ReportingPageName {
	// Budget
	PROGRAM_SPEND_ROLL_UPS = 'Program Spend Roll Ups',
	BUDGET_DASHBOARD = 'Budget Dashboard',
	TACTIC_COST_BY_FLIGHT = 'Tactic Cost by Flight',

	// Finance
	INVOICING_DASHBOARD = 'Invoicing Dashboard',

	// Program Details
	INITIATIVE_RECAP = 'Initiative Recap',
	VENDOR_SNAPSHOT = 'Vendor Snapshot',
	PROGRAM_COUNTS = 'Program Counts',
	PROGRAM_OVERVIEWS = 'Program Overviews',
	PROGRAM_PERFORMANCE_ANALYSIS = 'Program Performance Analysis',
	OFFER_PERFORMANCE_ANALYSIS = 'Offer Performance Analysis',

	// Summaries / File Reviews
	FILE_REVIEW = 'File Review',
	NESTLE_BRIEF_SUBMISSION_SUMMARY = 'Nestle Brief Submission Summary',

	// Admin
	CLIENT_DROP_DOWNS = 'Client Drop Downs',
	COMPLIANCE_TRACKER = 'Compliance Tracker',
	UTILIZATION_DASHBOARD = 'Utilization Dashboard',
	POS_DATA = 'POS Data',

	// RMN
	RMN_VS_NON_RMN_SPEND = 'RMN vs Non-RMN Spend',
}

/**
 * Reporting Pages
 */
export const reportingPagesData: {
	pageGroupId: ReportingGroupId;
	pageGroupName: ReportingGroupName;
	pages: Array<{ pageId: ReportingPageId; pageName: ReportingPageName }>;
}[] = [
	{
		pageGroupId: ReportingGroupId.BUDGET_GROUP,
		pageGroupName: ReportingGroupName.BUDGET,
		pages: [
			{ pageId: ReportingPageId.PROGRAM_SPEND_ROLL_UPS, pageName: ReportingPageName.PROGRAM_SPEND_ROLL_UPS },
			{ pageId: ReportingPageId.BUDGET_DASHBOARD, pageName: ReportingPageName.BUDGET_DASHBOARD },
			{ pageId: ReportingPageId.TACTIC_COST_BY_FLIGHT, pageName: ReportingPageName.TACTIC_COST_BY_FLIGHT },
		],
	},
	{
		pageGroupId: ReportingGroupId.FINANCE_GROUP,
		pageGroupName: ReportingGroupName.FINANCE,
		pages: [{ pageId: ReportingPageId.INVOICING_DASHBOARD, pageName: ReportingPageName.INVOICING_DASHBOARD }],
	},
	{
		pageGroupId: ReportingGroupId.PROGRAM_DETAILS_GROUP,
		pageGroupName: ReportingGroupName.PROGRAM_DETAILS,
		pages: [
			{ pageId: ReportingPageId.INITIATIVE_RECAP, pageName: ReportingPageName.INITIATIVE_RECAP },
			{ pageId: ReportingPageId.VENDOR_SNAPSHOT, pageName: ReportingPageName.VENDOR_SNAPSHOT },
			{ pageId: ReportingPageId.PROGRAM_COUNTS, pageName: ReportingPageName.PROGRAM_COUNTS },
			{ pageId: ReportingPageId.CREATIVE_PLAYBOOK, pageName: ReportingPageName.PROGRAM_OVERVIEWS },
			{ pageId: ReportingPageId.PROGRAM_PERFORMANCE_ANALYSIS, pageName: ReportingPageName.PROGRAM_PERFORMANCE_ANALYSIS },
			{ pageId: ReportingPageId.OFFER_PERFORMANCE_ANALYSIS, pageName: ReportingPageName.OFFER_PERFORMANCE_ANALYSIS },
		],
	},
	{
		pageGroupId: ReportingGroupId.FILE_REVIEWS_GROUP,
		pageGroupName: ReportingGroupName.SUMMARIES,
		pages: [
			{ pageId: ReportingPageId.FILE_REVIEWS, pageName: ReportingPageName.FILE_REVIEW },
			{ pageId: ReportingPageId.NESTLE_BRIEF_SUBMISSION_SUMMARY, pageName: ReportingPageName.NESTLE_BRIEF_SUBMISSION_SUMMARY },
		],
	},
	{
		pageGroupId: ReportingGroupId.ADMIN_GROUP,
		pageGroupName: ReportingGroupName.ADMIN,
		pages: [
			{ pageId: ReportingPageId.CLIENT_DROP_DOWNS, pageName: ReportingPageName.CLIENT_DROP_DOWNS },
			{ pageId: ReportingPageId.COMPLIANCE_TRACKER, pageName: ReportingPageName.COMPLIANCE_TRACKER },
			{ pageId: ReportingPageId.UTILIZATION_DASHBOARD, pageName: ReportingPageName.UTILIZATION_DASHBOARD },
			{ pageId: ReportingPageId.POS_DATA, pageName: ReportingPageName.POS_DATA },
		],
	},
	{
		pageGroupId: ReportingGroupId.RMN_GROUP,
		pageGroupName: ReportingGroupName.RMN,
		pages: [{ pageId: ReportingPageId.RMN_VS_NON_RMN_SPEND, pageName: ReportingPageName.RMN_VS_NON_RMN_SPEND }],
	},
];

/**
 * Reporting Pages Default State
 * Here we define the default state of the reporting pages.
 * This is used to determine which pages are enabled/disabled by default.
 */
export const reportingPagesDefaultState: {
	pageId: ReportingPageId;
	enabled: boolean;
}[] = reportingPagesData.flatMap((group) =>
	group.pages.map((page) => ({
		pageId: page.pageId,
		enabled: true,
	}))
);
