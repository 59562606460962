import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataCache } from '../../../_core/utils/cache.utils';
import { environment } from '../../../../environments/environment';
import { SygnlVendorInfo, Vendor } from './vendor.model';
import { VendorGroup } from '../../../../../../api/src/vendor-group/vendor-group.entity';
import { VendorStore } from './vendor.store';
import { ID } from '@datorama/akita';
import { PublicBudgetPeriod } from '../../../../../../api/src/budget-period/budget-period.entity';
import { GlobalStore } from '../../global/global.store';
import { Observable } from 'rxjs';

/**
 * Vendor Service
 * This service is responsible for vendor logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class VendorService {
	public cache: DataCache = new DataCache();

	constructor(private http: HttpClient, private vendorStore: VendorStore, private globalStore: GlobalStore) {}

	get(): Observable<Vendor[]> {
		return this.http.get<Vendor[]>(`${environment.apiUrl}/organization/${environment.organizationId}/vendor`);
	}
	/**
	 * Get a tactic based on an id + program id.
	 */
	getOne(id: Vendor['id']): Observable<Vendor> {
		return this.http.get<Vendor>(`${environment.apiUrl}/organization/${environment.organizationId}/vendor/${id}`);
	}

	/**
	 * Return a list of vendors based on a search query.
	 */
	suggest(text: string, isCacheLookupEnabled: boolean = true): Observable<Vendor[]> {
		if (isCacheLookupEnabled && this.cache.get(text)) {
			return of(this.cache.get(text));
		}

		return this.http
			.get<Vendor[]>(`${environment.apiUrl}/organization/${environment.organizationId}/vendor/suggest/?query=${text}`)
			.pipe(
				tap((response) => {
					this.cache.set(text, response);
				})
			);
	}

	public getSygnlVendorInfo(vendorId: Vendor['id']): Observable<SygnlVendorInfo> {
		return this.http.get<SygnlVendorInfo>(
			`${environment.apiUrl}/organization/${environment.organizationId}/integrations/sygnl/vendors/${vendorId}`
		);
	}

	public getVendorGroups(budgetPeriodId: PublicBudgetPeriod['id']): Observable<VendorGroup[]> {
		return this.http.get<VendorGroup[]>(
			`${environment.apiUrl}/organization/${environment.organizationId}/budget-period/${budgetPeriodId}/vendor-group`
		);
	}

	public createVendorGroup(budgetPeriodId: PublicBudgetPeriod['id'], vendor: Vendor, group: string): Observable<VendorGroup> {
		return this.http.post<VendorGroup>(
			`${environment.apiUrl}/organization/${environment.organizationId}/budget-period/${budgetPeriodId}/vendor-group`,
			{
				vendorId: vendor.id,
				group,
			}
		);
	}

	public removeVendorGroup(budgetPeriodId: PublicBudgetPeriod['id'], vendorGroupId: VendorGroup['id']): Observable<VendorGroup> {
		return this.http.delete<VendorGroup>(
			`${environment.apiUrl}/organization/${environment.organizationId}/budget-period/${budgetPeriodId}/vendor-group/${vendorGroupId}`
		);
	}

	set(vendors: Vendor[]): void {
		this.vendorStore.set(vendors?.map((vendor) => vendor));
	}

	public update(id: Vendor[ID], vendor: Partial<Vendor>): Observable<Partial<Vendor>> {
		this.vendorStore.setLoading(true);
		if (vendor) {
			return this.http
				.put<Vendor>(`${environment.apiUrl}/organization/${environment.organizationId}/vendor/${id}`, this.prepareForApi(vendor))
				.pipe(
					tap((newValue) => {
						this.vendorStore.update(vendor.id, newValue);
						this.vendorStore.setLoading(false);
					}),
					catchError((err) => {
						this.vendorStore.update(vendor.id, this.vendorStore.getValue().entities[vendor.id]);
						return throwError(err);
					})
				);
		} else {
			console.log('Updating Akita', vendor);
			this.vendorStore.update(vendor.id, vendor);
			this.vendorStore.setLoading(false);
			return of(vendor);
		}
	}

	remove(id: Vendor['id']): Observable<Vendor> {
		this.vendorStore.setLoading(true);

		return this.http.delete<Vendor>(`${environment.apiUrl}/organization/${environment.organizationId}/vendor/${id}`).pipe(
			tap((newValue) => {
				this.vendorStore.remove(id);
				this.vendorStore.setLoading(false);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	prepareForApi(vendor: Partial<Vendor>): object {
		const obj = {};

		console.log('Preparing for API', vendor);

		if (vendor) {
			Object.keys(vendor).forEach((key) => {
				switch (key) {
					case 'id':
					case 'name':
					case 'logoUrl':
						break;
					case 'vendorTypeId':
						obj[key] = vendor[key];
						break;

					case 'remoteId':
						if (typeof vendor?.remoteId === 'string') {
							obj[key] = vendor.remoteId;
						}
						obj['id'] = JSON.stringify(vendor.id as any);
						obj['name'] = JSON.stringify(vendor.name as any);
						break;

					default:
						obj[key] = JSON.parse(vendor[key]);
						break;
				}
			});
		}

		return obj;
	}

	getAssociatedTactics() {
		return this.http.get<any>(`${environment.apiUrl}/organization/${environment.organizationId}/vendor/count`);
	}

	mergeVendors(vendorsToMerge: Vendor[], toVendor: Vendor) {
		const vendorsIdsToMerge = vendorsToMerge.map((x) => {
			return { id: x.id };
		});
		const toVendorId = { id: toVendor.id };

		this.globalStore.setLoading(true);
		return this.http
			.post<any>(`${environment.apiUrl}/organization/${environment.organizationId}/vendor/merge`, {
				vendorsToMerge: vendorsIdsToMerge,
				toVendor: toVendorId,
			})
			.pipe(tap(() => this.globalStore.setLoading(false)));
	}
}
