import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { ProgramPhase } from './program-phase.model';
import { ProgramPhaseStore } from './program-phase.store';

@Injectable({ providedIn: 'root' })
export class ProgramPhaseService {
	constructor(private programPhaseStore: ProgramPhaseStore, private http: HttpClient) {}

	get(): Observable<ProgramPhase[]> {
		return this.http.get<ProgramPhase[]>('https://api.com').pipe(
			tap((entities) => {
				this.programPhaseStore.set(entities);
			})
		);
	}

	set(programPhase: ProgramPhase[]): void {
		this.programPhaseStore.set(programPhase);
	}

	add(programPhase: ProgramPhase): void {
		this.programPhaseStore.add(programPhase);
	}

	update(id: ProgramPhase['id'], programPhase: Partial<ProgramPhase>): Observable<Partial<ProgramPhase>> {
		this.programPhaseStore.setLoading(true);

		if (programPhase) {
			return this.http
				.put<ProgramPhase>(
					`${environment.apiUrl}/organization/${environment.organizationId}/program-phase/${id}`,
					this.prepareForApi(programPhase)
				)
				.pipe(
					tap((newValue) => {
						this.programPhaseStore.update(programPhase.id, newValue);
						this.programPhaseStore.setLoading(false);
					}),
					catchError((err) => {
						this.programPhaseStore.update(programPhase.id, this.programPhaseStore.getValue().entities[programPhase.id]);
						console.log(err);
						return throwError(err);
					})
				);
		} else {
			console.log('Updating Akita', programPhase);
			this.programPhaseStore.update(programPhase.id, programPhase);
			this.programPhaseStore.setLoading(false);
			return of(programPhase);
		}
	}

	remove(id: ID): void {
		this.programPhaseStore.remove(id);
	}

	setLoading(state: boolean): void {
		this.programPhaseStore.setLoading(state);
	}

	prepareForApi(programPhase: Partial<ProgramPhase>) {
		const obj = {};

		console.log('Preparing for API', programPhase);

		if (programPhase) {
			Object.keys(programPhase).forEach((key) => {
				switch (key) {
					case 'id':
					case 'created':
					case 'author':
						break;

					default:
						obj[key] = programPhase[key];
						break;
				}
			});
		}

		return obj;
	}
}
