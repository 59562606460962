import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { CustomField } from './custom-field.entity';

@Entity('customFieldOptions')
export class CustomFieldOption {
	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column({ nullable: false, type: 'varchar' })
	name: string;

	@Column({ nullable: false, type: 'int' })
	order: number;

	@ManyToOne(() => CustomField, (customField) => customField.options, {
		onDelete: 'CASCADE',
		nullable: false,
	})
	@JoinColumn({ name: 'customFieldId' })
	customField: CustomField;
}
