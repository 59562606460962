import { setObjectValueAtPath } from '../../../_core/utils/object.utils';
import { Entity } from '../entities.model';
import { MergedModification, MergedModificationCollection, StagedModification } from './staged-modification.model';

/**
 * Merge all modifications with a given entity object.
 * @param entity
 * @param modifications
 * @returns
 */
export function mergeModificationsWithEntity(entity: Entity, modifications: StagedModification[]) {
	let mergedEntity = { ...entity };

	modifications.forEach((modification) => {
		mergedEntity = setObjectValueAtPath(mergedEntity, modification.path, modification.value);
	});

	return mergedEntity;
}

/**
 * Takes all modifications provided and groups them by the entity id.
 * @param modifications
 * @returns Object with keys for each entity id and values for the modifications for that entity.
 */
export function getMergedModificationsByEntityId(modifications: StagedModification[]): MergedModificationCollection {
	const mergedModifications: MergedModificationCollection = {};

	// Merge modifications by entity ID, build a request
	modifications.forEach((modification) => {
		if (!mergedModifications[modification.entity.id]) {
			mergedModifications[modification.entity.id] = {
				id: modification.entity.id,
				parentId: modification.entity.parent?.id,
				body: {},
				type: modification.entity.type.toLowerCase(),
			};
		}

		// Save our value at the proper path
		mergedModifications[modification.entity.id].body = setObjectValueAtPath(
			mergedModifications[modification.entity.id].body,
			modification.path,
			modification.value
		);
	});

	return mergedModifications;
}
