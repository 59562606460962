import {
	ActivateFilterCollection,
	FilterParameters,
	MediaPlanningFilterCollection,
	OverallFilterCollection,
	PlanningFilterCollection,
} from '../entities/filter/filter.model';
import { InvoiceFilterCollection } from '../entities/invoice/invoice.model';
import { PlanDefaultActiveColumns, PlanFilterCollection } from '../entities/plan/plan.model';
import { MediaPlanningDefaultActiveColumns, ProgramDefaultActiveColumns, ProgramFilterCollection } from '../entities/program/program.model';
import { TacticFilterCollection } from '../entities/tactic/tactic.model';
import { BudgetCache, OverallColumnCollection } from '../global/global.model';
import { ProgramClassification } from '../../../../../api/src/program/program.entity';

/**
 * A wrapper for budget caches that are used for chart endpoints.
 */
export interface BudgetCacheEntity {
	id: string;
	name: string;
	budgetCache: BudgetCache;
}

/**
 * Describes the different views that can be accessed on the overview pages.
 */
export type OverviewViewType = 'table' | 'calendar' | 'budgets' | 'media-planner' | 'files' | 'performance';

/**
 * Overview view model
 * Overview views describe the different contexts you can look at on the overview page.
 * Overview views are configured below.
 */
export interface OverviewView {
	id: string;
	name: string;
	icon: string;
	type: OverviewViewType;
}

/**
 * The current overview views.
 */
export const OverviewViews: OverviewView[] = [
	{
		id: 'table',
		type: 'table',
		name: 'Table View',
		icon: 'uil-table',
	},
	{
		id: 'calendar',
		type: 'calendar',
		name: 'Calendar View',
		icon: 'uil-calendar-alt',
	},
	{
		id: 'budgets',
		type: 'budgets',
		name: 'Budgets View',
		icon: 'uil-chart-pie',
	},
	{
		id: 'performance',
		type: 'performance',
		name: 'Performance View',
		icon: 'uil-analytics',
	},
	{
		id: 'media-planner',
		type: 'media-planner',
		name: 'Planning View (Beta)',
		icon: 'uil-vertical-distribution-center',
	},
	{
		id: 'files',
		type: 'files',
		name: 'File Explorer',
		icon: 'uil-images',
	},
];

/**
 * Default filter parameter values for the planning page
 * This applies on page load.
 */
export const PlanningDefaultFilterParams: FilterParameters = {
	include: {
		id: 'plans-programs',
		name: 'Plans + Planned Programs',
		value: {
			endpoint: 'plans',
			include: ['programs'],
		},
	},
	groups: {
		id: 'retailers',
		name: 'Retailers',
		entityName: 'Retailer',
		value: 'retailers',
	},
};

/**
 * Default filter parameter values for the activate page
 * This applies on page load.
 */
export const ActivateDefaultFilterParams: FilterParameters = {
	include: {
		id: 'programs-tactics',
		name: 'Programs + Tactics',
		value: {
			endpoint: 'programs',
			include: ['tactics'],
		},
	},
	groups: {
		id: 'brands',
		name: 'Brands',
		entityName: 'Brand',
		value: 'brands',
	},
};

/**
 * Default filter parameter values for the media plan page
 * This applies on page load.
 */
export const MediaPlanDefaultFilterParams: FilterParameters = {
	include: {
		id: 'programs-tactics',
		name: 'Programs + Tactics',
		value: {
			endpoint: 'programs',
			include: ['tactics', 'tacticGroups'],
		},
	},
	classification: ProgramClassification.MediaPlan,
	groups: {
		id: 'brands',
		name: 'Brands',
		entityName: 'Brand',
		value: 'brands',
	},
};

/**
 * Config variables for the overview components on the Plan page
 */
export const PlanOverviewParams = {
	title: 'Plan',
	path: 'planning',
	section: 'planning',
	layoutLocationCode: 'tableView',
	entityName: 'plan',
	defaultFilterParameters: PlanningDefaultFilterParams,
	inlineFiltersKey: 'planInlineFilters',
	filters: [
		...OverallFilterCollection,
		...PlanningFilterCollection,
		...PlanFilterCollection,
		...ProgramFilterCollection,
		...TacticFilterCollection,
	],
	defaultActiveColumns: PlanDefaultActiveColumns,
};

/**
 * Config variables for the overview components on the Activate page
 */
export const ActivateOverviewParams = {
	title: 'Activate',
	path: 'activate',
	section: 'activation',
	layoutLocationCode: 'tableView',
	entityName: 'program',
	inlineFiltersKey: 'activateInlineFilters',
	defaultFilterParameters: ActivateDefaultFilterParams,

	filters: [
		...OverallFilterCollection,
		...ActivateFilterCollection,
		...PlanFilterCollection,
		...ProgramFilterCollection,
		...TacticFilterCollection,
		...InvoiceFilterCollection,
	],
	defaultActiveColumns: ProgramDefaultActiveColumns,
};

/**
 * Config variables for the overview components on the Media Plan page
 */
export const MediaPlanOverviewParams = {
	title: 'Media Plan',
	path: 'media-planning',
	section: 'media-plan',
	autoHeight: true,
	layoutLocationCode: 'tableView',
	entityName: 'mediaPlan',
	inlineFiltersKey: 'mediaPlanInlineFilters',
	defaultFilterParameters: MediaPlanDefaultFilterParams,
	actionRowSettings: {
		name: 'Add Tactic',
	},
	filters: [
		...MediaPlanningFilterCollection,
		...OverallFilterCollection.filter((filter) =>
			['overall-budget-period-multiselect', 'overall-brands', 'overall-retailers', 'overall-agencies', 'overall-start-date'].includes(
				filter.id
			)
		),
		...ProgramFilterCollection.filter((filter) => ['program-classification-status'].includes(filter.id)),
	],
	itemSlotFields: [
		{
			id: 'name',
			name: 'Name',
			path: 'name',
			type: 'field',
			entityTypes: ['plan', 'program', 'tactic', 'invoice'],
			category: 'Overall',
			hideFromMenu: false,
			extra: { width: 300 },
		},
		{
			id: 'budget',
			name: 'Estimated Spend',
			path: 'budget',
			type: 'currency',
			category: 'Budgets',
			entityTypes: ['program', 'tactic', 'invoice', 'plan'],
		},
		{
			id: 'exact-budget',
			name: 'Exact Estimated Spend',
			path: 'exact-budget',
			type: 'currency',
			category: 'Budgets',
			entityTypes: ['program', 'tactic', 'invoice', 'plan'],
		},
		{
			id: 'program-allocated-budget',
			name: 'Program Planned Budget',
			path: 'budgetAllocations.value',
			type: 'currency',
			category: 'Budgets',
			entityTypes: ['plan', 'program'],
		},
		{
			id: 'brands',
			name: 'Brand(s)',
			path: 'brands',
			type: 'badges',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic'],
		},
		{
			id: 'funding-source',
			name: 'Funding Source',
			path: 'fundingSource',
			type: 'field',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic'],
		},
		{
			id: 'vendors',
			name: 'Vendor(s)',
			path: 'vendors',
			type: 'field',
			category: 'Overall',
			entityTypes: ['plan', 'program', 'tactic'],
		},
	],
	defaultActiveColumns: MediaPlanningDefaultActiveColumns,
	// Whitelisted columns for the media plan page
	whitelistedColumns: [
		...new Set([
			...MediaPlanningDefaultActiveColumns,
			...(OverallColumnCollection?.items ?? [])
				.filter((column) => ['Start Date', 'End Date', 'ID', 'Date Created', 'Created By'].includes(column.name))
				.map((column) => column.name),
			...[
				'Retailer',
				'Estimated Tactic Spend',
				'Brand Initiative',
				'Planned Budget',
				'Date Range',
				'Funding Source',
				'Funding Type',
				'Agency',
				'Owners',
				'Tags',
				'All External Ids',
				//'Region',
				'Location',
				'Program Sector',
				'Budget Event ID',
				// Tactics
				'Tactic Type',
				'Tactic Category',
				'Tactic Phase',
				'Tactic Brands',
				'Vendor(s)',
				'RMN',
				'Tactic Funding Source',
				'Last Coupon Expiration',
				'Tactic Cost Types',
				'Tactic Due Date',
				'Next Steps',
				'Buy Specs',
				'Landing Page',
				'Flow Chart',
			],
		]),
	],
};
