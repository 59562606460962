import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumber } from '../../../_core/utils/types.utils';
import { Cost } from '../cost/cost.model';
import { Invoice } from '../invoice/invoice.model';
import { Observable } from 'rxjs';
import { Program } from '../program/program.model';
import { Tactic } from './tactic.model';
import { TacticQuery } from './tactic.query';
import { TacticStore } from './tactic.store';

/**
 * Tactic Service
 * This service is responsible for tactic logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class TacticService {
	constructor(private tacticQuery: TacticQuery, private tacticStore: TacticStore, private http: HttpClient) {}

	/**
	 * Get a tactic based on an id + program id.
	 */
	getOne(id: Tactic['id'], programId: Program['id']): Observable<Tactic> {
		this.tacticStore.setLoading(true);

		return this.http
			.get<Tactic>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${programId}/tactic/${id}`)
			.pipe(
				tap((tactic) => {
					console.log('Tactics: getOne()', tactic);
					this.tacticStore.upsert(tactic.id, { ...tactic, type: 'Tactic' });
					this.tacticStore.setLoading(false);
				})
			);
	}

	/**
	 * Set the Akita store with the given tactics.
	 */
	set(tactics: Tactic[]): void {
		this.tacticStore.set(tactics?.map((t) => ({ ...t, type: 'Tactic' })) || []);
	}

	/**
	 * Create a tactic on the API
	 * if 'skipActive' is true, do not make this tactic the active one in Akita after creating.
	 */
	create(tactic: Partial<Tactic>, skipActive?: boolean): Observable<Tactic> {
		console.log(tactic);
		return this.http
			.post<Tactic>(
				`${environment.apiUrl}/organization/${environment.organizationId}/program/${tactic.programId || tactic.program.id}/tactic`,
				this.prepareForApi(tactic)
			)
			.pipe(
				tap((response) => {
					console.log(response);
					this.tacticStore.upsert(response.id, { ...response });

					if (!skipActive) {
						this.tacticStore.remove(this.tacticQuery.getActiveId());
						this.tacticStore.setActive(response.id);
					}
				})
			);
	}

	/**
	 * Add a tactic to the Akita store.
	 */
	add(tactic: Tactic): void {
		this.tacticStore.add(tactic);
	}

	/**
	 * Update a tactic on the API.
	 * If the 'skipHTTP' param is true, just update the tactic on the Akita store.
	 */
	update(id: Tactic['id'], programId: Program['id'], tactic: Partial<Tactic>, skipHTTP?: boolean): Observable<Tactic> {
		console.log('Trying to update', id, tactic);
		if (skipHTTP) {
			this.tacticStore.upsert(id, { ...tactic });
			return EMPTY;
		}

		console.log('Data before prepareForApi:', tactic);
		const preparedTactic = this.prepareForApi(tactic);
		console.log('Data sent in PUT request:', preparedTactic);

		return this.http
			.put<Tactic>(
				`${environment.apiUrl}/organization/${environment.organizationId}/program/${programId}/tactic/${id}`,
				preparedTactic
			)
			.pipe(
				tap((response) => {
					console.log(response);
					this.tacticStore.upsert(response.id, { ...response });
				})
			);
	}

	/**
	 * Remove a tactic from the API
	 */
	remove(id: Tactic['id'], programId: Program['id']): Observable<Tactic> {
		return this.http
			.delete<Tactic>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${programId}/tactic/${id}`)
			.pipe(
				tap((response) => {
					this.removeFromStore(id);
				})
			);
	}

	/**
	 * Remove a tactic from the Akita store.
	 */
	// TODO: Pull this into the remove function with a 'skipHTTP' event like the update function.
	removeFromStore(id: Tactic['id']): void {
		this.tacticStore.remove(id);
	}

	/**
	 * Clone a tacting on the API, upsert the result into the Akita store.
	 */
	clone(id: Tactic['id'], programId: Program['id']): Observable<Tactic> {
		return this.http
			.post<Tactic>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${programId}/tactic/${id}/clone`, {})
			.pipe(
				tap((response) => {
					this.tacticStore.upsert(response.id, { ...response });
				})
			);
	}

	/**
	 * Clone a tacting on the API, upsert the result into the Akita store.
	 */
	restore(id: Tactic['id'], programId: Program['id']): Observable<Tactic> {
		return this.http
			.post<Tactic>(`${environment.apiUrl}/organization/${environment.organizationId}/program/${programId}/tactic/${id}/restore`, {})
			.pipe(
				tap((response) => {
					this.tacticStore.upsert(response.id, { ...response });
				})
			);
	}

	/**
	 * Set a tactic as 'active' in the Akita store.
	 */
	setActive(id: Tactic['id']): void {
		this.tacticStore.setActive(id);
	}

	/**
	 * Remove a tactic as 'active' in the Akita store.
	 */
	removeActive(id: Tactic['id']): void {
		this.tacticStore.removeActive(id);
	}

	/**
	 * Set the loading status for the Akita store.
	 */
	setLoading(state: boolean): void {
		this.tacticStore.setLoading(state);
	}

	/**
	 * Set the sort direction for tactics.  Provide a dot notation path to the property and a direction.
	 */
	setSortBy(path: string, direction: 'asc' | 'desc'): void {
		this.tacticStore.update({
			sortByProperty: path,
			sortByDirection: direction,
		});
	}

	/**
	 * Return a tactic form builder object with default values.
	 */
	getFormObject(tactic: Tactic, controlOverrides: any = {}) {
		return {
			tacticType: [tactic.tacticType, Validators.required],
			name: [tactic.name],
			detail: [tactic.detail],
			start: [tactic.start, Validators.required],
			end: [tactic.end, Validators.required],
			destinationURL: [tactic.destinationURL],
			tacticPhase: [tactic.tacticPhase, Validators.required],
			fundingSource: [tactic.fundingSource, Validators.required],
			vendors: [tactic.vendors || []],
			brands: [tactic.brands || [], Validators.required],
			lastCouponExpiration: [tactic.lastCouponExpiration],
			tags: [tactic.tags || []],
			proMax: [tactic.proMax],
			retailMediaPO: [tactic.retailMediaPO],
			costs: [tactic.costs || []],
			invoices: [tactic.invoices || []],
			milestones: [tactic.milestones || []],
			offers: [tactic.offers || []],
			notes: [tactic.notes || []],
			rmn: [tactic.rmn || undefined],
			dueDate: [tactic.dueDate || undefined],
			nextSteps: [tactic.nextSteps || undefined],
			buySpecs: [tactic.buySpecs || undefined],
			flowchart: [tactic.flowchart || undefined],
			landingPage: [tactic.landingPage || undefined],
			location: [tactic.location || undefined],
			tacticAgency: [tactic.tacticAgency || undefined],
			...controlOverrides,
		};
	}

	prepareForAkita(items: Tactic[]) {
		return items.map((item) => ({
			...item,
			type: 'Tactic',
		}));
	}

	/**
	 * Normalize a tactic for the API.
	 */
	prepareForApi(tactic: Partial<Tactic>): object {
		const obj = {};
		console.log('Preparing for pre API partial tactic', tactic);

		if (tactic) {
			Object.keys(tactic).forEach((key) => {
				switch (key) {
					case 'budgetPeriod':
					case 'tacticPhase':
					case 'fundingSource':
					case 'tacticType':
					case 'retailer':
					case 'location':
					case 'tacticAgency':
						if (tactic[key] === null) {
							obj[key + 'Id'] = null;
						} else {
							obj[key + 'Id'] = tactic[key]?.id;
						}
						break;

					case 'brands':
						obj[key.slice(0, key.length - 1) + 'Ids'] = tactic[key]?.map((v) => v.id);
						break;

					case 'tags':
						obj[key] = tactic[key]?.map((v) => v.name);
						break;

					case 'vendors':
						const ids = tactic[key]?.filter((v) => !v.add).map((v) => v.id);
						const names = tactic[key]?.filter((v) => v.add).map((v) => v.name);
						obj[key.slice(0, key.length - 1) + 'Ids'] = ids;
						obj[key] = names;
						break;

					case 'start':
						if (isNumber(tactic[key])) {
							obj[key] = new Date(tactic[key]).toISOString();
						} else {
							obj[key] = tactic[key];
						}
						const startOfDay = new Date(obj['start']).setHours(12, 0, 0, 0);
						obj[key] = new Date(startOfDay).toISOString();
						break;

					case 'end':
						if (isNumber(tactic[key])) {
							obj[key] = new Date(tactic[key]).toISOString();
						} else {
							// can be also an object.
							obj[key] = tactic[key];
						}
						const endOfDay = new Date(obj['end']).setHours(12, 0, 0, 0);
						obj[key] = new Date(endOfDay).toISOString();
						break;
					case 'lastCouponExpiration':
						// if no value, set to null
						if (!tactic[key]) {
							obj[key] = null;
							break;
						}
						console.log('lastCouponExpiration ');
						if (isNumber(tactic[key])) {
							obj[key] = new Date(tactic[key]).toISOString();
						} else {
							// can be also an object.
							obj[key] = tactic[key];
						}
						const lastCouponExpirationDate = new Date(obj['lastCouponExpiration']).setHours(12, 0, 0, 0);
						obj[key] = new Date(lastCouponExpirationDate).toISOString();
						break;
					case 'dueDate':
						// if no value, set to null
						if (!tactic[key]) {
							obj[key] = null;
							break;
						}
						console.log('dueDate ');
						if (isNumber(tactic[key])) {
							obj[key] = new Date(tactic[key]).toISOString();
						} else {
							// can be also an object.
							obj[key] = tactic[key];
						}
						const OTVdueDate = new Date(obj['dueDate']).setHours(12, 0, 0, 0);
						obj[key] = new Date(OTVdueDate).toISOString();
						break;
					case 'costs':
					case 'offers':
					case 'notes':
					case 'status':
					case 'program':
					case 'invoices':
					case 'milestones':
					case 'programId':
					case 'proMax':
					case 'retailMediaPO':
						break;

					default:
						obj[key] = tactic[key];
						break;
				}
			});
		}

		console.log('Preparing for after API partial tactic', tactic);
		return obj;
	}

	/**
	 * Get the total difference between the costs total and the invoices total
	 * TODO: This function should be renamed to better suggest what it does.
	 */
	getPlannedCostTotal(invoices: Invoice[], costs: Cost[]): number {
		let total = 0;
		let plannedCostTotal = 0;
		if (invoices) {
			total = invoices.reduce((a, b) => a + (Number(b.amount) || 0), 0);
		}

		if (costs) {
			plannedCostTotal = costs.reduce((a, b) => a + (Number(b.amountPlanned) || 0), 0);
		}

		return (plannedCostTotal - total) * -1;
	}
}
